import axiosAdminInstance from "../../config/api/axiosAdmin";

const login = (data) => {
  return axiosAdminInstance.post(`/v1/users/login`, data);
};

const userInfo = (data) => {
  return axiosAdminInstance.get(`/v1/users/get-user-info`, data);
};

const logOut = () => {
  return axiosAdminInstance.get(`/v1/users/revoke-refresh-token`);
};

const verifyCaptcha = (data) => {
  return axiosAdminInstance.post(`/v1/users/verify-captcha`, data);
};

export { login, userInfo, logOut, verifyCaptcha };
