import React from "react";
import Dialog from '@mui/material/Dialog';
import { DialogTitle,DialogContent } from "@mui/material";
import { AlignCenter } from "react-feather";

const ModalPage = ({ show, children }) => {

  return (
    <Dialog
        open={show}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Crop Image"}
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
  );
};

export default ModalPage;
