import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Icon, Typography } from "@mui/material";
import { ChevronDown, ChevronUp } from "react-feather";
import { useState } from "react";

export default function CommmentActions(props) {
  const {
    row: { status, isSuspended },
    id,
    handleActions,
    selectedItems,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const actionsType ={
    unflag : 'unflagged',
    delete : 'deleted',
    suspend : 'suspended'
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionsHandler =(action)=>{
    handleClose();
    if (selectedItems.length) {
      for (let index=0; index<selectedItems.length; index++) {
        handleActions(action, Number(selectedItems[index]))
      }
    } else {
      handleActions(action,id);
    }
  }
  
  let actions = (
      <div>
      <MenuItem onClick={()=>actionsHandler(actionsType.delete)}>
        <Typography variant="blackText">Delete</Typography>
      </MenuItem>
      <MenuItem onClick={()=>actionsHandler(actionsType.suspend)}>
        <Typography variant="blackText">Suspend</Typography>
      </MenuItem>
      </div>
  );

  if (status === "approved") {
    actions = (
      <div>
        <MenuItem onClick={()=>actionsHandler(actionsType.delete)}>
          <Typography variant="blackText">Delete</Typography>
        </MenuItem>
        <MenuItem onClick={()=>actionsHandler(actionsType.suspend)}>
          <Typography variant="blackText">Suspend</Typography>
        </MenuItem>
      </div>
    );
  };

  if(status==="flagged" && !!isSuspended){
    actions = (
      <div>
        <MenuItem onClick={()=>actionsHandler(actionsType.unflag)}>
          <Typography variant="blackText">Unflag</Typography>
        </MenuItem>
      </div>
    );
  }else if(status==="flagged"){
    actions = (
      <div>
        <MenuItem onClick={()=>actionsHandler(actionsType.unflag)}>
          <Typography variant="blackText">Unflag</Typography>
        </MenuItem>
        <MenuItem onClick={()=>actionsHandler(actionsType.suspend)}>
          <Typography variant="blackText">Suspend</Typography>
        </MenuItem>
      </div>
    );
  }

  return (
    <div>
      <Button
        sx={{
          textTransform: "unset",
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Typography variant="blackText" sx={{ width: "60px" }}>
          Action
        </Typography>
        {open ? (
          <Icon
            sx={{ marginLeft: "14px", display: "flex", alignItems: "center" }}
          >
            {" "}
            <ChevronUp color="#3F51B5" />{" "}
          </Icon>
        ) : (
          <Icon
            sx={{ marginLeft: "14px", display: "flex", alignItems: "center" }}
          >
            {" "}
            <ChevronDown color="black" />{" "}
          </Icon>
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        sx={{
          "& .MuiMenu-root": {},
          "& .MuiMenu-list": {
            padding: "6px 0px 6px 4px",
          },
        }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {actions}
      </Menu>
    </div>
  );
}
