import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Typography } from "@mui/material";
import { Edit as EditIcon } from "react-feather";
import CustomizedSnackbars from "../../../../components/Alert/CustomizedSnackbars";
import { deleteUser, editUser } from "../../../../services/user/userService";
import { getErrorMessageFromErrorResponse } from "../../../../services/helper";

export default function UserActions(props) {
  const {
    id,
    row,
    fetchListData,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const actionList= row.status === "Paused" ? ['Activate', 'Delete'] : ['Deactivate', 'Delete']
  const open = Boolean(anchorEl);
  const [alert,setAlert] = React.useState({});
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = async(action) => {
    handleClose();
    if(action==="Delete")
    {
      await handleDeleteAction();
    }
    else{
      handleUserStatus(action === "Activate" ? false : true);
    }
  }

  const handleUserStatus = async (status) => {
    handleClose();
    let param = {
      user_id: id,
      is_paused: status
    };
    let params = new URLSearchParams(param).toString();
    editUser(params)
      .then(function (response) {
        fetchListData();
      })
      .catch(function (error) {
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
        console.error("edit user error:", error)
        return true
      })
  };

  const handleDeleteAction = async () => {
    handleClose();
    deleteUser(id)
      .then(function (response) {
        fetchListData();
      })
      .catch(function (error) {
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
        console.error("delete user error:", error)
        return true
      })
  };

  let actions = [];

  if (!!actionList) {
    actions = actionList.map((action) => (
      <MenuItem
        onClick={()=>handleButtonClick(action)}
        key={action}
        value={action}
      >
        <Typography variant="blackText">{action}</Typography>
      </MenuItem>
    ));
  }

  return (
    <div>
      <IconButton
        sx={{
          textTransform: "unset",
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <EditIcon color="#263238" height="18px" width="18px" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        sx={{
          "& .MuiMenu-root": {},
          "& .MuiMenu-list": {
            padding: "6px 0px 6px 4px",
          },
        }}
        transformOrigin={{
          horizontal: "center",
        }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {actions}
      </Menu>
      {
        alert.error ?
          <CustomizedSnackbars
            open={alert.error}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="error"
          /> :
          alert.success && <CustomizedSnackbars
            open={alert.success}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="success"
          />
      }
    </div>
  );
}
