import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Tabs,
  Tab,
  Button,
  Box,
  SvgIcon,
  Typography,
  InputBase,
  Card,
  IconButton,
  Link,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import Label from "../../../../components/Label";
import {
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
} from "react-feather";
import { _getValue } from "../../../../util/lodashWrapper";
import SortIcon from "../../../../components/UI/SortIcon";
import { profileList } from "../../../../services/user/userService";
import RenderImage from "./RenderImage";
import { formateDate } from "../../../../util/dateformat";
import ShopActions from "./ShopActions";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../../../../components/LoadingAnimation/LoadingAnimation";
import CustomizedSnackbars from "../../../../components/Alert/CustomizedSnackbars";
import { getErrorMessageFromErrorResponse } from "../../../../services/helper";

const labelColors = {
  active: "success",
  paused: "warning",
  inactive: "drafted",
};

const columnGroupingModel = [
  {
    groupId: "user",
    headerName: "User",
    headerClassName: "mygroupcolumn-css",
    description: "",
    renderHeaderGroup: (params) => (
      <Typography {...params} variant="blackText" sx={{ lineHeight: "22px" }}>
        {params.headerName}{" "}
      </Typography>
    ),
    children: [
      { field: "shopName" },
      { field: "owner" },
      { field: "email" },
      { field: "created" },
    ],
  },
  {
    groupId: "social",
    headerName: "Social",
    headerClassName: "mygroupcolumn-css",
    description: "",
    renderHeaderGroup: (params) => (
      <Typography {...params} variant="blackText" sx={{ lineHeight: "22px" }}>
        {params.headerName}{" "}
      </Typography>
    ),
    children: [
      { field: "followers" },
      { field: "views" },
      { field: "videos" },
      { field: "clubMembers" },
      { field: "videosTagged" },
    ],
  },
  {
    groupId: "commerce",
    headerName: "Commerce",
    headerClassName: "mygroupcolumn-css",
    description: "",
    renderHeaderGroup: (params) => (
      <Typography {...params} variant="blackText" sx={{ lineHeight: "22px" }}>
        {params.headerName}{" "}
      </Typography>
    ),
    children: [
      { field: "activeProducts" },
      { field: "weekelySales" },
      { field: "monthlySales" },
      { field: "activePromotion" },
      { field: "freeShippingThreshold" },
    ],
  },
  {
    groupId: "payouts",
    headerName: "Payouts",
    description: "",
    headerClassName: "mygroupcolumn-css",
    renderHeaderGroup: (params) => (
      <Typography {...params} variant="blackText" sx={{ lineHeight: "22px" }}>
        {params.headerName}{" "}
      </Typography>
    ),
    children: [
      { field: "weeklyPaid" },
      { field: "monthlyPaid" },
      { field: "totalPaid" },
      { field: "status" },
      { field: "action" },
    ],
  },
];

const ShopList = () => {
  const searchForm = useRef(null);
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [clearButtonVisible, setClearButtonVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState("All");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [rowCountState, setRowCountState] = useState(0);
  const [alert, setAlert] = useState({});
  const navigate = useNavigate();
  const handleOnArrow = (id) => {
    navigate(`/Manage/Profiles/${id}`);
  }
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleSearch = () => {
    const form = searchForm.current;
    if (form.value) {
      setSearchValue(form.value);
      setClearButtonVisible(true);
    }
  };

  const handleSearchClear = () => {
    setSearchValue("");
    searchForm.current.value = "";
    setClearButtonVisible(false);
  };

  const getBrandStatus = (is_active, is_paused, is_deleted) => {
    if (is_active) {
      return "Active";
    }

    if (is_paused && is_deleted) {
      return "Inactive";
    }

    if (is_paused && !is_deleted) {
      return "Paused";
    }

    return "Inactive";
  };

  const fetchListData = useCallback(async () => {
    let param = {
      limit: pageSize,
      offset: page * pageSize,
      status: currentTab,
      brand: true, //it should be always true for fetching brands, is_brand=true;
      search: searchValue,
    };
    let searchParams = new URLSearchParams(param).toString();
    let brandsList = [];
    try {
      const data = await profileList(searchParams);
      if (data) {
        setRowCountState(data?.count);
        brandsList = data?.profiles?.map((brand) => ({
          id: brand?.id,
          shopName: {
            photo_Url: brand?.profile_photo_s3_object_id,
            name: brand?.user_name,
          },
          owner: brand.user_name,
          email: brand?.User?.email,
          created: brand?.date_created,
          followers: brand?.follower_count,
          videos: "NA",
          views: "NA",
          clubMembers: "NA",
          videosTagged: "NA",
          activeProducts: "NA",
          weekelySales: "NA",
          monthlySales: "NA",
          activePromotion: "NA",
          freeShippingThreshold: "NA",
          weeklyPaid: "NA",
          monthlyPaid: "NA",
          totalPaid: "NA",
          is_paused: _getValue(brand, "is_paused").value,
          status: getBrandStatus(
            _getValue(brand, "is_active").value,
            _getValue(brand, "is_paused").value,
            _getValue(brand, "is_deleted").value
          ),
          action: "Action",
        }));
        setShops(brandsList);
      }
    } catch (error) {
      setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
      console.error(error);
    }
    setLoading(false);
  }, [page, pageSize, searchValue, currentTab]);

  const fetchProfileData = () => {
    setLoading(true);
    fetchListData();
  }

  useEffect(() => fetchProfileData(), [page, pageSize, currentTab, searchValue, fetchListData]);


  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 89,
    },
    { field: "id", headerName: "Id", width: 130, hide: true },
    {
      field: "shopName",
      headerName: "Shop Name",
      width: 300,
      renderCell: (params) => {
        return (
          <Box
            sx={{ paddingY: 2, display: "flex", alignItems: "center" }}
            component="div"
          >
            <RenderImage s3ObjectId={params.value.photo_Url} />
            <Typography variant="purpleText" sx={{ marginLeft: 1 }}>
              {params.value.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "owner",
      headerName: "Owner",
      renderCell: (params) => {
        return <Typography variant="purpleText">{params.value}</Typography>;
      },
      width: 300,
    },
    {
      field: "email",
      headerName: "E-Mail",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 300,
    },
    {
      field: "created",
      headerName: "Created",
      renderCell: (params) => {
        const { date } = formateDate(params.value);
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="blackText" component="span">
              {" "}
              {date}{" "}
            </Typography>
          </Box>
        );
      },
      width: 233,
    },
    {
      field: "followers",
      headerName: "Followers",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 133,
    },
    {
      field: "videos",
      headerName: "Videos",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 133,
    },
    {
      field: "views",
      headerName: "Views",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 133,
    },
    {
      field: "clubMembers",
      headerName: "Club Members",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 154,
    },
    {
      field: "videosTagged",
      headerName: "Videos Tagged",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 157,
    },
    {
      field: "activeProducts",
      headerName: "Active Products",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 157,
    },
    {
      field: "weekelySales",
      headerName: "7 Day Sales",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 166,
    },
    {
      field: "monthlySales",
      headerName: "30 Day Sales",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 174,
    },
    {
      field: "activePromotion",
      headerName: "Active Promotion",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 200,
    },
    {
      field: "freeShippingThreshold",
      headerName: "Free Shipping Threshold",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 216,
    },
    {
      field: "weeklyPaid",
      headerName: "7 Day Paid",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 166,
    },
    {
      field: "monthlyPaid",
      headerName: "30 Day Paid",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 174,
    },
    {
      field: "totalPaid",
      headerName: "Total Paid",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 174,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return (
          <Label
            color={
              params.value === "Active"
                ? labelColors["active"]
                : params.value === "Inactive"
                  ? labelColors["inactive"]
                  : labelColors["paused"]
            }
          >
            {params.value}
          </Label>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      type: "actions",
      width: 120,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "space-around",
              alignItems: "center",
              paddingLeft: "20px",
            }}
          >
            <ShopActions
              id={params?.id}
              row={params.row}
              actionList={params.row.is_paused ? ['Activate', 'Delete'] : ['Deactivate', 'Delete']}
              fetchListData={fetchProfileData}
            />
            <IconButton
              onClick={() => handleOnArrow(params.id)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ArrowRightIcon color="#5850EC" height="14px" width="14px" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Card
        sx={{
          background: "#FFFFFF",
          boxShadow:
            "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
          borderRadius: "4px",
        }}
      >
        <div style={{ height: "5vh", width: "100%" }}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs"
          >
            <Tab value="All" label="All" />
            <Tab value="Active" label="Active" />
            <Tab value="Inactive" label="Inactive" />
            <Tab value="Review" label="Review" />
          </Tabs>
        </div>
        <div style={{ height: "10vh", width: "100%" }}>
          <Box p={2} minHeight={56} display="flex" alignItems="center">
            <Grid container spacing={2}>
              <Grid item>
                <InputBase
                  inputRef={searchForm}
                  sx={{
                    width: "420px",
                    height: "36px",
                    background: "#FFFFFF",
                    border: "1px solid #E0E0E0",
                    borderRadius: "4px",
                    textAlign: "right",
                    display: "flex",
                    padding: 2,
                  }}
                  startAdornment={
                    <SvgIcon fontSize="small" color="action" sx={{}}>
                      <SearchIcon />
                    </SvgIcon>
                  }
                  placeholder="Search messages"
                  variant="outlined"
                  disabled={clearButtonVisible}
                />
              </Grid>
              <Grid item>
                {clearButtonVisible === true ? (
                  <Button
                    sx={{
                      height: "36px",
                      background: "#F5F5F5",
                      borderRadius: "4px",
                      color: "#263238",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                      textTransform: "uppercase",
                    }}
                    onClick={handleSearchClear}
                  >
                    Clear Filter
                  </Button>
                ) : (
                  <Button
                    sx={{
                      background: "#F5F5F5",
                      borderRadius: "4px",
                      color: "#263238",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                      textTransform: "uppercase",
                      padding: "6px 16px",
                    }}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </div>
        {loading ?
          <Box sx={{ width: "100%", height: "50vh" }}><LoadingAnimation /></Box> :
          <Box sx={{ width: "100%" }}>
            <DataGrid
              autoHeight
              rows={shops}
              columns={columns}
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={columnGroupingModel}
              checkboxSelection
              disableColumnMenu={true}
              sx={{
                border: 0,
                "& .MuiDataGrid-virtualScroller": {
                  bg: "#eee",
                  opacity: "0.9",
                },
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  display: "flex",
                  justifyContent: "space-between",
                },
                "& .MuiDataGrid-iconButtonContainer": {
                  display: "flex",
                  width: "auto",
                  visibility: "visible",
                },
              }}
              components={{
                ColumnSortedAscendingIcon: SortIcon,
                ColumnSortedDescendingIcon: SortIcon,
                ColumnUnsortedIcon: SortIcon,
              }}
              rowCount={rowCountState}
              loading={loading}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </Box>
        }
      </Card>
      {
        alert.error ?
          <CustomizedSnackbars
            open={alert.error}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="error"
          /> :
          alert.success && <CustomizedSnackbars
            open={alert.success}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="success"
          />
      }</>
  );
};

export default ShopList;
