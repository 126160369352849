import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Grid,
  Link,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Image as ImageIcon,
  PlusCircle as PlusCircleIcon,
  Upload as UploadIcon,
  Edit3 as EditIcon,
  X as CloseIcon
} from "react-feather";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./Category.css";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import { useEffect } from "react";
import { generatePreSignedURL, uploadFileToS3 } from "../../../../services/aws/s3FileUpload";
import { editCategory, getCategoryDetail } from "../../../../services/category/category";
import { generateRandomString, getErrorMessageFromErrorResponse } from "../../../../services/helper";
import CustomizedSnackbars from "../../../../components/Alert/CustomizedSnackbars";

const CategoryDetails = () => {
  const [alert, setAlert] = useState({});
  const [categoryImage, setCategoryImage] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [s3ObjectId, setS3ObjectId] = useState("")
  const { id } = useParams();
  const imageExtension = ['jpeg', 'jpg', 'png', 'JPEG', 'JPG', 'PNG'];
  const [imageError, setImageError] = useState('');

  const navigate = useNavigate();

  const validationSchemaProfile = Yup.object().shape({
    categoryName: Yup.string().required("Category Name is required"),
  });

  const { state } = useLocation();

  useEffect(() => {
    if (state?.response) {
      setCategoryValues(state.response)
    }
    else {
      getCategoryDetail(id)
        .then(function (response) {
          setCategoryValues(response)
        })
        .catch(function (error) {
          setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
          console.error("get category detail error:", error)
          return true
        })
    }

  }, []);

  useEffect(() => {
    if (categoryImage.length>0 && categoryImage[0]?.name && imageExtension.indexOf(categoryImage[0]?.name.split('.').pop()) < 0) {
      setImageError('Selected File is not an image type');
      setCategoryImage([]);
    }
    else if (categoryImage.length>0 && categoryImage[0]?.name) {
      setImageError('');
    }
  }, [categoryImage]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemaProfile),
  });

  const setCategoryValues = (response) => {
    setValue('categoryName', response?.name);
    setValue('status', response?.is_deleted ? "Inactive" : "Active");
    setS3ObjectId(response.category_photo_s3_object_id);
    generatePreSignedURL(response.category_photo_s3_object_id)
      .then(function (presignedResponse) {
        setCategoryImage([{ "presignedUrl": presignedResponse }]);

      })
      .catch(function (error) {
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
        console.error("get profile error:", error)
        return true
      })

  }

  const onSubmit = async (data) => {
    const { categoryName } = data;
    let category_photo_s3_object_id = "" + s3ObjectId;
    if (categoryImage.length >0 && Object.keys(categoryImage[0]).length > 1) {
      const { presignedUrl, url, s3_object_id } = await handleImageUpload();
      setS3ObjectId(s3_object_id);
      category_photo_s3_object_id = s3_object_id;
    }
    const categoryData = { id, name: categoryName, category_photo_s3_object_id }
    editCategory(categoryData)
      .then(function (response) {
        const { id } = response
        navigate(`/Manage/Categories`, { replace: true });

      })
      .catch(function (error) {
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
      })


  };

  const handleImageUpload = async () => {
    const fileName = generateRandomString(16)
    const { presignedUrl, url } = await uploadFileToS3(categoryImage[0], "category-images", setUploadProgress, fileName);
    const s3_object_id = `category-images/${fileName}`;
    return { presignedUrl, url, s3_object_id }
  }


  const categoryImages = categoryImage.map((file, index) => (
    <div key={index}>
      <img
        src={file.preview || file.presignedUrl}
        className="product-image"
        alt=""
      />
    </div>
  ));

  const handleImageUploadError = () => {


  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      DashBoard
    </Link>,
    <Typography key="2" color="text.primary">
      Manage Users
    </Typography>,
    <Typography key="3" color="text.primary">
      Categories
    </Typography>,
  ];
  return (
    <>
      <Grid container justify="space-between" spacing={3}>
        <Grid item xs={9}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
          <Typography variant="h1">Category Detail</Typography>
        </Grid>

        <Grid
          item
          xs={3}
          sx={{
            paddingRight: "24px",
          }}
        >
        </Grid>
        <Container maxWidth={false}>
          <Box mt={3}>
            <Card
              sx={{
                background: "#FFFFFF",
                boxShadow:
                  "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
                borderRadius: "4px",
                padding: "36px 24px 24px 16px",
              }}
            >
              <Box
                sx={{
                  widt: "100%",
                }}
              >
                <form>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={2}>
                      <Button
                        variant="text"
                        startIcon={<Avatar sx={{ bgcolor: "#5850EC" }}>#</Avatar>}
                        disabled
                        className="button-text"
                      >
                        Edit Category
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={5}>
                      <TextField
                        id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        {...register("categoryName")}
                        InputLabelProps={{
                          shrink: getValues('categoryName') && true
                        }}
                        sx={{ width: "100%", marginBottom: "20px" }}
                        error={errors.categoryName ? true : false}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.categoryName?.message}
                      </Typography>
                      <Box>
                        <Paper variant="outlined">
                          <div className="custom-image-upload image-upload fas">
                            <div className="upload-contianer">
                              <UploadFile
                                required
                                id="image"
                                error={() => { }}
                                setFile={setCategoryImage}
                                inputName="image"
                                className="dropzone"
                              >
                                {!categoryImage.length > 0 && <><PlusCircleIcon className="add-icon" />
                                  <h5>Add Image</h5>
                                  <p className="image-upload-text">
                                    Drag selection here
                                  </p></>}
                              </UploadFile>
                            </div>
                            {categoryImage.length > 0 ? (<>
                              <>{categoryImages}</>
                              <div className="image-cross">
                                <CloseIcon size="16px" onClick={() => setCategoryImage([])} />
                              </div></>
                            ) : (
                              <>
                              </>
                            )}
                          </div>
                        </Paper>
                        <Typography variant="inherit" color="red">
                          {imageError}
                        </Typography>

                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={5}>
                      <TextField
                        required
                        id="status"
                        name="staus"
                        label="Status"
                        margin="dense"
                        fullWidth
                        select
                        defaultValue="Active"
                        {...register("status")}
                        sx={{ width: "100%", marginBottom: "20px", marginTop: "8px" }}
                        error={errors.status ? true : false}
                      >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Inactive">Inactive</MenuItem>
                      </TextField>
                      <Typography variant="inherit" color="textSecondary">
                        {errors.status?.message}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="text"
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        color: "#263238",
                      }}
                      onClick={() => navigate("/Manage/Categories", { replace: true, })}
                    >
                      {" "}
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        background: "#5850EC",
                        borderRadius: "4px",
                        color: "#FFFFFF",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "24px",
                        textTransform: "uppercase",
                        padding: "6px 16px",
                        "&.Mui-disabled": {
                          backgroundColor: "#5850EC",
                          opacity: 0.5,
                          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                          borderRadius: "4px",
                          color: "white !important",
                        },
                        marginLeft: "16px",
                      }}
                      onClick={handleSubmit(onSubmit)}
                    >
                      {" "}
                      Save
                    </Button>
                  </Box>
                </form>
              </Box>
            </Card>
          </Box>
        </Container>
      </Grid>
      {
        alert.error ?
          <CustomizedSnackbars
            open={alert.error}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="error"
          /> :
          alert.success && <CustomizedSnackbars
            open={alert.success}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="success"
          />
      }</>
  );
};

export default CategoryDetails;
