import axiosAdminInstance from '../../config/api/axiosAdmin';

const getProfileDetail = (profileId, rowsPerPage=5, offSet=0) => {
    return axiosAdminInstance.get(`v1/users/get-profile-detail?profile_id=${profileId}&limit=${rowsPerPage}&offset=${offSet}`, {});
};

const updateProfileDetail = (data) => {
    return axiosAdminInstance.put(`/v1/users/edit-profile`, data);
};

const deleteProfile= (id) => axiosAdminInstance.delete(`/v1/users/delete-profile/${id}`);

export { getProfileDetail, updateProfileDetail, deleteProfile};


