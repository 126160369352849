import React, { useState } from "react";
import * as Yup from "yup";
import { EmailSuccessMessage, ResetPassButton, useStyles } from "./style";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  Divider,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactComponent as LockIcon } from "../../assets/images/icons/lock.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import CustomizedSnackbars from "../../components/Alert/CustomizedSnackbars";
import { auth } from "../../services/firebase/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback } from "react";
import { verifyCaptcha } from "../../services/user/authUserService";
import { useEffect } from "react";
import { constant } from "lodash";
import { _getValue } from "../../util/lodashWrapper";
import { getErrorMessageFromErrorResponse } from "../../services/helper";

function ResetPassword() {
  const [showError, setError] = useState(false);
  const [showSucess, setSucess] = useState(false);
  const navigate = useNavigate();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [alert, setAlert] = useState({});

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    setCaptchaVerified(false);
    setAlert({});
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("password_reset");
    let data = {
      response: token,
    };
    try {
      const tokenVerification = await verifyCaptcha(data);
      if (!!tokenVerification?.success) {
        setCaptchaVerified(true);
      } 
    } catch (error) {
      console.log(error);
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const resetPasswordSchema = Yup.object().shape({
    resetEmail: Yup.string()
      .email("Please enter a valid email address")
      .required("Please enter a valid email address"),
  });

  const resSetPassformOptions = { resolver: yupResolver(resetPasswordSchema) };
  const { register, handleSubmit, formState, getValues } = useForm(
    resSetPassformOptions
  );
  const { errors } = formState;

  const resetPassword = async (data) => {
    setAlert({});
    setSucess(false);
    const email = getValues("resetEmail");
    try {
      if (!captchaVerified) {
        throw new Error(
          "Captcha verification failed, Please refresh to try again!"
        );
      }
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setSucess(true);
        })
        .catch((error) => {
          setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) });
        });
    } catch (error) {
      setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) });
      console.log(error);
    }
  };

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const AvatarStyle = {
    top: "-32px",
    left: "24px",
    color: "#fff",
    width: "64px",
    height: "64px",
    padding: "4px",
    position: "absolute",
    borderRadius: "4px",
    backgroundColor: "#3F51B5",
    zIndex: 0,
    overflow: "visible",
  };

  let content = (
    <>
      <Box mt={3}>
        <Avatar style={AvatarStyle}>
          <LockIcon />
        </Avatar>
        <Grid container rowSpacing={2}>
          <Typography variant="h1">Forgot Password?</Typography>
          <Typography variant="subtitleLogin">
            Enter the email associated with your account.
          </Typography>
        </Grid>
      </Box>
      <Divider
        variant="fullWidth"
        component={"div"}
        sx={{
          "&::before, &::after": {
            borderColor: "secondary.light",
          },
          marginY: 1,
        }}
      />
      <Box>
        <form
          onSubmit={handleSubmit(resetPassword)}
          id="resetPassForm"
          autoComplete="off"
        >
          <Grid
            container
            rowSpacing={1}
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "initial" : "center",
            }}
          >
            <TextField
              fullWidth
              autoFocus
              label="Email Address"
              margin="normal"
              name="resetEmail"
              type="email"
              variant="outlined"
              className={classes.subtitleLogin}
              error={errors.resetEmail?.message ? true : false}
              helperText={
                errors.resetEmail?.message ? errors.resetEmail.message : ""
              }
              {...register("resetEmail")}
            />
          </Grid>

          <Grid
            container
            rowSpacing={1}
            mt={2}
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "initial" : "center",
            }}
          >
            <ResetPassButton
              type="submit"
              color="secondary"
              variant="contained"
            >
              Submit
            </ResetPassButton>
          </Grid>
        </form>
      </Box>
    </>
  );

  if (showSucess) {
    content = (
      <>
        <Box mt={3}>
          <Avatar style={{ ...AvatarStyle, backgroundColor: "#4caf50" }}>
            <LockIcon />
          </Avatar>
          <Grid container rowSpacing={2}>
            <Typography variant="h1">Forgot Password?</Typography>
          </Grid>
        </Box>
        <Box margin={5.5}>
          <Grid
            container
            rowSpacing={1}
            p={2.5}
            sx={{
              display: "flex",
            }}
          >
            <EmailSuccessMessage>
              Check your email for a link to reset your password.
            </EmailSuccessMessage>
          </Grid>
        </Box>
      </>
    );
  }
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Card sx={{ overflow: "visible" }} className={classes.card}>
          <CardContent
            sx={{
              paddingLeft: "32px",
              paddingRight: "25px",
              paddingTop: "40px",
            }}
          >
            {content}
          </CardContent>
        </Card>

        {alert?.error ? (
          <CustomizedSnackbars
            open={alert?.error}
            vertical="bottom"
            horizontal="center"
            message={alert?.message}
            color="error"
          />
        ) : (
          <></>
        )}
        {showSucess ? (
          <CustomizedSnackbars
            open={showSucess}
            vertical="bottom"
            horizontal="center"
            message="Password reset link sent successfully!"
            color="success"
          />
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
}

export default ResetPassword;
