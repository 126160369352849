import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import { ArrowRight as ArrowRightIcon } from "react-feather";
import Posts from "./DetailPosts";
import "./UserDetails.css";
import { getProfileDetail } from "../../../services/profile/profileService";
import { DetailContext } from "./DetailManagePage";
import OutlinedCard from "../../../components/Card/Card";
import ProfileActions from "./ProfileActions";

function UserDetails() {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offSet, setOffSet] = useState(0);
  const [profileInfo, setProfileInfo] = useState(0);

  const style = {
    bgcolor: "background.paper",
  };

    const detailContext = useContext(DetailContext);
    const {profileImage, coverImage, setOpenCrop,setCropCoverPhoto,handleImageSelect,handleImageDelete, handleCoverImageSelect} = detailContext;


    useEffect(() => {
        if (detailContext) {
            const { setProgress, profileId } = detailContext;
            getProfileDetail(profileId, rowsPerPage, offSet)
                .then(function (response) {
                    setProfileInfo(response);
                    setProgress(false);
                })
                .catch(function (error) {
                    return true;
                })

        }
    }, [])

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            height: 116,
            width: 1,
          },
        }}
      >
        <div className="detail-banner paper">
          <div className="detail-banner-inner">
            <div className="banner-text">
              <h2>{profileInfo?.post_info?.posts.length}</h2>
              <p>POSTS</p>
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div className="banner-text">
              <h2>{profileInfo?.view_total}</h2>
              <p>VIEWS</p>
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div className="banner-text">
              <h2>{profileInfo?.profile_info?.follower_count}</h2>
              <p>FOLLOWERS</p>
            </div>
          </div>
        </div>
      </Box>
      <div className="border-text">
        <p>Sales</p>
      </div>
      <div className="sales-content">
        <List component="div" sx={style} className="sales-box">
          <ListItem button>
            <ListItemText primary="7 Day Earnings" />
            <ArrowRightIcon />
          </ListItem>
          <Divider />
          <ListItem button divider>
            <ListItemText primary="Total Orders" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Customer/s" />
          </ListItem>
          <Divider light />
          <ListItem button>
            <ListItemText primary="Total Earnings" />
          </ListItem>
        </List>

        <List sx={style} component="div" className="sales-box">
          <ListItem button>
            <ListItemText primary="30 Day Earnings" />
            <ArrowRightIcon />
          </ListItem>
          <Divider />
          <ListItem button divider>
            <ListItemText primary="Total Orders" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Customer/s" />
          </ListItem>
          <Divider light />
          <ListItem button>
            <ListItemText primary="Total Earnings" />
          </ListItem>
        </List>

                <List sx={style} component="div" className="sales-box">
                    <ListItem button>
                        <ListItemText primary="Last Order"/>
                        <ArrowRightIcon/>
                    </ListItem>
                    <Divider/>
                    <ListItem button divider>
                        <ListItemText primary="Total Orders"/>
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Customer/s"/>
                    </ListItem>
                    <Divider light/>
                    <ListItem button>
                        <ListItemText primary="Total Earnings"/>
                    </ListItem>
                </List>
            </div>
            <div className="border-text">
                <p>Posts</p>
            </div>
            <div style={{marginTop: "20px"}}>
                <Posts/>
            </div>
            <div className="border-text-imagery">
                <p>Profile Imagery</p>
            </div>
            <div className="imagery-content" style={{marginBottom: "20px"}}>      
                <OutlinedCard {...{title:"Profile Picture",photoUrl:profileImage && (profileImage[0]?.preview || profileImage[0]?.presignedUrl), width:"100%",image:"Profile",handleImageSelect,handleCoverImageSelect, actions:<ProfileActions {...{setOpenCrop,image:"Profile",setCropCoverPhoto,handleImageSelect,handleImageDelete,handleCoverImageSelect}}/>}}/>
                <OutlinedCard {...{title:"Cover Picture",photoUrl:coverImage && (coverImage[0]?.preview || coverImage[0]?.presignedUrl), width:"100%",image:"Cover",handleCoverImageSelect,handleImageSelect, actions:<ProfileActions {...{setOpenCrop,image:"Cover",setCropCoverPhoto,handleImageSelect,handleImageDelete,handleCoverImageSelect}}/>}}/>
            </div>

        </>
    );
}

export default UserDetails;
