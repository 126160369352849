import axiosAdminInstance from "../../config/api/axiosAdmin";

const profileList = (data) => {
  return axiosAdminInstance.get(`/v1/users/get-profile-list?` + data);
};

const userList = (data) => {
  return axiosAdminInstance.get(`/v1/users/get-user-list?` + data);
};

const getUserDetailsById = (data) => {
  return axiosAdminInstance.get(`/v1/users/get-user-details?` + data);
};

const editUser = (data) => {
  return axiosAdminInstance.put(`/v1/users/edit-user`, data);
}

const deleteUser = (data) => {
  return axiosAdminInstance.delete(`/v1/users/delete-user?id=${data}`);
}


const assignProfilesToUser = (data) => {
  return axiosAdminInstance.put(`/v1/users/assign-profiles` , data);
}

export { profileList, userList, getUserDetailsById, editUser, deleteUser, assignProfilesToUser };
