import React from 'react';
import {Typography} from '@mui/material';

function HomePage({children}) {

    return (

        <Typography>
            Home Pageeeee
        </Typography>

    )
}

export default HomePage
