import React from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Typography,
  Divider,
  Paper,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./OrderDetail.css";
import OrderDetailsTable from "./OrderDetailsTable";
import { ArrowRight as ArrowRightIcon, Edit as EditIcon } from "react-feather";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useCallback } from "react";
import { getOrderDetails } from "../../../../services/orders/orderService";
import { useEffect } from "react";
import LoadingAnimation from "../../../../components/LoadingAnimation/LoadingAnimation";
import OderProfileDetails from "./OderProfileDetails";
import CustomizedSnackbars from "../../../../components/Alert/CustomizedSnackbars";
import { getErrorMessageFromErrorResponse } from "../../../../services/helper";

function OrderDetailPage() {
  const [orderDetails, setOrderDetails] = useState([]);
  const [totalOrderDetails, setTotalOrderDetails] = useState({
    orderNumber: null,
    orderTotalValue: null,
    productsCount: null,
    shops: null,
  });
  const [orderedProductList, setOrderedProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const { id } = useParams();

  const fetchListData = useCallback(async () => {
    setOrderedProductList([]);
    let param = {
      nautical_order_id: id,
    };
    let params = new URLSearchParams(param).toString();
    let productList = [];
    try {
      const data = await getOrderDetails(params);
      if (data) {
        setTotalOrderDetails({
          orderNumber: data?.data?.nauticalOrder?.number,
          orderTotalValue: data?.data?.nauticalOrder?.total?.gross?.localized,
          productsCount: data?.data?.nauticalOrder?.lines?.length,
          shops: "N/A",
        });
        setOrderDetails(data?.data?.nauticalOrder);

        for (let i = 0; i < data?.data?.nauticalOrder?.lines?.length; i++) {
          const photoUrl = !!data?.data?.nauticalOrder?.lines[i]?.thumbnail?.url
            ? data?.data?.nauticalOrder?.lines[i]?.thumbnail?.url
            : null;
          let obj = {
            id: data?.data?.nauticalOrder?.lines[i]?.id,
            productDescription: {
              photo: photoUrl,
              name: data?.data?.nauticalOrder?.lines[i]?.productName,
            },
            seller: "N/A",
            shop: "N/A",
            brand: "N/A",
            quantity: data?.data?.nauticalOrder?.lines[i]?.quantity,
            price:
              data?.data?.nauticalOrder?.lines[i]?.unitPrice?.gross?.localized,
            action: "action",
          };
          productList.push(obj);
        }
        setOrderedProductList(productList);
      }
    } catch (error) {
      console.log(error);
      setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) });
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    setLoading(true);
    fetchListData();
  }, [id, fetchListData]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      DashBoard
    </Link>,
    <Typography key="2" color="inherit">
      Manage
    </Typography>,
  ];

  return (
    <>
      <div>
        <>
          <div style={{ marginLeft: "10%", marginRight: "10%" }}>
            <Grid container justify="space-between" spacing={3}>
              <Grid item xs={9}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {breadcrumbs}
                  <Typography key="3" color="text.primary">
                    Users
                  </Typography>
                  <Typography key="3" color="text.primary">
                    Sales
                  </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <p>
              <h3>Last Order</h3>
            </p>
            {loading ? (
              <Box sx={{ width: "100%", height: "50vh" }}>
                <LoadingAnimation />
              </Box>
            ) : (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    "& > :not(style)": {
                      height: 116,
                      width: 1,
                    },
                  }}
                >
                  <Paper className="detail-banner">
                    <div className="detail-banner-inner">
                      <div className="banner-text">
                        <h2>{totalOrderDetails?.orderNumber}</h2>
                        <p>ORDER NUMBER</p>
                      </div>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <div className="banner-text">
                        <h2>{totalOrderDetails?.orderTotalValue}</h2>
                        <p>ORDER TOTAL</p>
                      </div>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <div className="banner-text">
                        <h2>{totalOrderDetails?.productsCount}</h2>
                        <p>PRODUCTS</p>
                      </div>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <div className="banner-text">
                        <h2>{totalOrderDetails?.shops}</h2>
                        <p>SHOPS</p>
                      </div>
                    </div>
                  </Paper>
                </Box>
                <div className="border-text">
                  <p>Profile Detail</p>
                </div>
                <Paper>
                  <OderProfileDetails data={[]} />
                </Paper>
                <div className="border-text">
                  <p>Order Details</p>
                </div>
                <p></p>
                <div>
                  <OrderDetailsTable
                    orderedProductList={orderedProductList}
                    loading={loading}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      </div>
      {alert.error ? (
        <CustomizedSnackbars
          open={alert.error}
          vertical="top"
          horizontal="right"
          message={alert.message}
          color="error"
        />
      ) : (
        alert.success && (
          <CustomizedSnackbars
            open={alert.success}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="success"
          />
        )
      )}
    </>
  );
}

export default OrderDetailPage;
