import React, { useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  Fade,
  Grid,
  Link,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { PlusCircle, User as UserIcon } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import { createProfile } from "../../../../../services/user/createuserProfileService";
import CustomizedSnackbars from "../../../../../components/Alert/CustomizedSnackbars";
import AddressAutoComplete from "../../../../../components/AddressAutocomplete";
import "./UserDetailsScreen.css";
import {
  Visibility,
  VisibilityOff,
  Image as ImageIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { getProfileDetail } from "../../../../../services/profile/profileService";
import { checkDuplicateSpecialCharactrer } from "../../../../../util/textConverter";
import HeadingBorderB from "../../../../../components/UI/HeadingBorderB";
import { List } from "immutable";
import { ContentState } from "react-draft-wysiwyg";
import ProfileListModal from "./ProfileListModal";
import { assignProfilesToUser, editUser, getUserDetailsById } from "../../../../../services/user/userService";
import { generatePreSignedURL } from "../../../../../services/aws/s3FileUpload";


const UserDetailScreen = () => {
  const [dob, setDob] = useState();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const [connectedProfile, setConnectedProfiles] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchemaProfile = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number is too short")
      .max(12, "Phone number is too long"),
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemaProfile),
  });

  const fetchUserData = useCallback(async () => {
    let param = {
      id: id,
    };
    let params = new URLSearchParams(param).toString();
    try {
      const data = await getUserDetailsById(params);
      if (data) {
        console.log(data);
        setUserDetails(data);
        setValue("firstName", data?.first_name);
        setValue("lastName", data?.last_name);
        setValue("email", data?.email);
        setValue("city", data?.city);
        setValue("state", data?.state);
        setValue("zip", data?.zip_code);
        setValue("country", data?.country);
        setValue("dob", data?.dob);
        setValue("phoneNumber", data?.phone);
        setValue("address", !!data?.address ? data?.address : []);

        if(data?.Profiles?.length > 0){ 
         let tempList = [];
          for (let i = 0; i < data?.Profiles?.length; i++) {
            const photoUrl =
            data?.Profiles[i]?.profile_photo_s3_object_id !== null && !!(data?.Profiles[i]?.profile_photo_s3_object_id) 
              ? await generatePreSignedURL(
                data?.Profiles[i]?.profile_photo_s3_object_id
                )
              : null;
            let tempProfile = {
              id:  data?.Profiles[i]?.id,
              name : data?.Profiles[i]?.display_name,
              photo : photoUrl
            };

            tempList.push(tempProfile);
          };
        setConnectedProfiles(tempList);
       }
      }  
    } catch (error) {
      console.log(error);
      setError(error);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
      setLoading(true);
      fetchUserData();
  }, [id, fetchUserData]);
  

  const assignProfiles = useCallback(async (listOfSeclectedProfiles) => { 
    setLoading(true);
    let data = {
      user_id: id,
      profiles: listOfSeclectedProfiles,
    };
    
    try {
     let responseData = await assignProfilesToUser(data);
     if(responseData) {
       console.log(responseData);
       fetchUserData();
     }
    } catch (error) {
      console.log(error);
      setError(error);
    }
    setLoading(false);
  },[id, fetchUserData, setError]);

  const onSubmit = async (data) => {
    console.log("submitted the form");
    let formData = {
      user_id: id,
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      date_of_birth: data.dob,
      address: data.address,
      city: data.city,
      state:data.state,
      zip_code: data.zip,
      country: data.country
    }
    setAlert({ ...alert, error: false });
    console.log(formData);
    editUser(formData)
      .then(function (response) {
        const { id } = response;
        navigate(`/Manage/Users/${id}`, { replace: true });
      })
      .catch(function (error) {
        setAlert({ ...alert, error: true, message: error.message });
      });
  };


  const handleRocketHandleChange = async (event) => {
    let isDuplicate = await checkDuplicateSpecialCharactrer(event.target.value);
    if (isDuplicate) {
      setError("rocketHandle", {
        type: "custom",
        message: "Special Character repeated",
      });
    } else {
      setValue("rocketHandle", event.target.value.toLowerCase());
      setError("rocketHandle", {});
    }
  };

  let listOfConnectedProfiles = [];
  if (!!connectedProfile) {
    listOfConnectedProfiles = connectedProfile.map((profile) => (
      <Link href={`/Manage/Profiles/${profile.id}`}
        className="profileListItemWrapper"
      >
        <Avatar src={profile.photo} />
        <Typography variant="purpleText" sx={{ marginLeft: "8px" }}>
          {profile?.name}
        </Typography>
      </Link>
  ))};

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      DashBoard
    </Link>,
    <Typography key="2" color="inherit">
      Manage Users
    </Typography>,
  ];

  return (
    <div>
      <>
        <div style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Grid container justify="space-between" spacing={3}>
            <Grid item xs={9}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
                <Typography key="2" color="text.primary">
                User Details
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <h2>
            <strong>{id ? "Edit Profile" : "Add Profile"}</strong>
          </h2>
          <Paper style={{ marginTop: 5 }}>
            <Box px={3} py={2}>
              <FormControl>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={2}>
                    <Button
                      variant="text"
                      startIcon={
                        <Avatar sx={{ bgcolor: "#5850EC" }}>
                          <UserIcon />
                        </Avatar>
                      }
                      disabled
                      className="button-text"
                    >
                      {"Edit User Details"}
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={4.25}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      label="First name"
                      fullWidth
                      margin="dense"
                      {...register("firstName")}
                      InputLabelProps={{
                        shrink: getValues("firstName") && true,
                      }}
                      error={errors.firstName ? true : false}
                    />
                    <Typography variant="inherit" color="textSecondary">
                      {errors.firstName?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={0.5}></Grid>
                  <Grid item xs={6} sm={4.25} sx={{marginTop: "8px"}}>
                    <AddressAutoComplete
                      id="address"
                      name="address"
                      label="Address"
                      fieldNames={{
                        route: "address",
                        postal_code: "zip",
                        locality: "city",
                        administrative_area_level_1: "state",
                        country: "country",
                      }}
                      setValue={setValue}
                      register={register}
                      alert={alert}
                      setAlert={setAlert}
                    />
                    <Typography variant="inherit" color="textSecondary">
                      {errors.address?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={1}></Grid>
                  <Grid item xs={6} sm={2}></Grid>
                  <Grid item xs={6} sm={4.25}>
                    <TextField
                      id="lastName"
                      name="lastName"
                      label="Last name"
                      fullWidth
                      margin="dense"
                      {...register("lastName")}
                      InputLabelProps={{
                        shrink: getValues("lastName") && true,
                      }}
                      onChange={(event) => handleRocketHandleChange(event)}
                      error={errors.lastName?.message ? true : false}
                    />
                    <Typography variant="inherit" color="textSecondary">
                      {errors.lastName?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={0.5}></Grid>
                  <Grid item xs={6} sm={4.25}>
                    <TextField
                      id="city"
                      name="city"
                      label="City"
                      fullWidth
                      InputLabelProps={{
                        shrink: getValues("City") && true,
                      }}
                      margin="dense"
                      {...register("city")}
                    />
                  </Grid>
                  <Grid item xs={6} sm={1}></Grid>
                  <Grid item xs={6} sm={2}></Grid>
                  <Grid item xs={6} sm={4.25}>
                  <TextField
                      id="email"
                      name="email"
                      label="Email"
                      autoComplete="false"
                      fullWidth
                      margin="dense"
                      {...register("email")}
                      error={errors.email ? true : false}
                      InputLabelProps={{
                        shrink: getValues("email") && true,
                      }}
                    />
                    <Typography variant="inherit" color="textSecondary">
                      {errors.email?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={0.5}></Grid>
                  <Grid item xs={6} sm={4.25}>
                    <TextField
                      id="state"
                      name="state"
                      label="State"
                      fullWidth
                      InputLabelProps={{
                        shrink: getValues("state") && true,
                      }}
                      margin="dense"
                      {...register("state")}
                    />
                  </Grid>
                  <Grid item xs={6} sm={1}></Grid>
                  <Grid item xs={6} sm={2}></Grid>
                  <Grid item xs={6} sm={4.25}>
                  <TextField
                      id="phoneNumber"
                      name="phoneNumber"
                      label="Phone Number"
                      fullWidth
                      disabled
                      margin="dense"
                      {...register("phoneNumber")}
                      InputLabelProps={{
                        shrink: getValues("phoneNumber") && true,
                      }}
                      error={errors.phoneNumber ? true : false}
                    />
                    <Typography variant="inherit" color="textSecondary">
                      {errors.phoneNumber?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={0.5}></Grid>
                  <Grid item xs={6} sm={4.25}>
                    <TextField
                      id="zip"
                      name="zip"
                      label="Zip"
                      fullWidth
                      InputLabelProps={{
                        shrink: getValues("zip") && true,
                      }}
                      margin="dense"
                      {...register("zip")}
                    />
                  </Grid>
                  <Grid item xs={6} sm={1}></Grid>
                  <Grid item xs={6} sm={2}></Grid>
                  <Grid item xs={6} sm={4.25}  sx={{marginTop: "8px"}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="Date of Birth"
                        id="dob"
                        name="dob"
                        value={dob}
                        onChange={(newDob) => {
                          setDob(newDob);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...register("dob")}
                            fullWidth
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} sm={0.5}></Grid>
                  <Grid item xs={6} sm={4.25}>
                    <TextField
                      id="country"
                      name="country"
                      label="Country"
                      fullWidth
                      InputLabelProps={{
                        shrink: getValues("country") && true,
                      }}
                      margin="dense"
                      {...register("country")}
                    />
                  </Grid>
                  <Grid item xs={6} sm={1}></Grid>
                  <Grid item xs={6} sm={2}></Grid>
                  <Grid item xs={6} sm={9}>
                    <HeadingBorderB heading={"Connected Profiles"} />
                    <div>{listOfConnectedProfiles}</div>
                    <Link className="profileListItemWrapper" onClick={handleOpen}>
                        <PlusCircle height={"40px"} width={"40px"} color={"#263238"}/>
                      <Typography
                        variant="blackText"
                        sx={{ marginLeft: "8px", fontWeight: 500 }}
                      >
                        Connect a Profile
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={1}></Grid>
                  <Grid item xs={6} sm={6.75}></Grid>
                  <Grid item xs={6} sm={4.25} className="add-user-button">
                    <Button
                      color="primary"
                      className="cancel-btn"
                      onClick={() =>
                        navigate("/Manage/ManageListings/Users", { replace: true })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit(onSubmit)}
                      className="next-btn"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
            </Box>
          </Paper>
        </div>
        <div>
        <ProfileListModal open={open} handleClose={ handleClose} handleOpen={handleOpen} userInfo={userDetails} assignProfiles={assignProfiles} connectedProfile={connectedProfile}/>
        </div>
        {alert.error && (
          <CustomizedSnackbars
            open={alert.error}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="error"
          />
        )}
      </>
    </div>
  );
};

export default UserDetailScreen;
