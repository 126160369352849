import axios from "axios";
import _ from "lodash";
import { APIBaseUrl } from "./baseUrl";

const axiosAdminInstance = axios.create({
  baseURL: APIBaseUrl,
  responseType: "json",
});

axiosAdminInstance.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem("adminToken");
  request.headers["x-api-key"] = `${process.env.REACT_APP_API_AUTH_KEY}`;
  if (accessToken) {
    request.headers.Authorization = `${accessToken}`;
  }
  return request;
});

axiosAdminInstance.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data

  (response) => {
    return response.data;
  },
  (error) => {
    const errorObject = {
      code: 500,
      message: "Server not responding",
    };
    if (error.response) {
      errorObject.code =
        _.get(error, "response.data.code") || _.get(error, "response.data.key");
      errorObject.message =
        _.get(error, "response.data.title") ||
        _.get(error, "response.data.message");
    } else if (error.request) {
    } else {
      errorObject.message = "Something went wrong";
    }
    if (
      errorObject.code === "expired_token" ||
      errorObject.code === "authentication_error"
    ) {
      localStorage.removeItem("adminToken");
      localStorage.removeItem("adminUser");
      window.location.href = "/login";
      return { data: {} };
    }
    const originalConfig = error.config;
    if (originalConfig.url !== "/v1/users/get-user-info" && error.response) {
      if (error.response.status === 401) {
        const body = {
          refresh_token: localStorage.getItem("adminRefreshToken"),
        };
        axiosAdminInstance
          .post("/v1/users/refresh-token", body)
          .then(function (response) {
            localStorage.setItem("adminToken", response.access_token);
          })
          .catch(function (error) {
            localStorage.removeItem("adminRefreshToken");
            localStorage.removeItem("adminToken");
            localStorage.removeItem("user");
            localStorage.removeItem("userInfo");
            window.location.href = "/login";
          });
        return axiosAdminInstance(originalConfig);
      }
    }
    return Promise.reject(errorObject);
  }
);

export default axiosAdminInstance;
