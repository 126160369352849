import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { User as UserIcon } from "react-feather";

import "./AdminNew.css";
import { createAdminUser } from "../../../../services/admin/admin";
import CustomizedSnackbars from "../../../../components/Alert/CustomizedSnackbars";
import LoadingAnimation from "../../../../components/LoadingAnimation/LoadingAnimation";

const AdminNew = () => {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const validationSchemaProfile = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email().required("Email is required"),
  });
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemaProfile),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setShowError(false);
    setShowSuccess(false);
    setError("");
    const { firstName, lastName, email } = data;
    let dataToSend = {
      email: email,
      first_name: firstName,
      last_name: lastName,
    };

    try {
      const response = await createAdminUser(dataToSend);
      if(response){
        setShowSuccess(true);
      }
    } catch (error) {
      setShowError(true);
      setError(error?.message);
    }
    setLoading(false);
    reset();
    return;
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Admin
    </Link>,
    <Typography key="2" color="text.primary">
      Manage Users
    </Typography>,
    <Typography key="3" color="text.primary">
      Admin Users
    </Typography>,
  ];
  return (
    <div>
      <>
        <div style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Grid container justify="space-between" spacing={3}>
            <Grid item xs={9}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
              <Typography variant="h1">Create Admin</Typography>
            </Grid>
            <Container maxWidth={false}>
              <Box mt={3}>
                <Card
                  sx={{
                    background: "#FFFFFF",
                    boxShadow:
                      "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
                    borderRadius: "4px",
                    padding: "36px 24px 24px 16px",
                  }}
                >
                  {loading ? (
                  <Box sx={{ width: "100%", height: "50vh" }}>
                    <LoadingAnimation />
                  </Box>) : 
                  <Box
                    sx={{
                      widht: "100%",
                    }}
                  >
                    <form>
                      <Grid container spacing={1}>
                        <Grid item xs={6} sm={2}>
                          <Button
                            variant="text"
                            startIcon={
                              <Avatar sx={{ bgcolor: "#5850EC" }}>
                                <UserIcon />
                              </Avatar>
                            }
                            disabled
                            className="button-text"
                          >
                            Create Admin
                          </Button>
                        </Grid>
                        <Grid item xs={6} sm={4.25}>
                          <TextField
                            id="firstName"
                            name="firstName"
                            label="First name"
                            variant="outlined"
                            {...register("firstName")}
                            sx={{ width: "100%", marginBottom: "20px" }}
                            error={errors.firstName ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.firstName?.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={0.5}></Grid>
                        <Grid item xs={6} sm={4.25}>
                          <TextField
                            id="lastName"
                            name="lastName"
                            label="Last name"
                            variant="outlined"
                            {...register("lastName")}
                            sx={{ width: "100%", marginBottom: "20px" }}
                            error={errors.lastName ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.lastName?.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={1}></Grid>
                        <Grid item xs={6} sm={2}></Grid>
                        <Grid item xs={6} sm={4.25}>
                          <TextField
                            id="email"
                            name="email"
                            label="Email"
                            variant="outlined"
                            {...register("email")}
                            sx={{ width: "100%", marginBottom: "20px" }}
                            error={errors.email ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.email?.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={0.5}></Grid>
                        <Grid item xs={6} sm={4.25}></Grid>
                        <Grid item xs={6} sm={6.75}></Grid>
                        <Grid item xs={6} sm={4.25}>
                          <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <Button
                              variant="text"
                              sx={{
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "24px",
                                textTransform: "uppercase",
                                color: "#263238",
                              }}
                              onClick={() =>
                                navigate("/Manage/Categories", {
                                  replace: true,
                                })
                              }
                            >
                              {" "}
                              Cancel
                            </Button>
                            <Button
                              sx={{
                                background: "#5850EC",
                                borderRadius: "4px",
                                color: "#FFFFFF",
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "24px",
                                textTransform: "uppercase",
                                padding: "6px 16px",
                                "&.Mui-disabled": {
                                  backgroundColor: "#5850EC",
                                  opacity: 0.5,
                                  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                                  borderRadius: "4px",
                                  color: "white !important",
                                },
                                marginLeft: "16px",
                              }}
                              onClick={handleSubmit(onSubmit)}
                            >
                              {" "}
                              Create
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                  }
                </Card>
              </Box>
            </Container>
            {showError ? (
              <CustomizedSnackbars
                open={showError}
                vertical="bottom"
                horizontal="center"
                message={error}
                color="error"
              />
            ) : (
              <></>
            )}
            {showSuccess ? (
              <CustomizedSnackbars
                open={showSuccess}
                vertical="bottom"
                horizontal="center"
                message={"Account created successfully and email verification mail sent to user."}
                color="success"
              />
            ) : (
              <></>
            )}
          </Grid>
        </div>
      </>
    </div>
  );
};

export default AdminNew;
