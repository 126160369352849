import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Box, Icon, Typography } from "@mui/material";
import { ChevronDown, ChevronUp, Grid } from "react-feather";
import { createSearchParams, useNavigate } from "react-router-dom";
import CustomizedSnackbars from "../../../components/Alert/CustomizedSnackbars";
import { deleteAdminUser, sendResetEmail } from "../../../services/admin/admin";
import { getErrorMessageFromErrorResponse } from "../../../services/helper";

const AdminListActions = (props) => {
  const {
    row: { email },
    adminId,
    fetchListData,
  } = props;

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alert, setAlert] = React.useState({});
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEditAction = () => {
    navigate(`/Manage/Admin/Edit/${adminId}`, {
      replace: true,
      state: { admin_id: adminId },
    });
    handleClose();
  };

  const handleResetPassword = () => {
    setAlert({});
    handleClose();
    let data = {
     email: email
    };
    sendResetEmail(data)
      .then(function (response) {
        setAlert({ ...alert, success: true, message: "Password reset email sent successfully" });
        handleClose();
      })
      .catch(function (error) {
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
        console.error("edit post error:", error);
        handleClose();
        return true;
      })
  };

  const handleDeleteAction = async () => {
    handleClose();
    let param = {
      id: adminId,
    };
    let params = new URLSearchParams(param).toString();
    deleteAdminUser(params)
      .then(function (response) {
        fetchListData();
      })
      .catch(function (error) {
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
        console.error("delete Admin user error:", error)
        return true;
      })
  };

  let actions = (
    <div>
      <MenuItem onClick={handleEditAction}>
        <Typography variant="blackText">Edit</Typography>
      </MenuItem>
      <MenuItem onClick={handleResetPassword}>
        <Typography variant="blackText">Reset Password</Typography>
      </MenuItem>
      {/* <MenuItem onClick={handleDeleteAction}>
        <Typography variant="blackText">Delete</Typography>
      </MenuItem> */}
    </div>
  );
  return (
    <>
    <div>
      <Button
        sx={{
          textTransform: "unset",
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Typography variant="blackText" sx={{ width: "60px" }}>
          Action
        </Typography>
        {open ? (
          <Icon
            sx={{ marginLeft: "14px", display: "flex", alignItems: "center" }}
          >
            {" "}
            <ChevronUp color="#3F51B5" />{" "}
          </Icon>
        ) : (
          <Icon
            sx={{ marginLeft: "14px", display: "flex", alignItems: "center" }}
          >
            {" "}
            <ChevronDown color="black" />{" "}
          </Icon>
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        sx={{
          "& .MuiMenu-root": {},
          "& .MuiMenu-list": {
            padding: "6px 0px 6px 4px",
          },
        }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
      >
        {actions}
      </Menu>
    </div>
    {alert.error ? (
      <CustomizedSnackbars
        open={alert.error}
        vertical="top"
        horizontal="center"
        message={alert.message}
        color="error"
      />
    ) : (
      alert.success && (
        <CustomizedSnackbars
          open={alert.success}
          vertical="top"
          horizontal="center"
          message={alert.message}
          color="success"
        />
      )
    )}
    </>
  );
};

export default AdminListActions;
