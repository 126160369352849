import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, IconButton, Typography } from "@mui/material";
import { Edit as EditIcon } from "react-feather";
import CustomizedSnackbars from "../../../components/Alert/CustomizedSnackbars";
import UploadFile from "../../../components/UploadFile/UploadFile";

export default function ProfileActions({ setOpenCrop, image, setCropCoverPhoto, handleImageSelect,handleCoverImageSelect, handleImageDelete }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [uploadFile, setUploadFile] = React.useState(false);
  const actionList = ["Delete Photo"]
  const open = Boolean(anchorEl);
  const [alert, setAlert] = React.useState({});
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetCropCover = async() => {
    if (image === "Cover") {
      setCropCoverPhoto(true);
    }
    else {
      setCropCoverPhoto(false);
    }
  }
  const handleActions = async(action,file) =>{
    if (action=== "Delete Photo") {
      image=== "Cover" ? handleImageDelete(true) : handleImageDelete(false);
    }
    else if (action === "Replace Photo" && file.length>0)
    {
      image === "Cover" ? handleCoverImageSelect(file) : handleImageSelect(file);
    }

  } 

  const handleButtonClick = async (action, file=[]) => {
    handleClose();
    await handleSetCropCover();
    await handleActions(action,file);
  }


  let actions = [];

  if (!!actionList) {
    actions = actionList.map((action) => (
      <MenuItem
        onClick={() => handleButtonClick(action)}
        key={action}
        value={action}
      >
        <Typography variant="blackText">{action}</Typography>
      </MenuItem>
    ));
    actions.push(
      <MenuItem
        onClick={() => handleButtonClick("Replace Photo")}
        key={"Replace Photo"}
        value={"Replace Photo"}
      ><UploadFile
        setFile={(file) => { handleButtonClick("Replace Photo",file) }}
        onDragnDrop={false}
      ><Typography variant="blackText">Replace Photo</Typography></UploadFile></MenuItem>)
  }

  return (
    <div>
      <IconButton
        sx={{
          textTransform: "unset",
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <EditIcon color="#263238" height="18px" width="18px" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        sx={{
          "& .MuiMenu-root": {},
          "& .MuiMenu-list": {
            padding: "6px 0px 6px 4px",
          },
        }}
        transformOrigin={{
          horizontal: "center",
        }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {actions}

      </Menu>
      {
        alert.error ?
          <CustomizedSnackbars
            open={alert.error}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="error"
          /> :
          alert.success && <CustomizedSnackbars
            open={alert.success}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="success"
          />
      }
    </div>
  );
}
