function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0")
}

const generateRandomString = (len) => {
  var arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

const getErrorMessageFromErrorResponse = (error) => {
  return error?.validation ? error?.validation?.query?.message || error?.validation?.body?.message || error?.validation?.params?.message : error?.message
}

export { generateRandomString,getErrorMessageFromErrorResponse }