import _ from 'lodash';

// Use for finding value in the object on a particular path with null, undefined handling
// Parameter object - pass object of json
//           path   - pass value path of given object
//           defaultValue - (optional) pass default value which you get in return if value not found on given path
// Return value  - return value of the path
//        result - return false if any empty, null or undefined value find on the path, otherwise, return true
const _getValue = (object, path, defaultValue = null) => {
    try {
        const getResult = _.get(object, path, defaultValue);
        let value = getResult === null ? '' : getResult;
        const result = value === '' ? false : true;
        if (!result) {
            value = defaultValue;
        }
        return {value, result};
    } catch {
        return {value: '', result: false};
    }
};

export {_getValue};
