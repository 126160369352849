export const formateDate =(date)=>{
    if((date!=="N/A" && date !== null && date !== undefined)){ 
        let formattedDate;
        let isoDateArray = new Date(date).toISOString().split("T");
        let dateItem, hour;
        dateItem = isoDateArray[0];
        let newForamteDate = new Date(date).toISOString().split("T").join(" ").split(".")[0];
        let temp =dateItem.split("-");
        const mmddyy = `${temp[1]}/${temp[2]}/${temp[0]}`;
        hour = isoDateArray[1].split(":")[0];
        let timeTemp = newForamteDate.split(" ")[1].split(":");
        let timeHHMM = `${timeTemp[0]}:${timeTemp[1]}`;
        if(hour >= 12){
            formattedDate = `${dateItem} . ${hour-12 === 0 ? 12 : hour-12 }pm`;
            hour = `${hour-12 === 0 ? 12 : hour-12 }`;
            let temp = timeHHMM.split(":");
            temp[0] = hour;
            timeHHMM = `${temp[0]+":"+temp[1]+ " "}PM`;
            hour = hour + "pm";
        }else{
            formattedDate = `${dateItem} . ${hour}am`;
            hour = `${hour}am`;
            timeHHMM = `${timeHHMM +" "}AM`
        }
      
    
        return {fullDate: formattedDate, date: dateItem, time: hour, newForamteDate, "mmddyy": mmddyy, timeHHMM : timeHHMM };
     }
     return {fullDate: "N/A", date: "N/A", time: "N/A", newForamteDate: "N/A", "mmddyy": "N/A", timeHHMM : "N/A"}
};

