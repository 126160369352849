import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Box,
  SvgIcon,
  Typography,
  InputBase,
  Card,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import {
  ArrowRight as ArrowRightIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from "react-feather";
import { _getValue } from "../../../util/lodashWrapper";
import SortIcon from "../../../components/UI/SortIcon";
import { adminList } from "../../../services/admin/admin";
import LoadingAnimation from "../../../components/LoadingAnimation/LoadingAnimation";
import AdminListActions from './AdminListActions';
import { Link } from "react-router-dom";
const labelColors = {
  active: "success",
  paused: "warning",
  inactive: "drafted",
};

const AdminList = () => {
  const searchForm = useRef(null);
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [clearButtonVisible, setClearButtonVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [rowCountState, setRowCountState] = useState(0);

  const handleSearch = () => {
    const form = searchForm.current;
    if (form.value) {
      setSearchValue(form.value);
      setClearButtonVisible(true);
    }
  };

  const handleSearchClear = () => {
    setSearchValue("");
    searchForm.current.value = "";
    setClearButtonVisible(false);
  };

  const formatDateField = (value) => {
    const dateValues = value.split("T");
    const date = dateValues[0];
    const time = dateValues[1].slice(0, -5);

    return `${date} ${time}`;
  };



  const getAdminListData = useCallback(async () => {
    let param = {
      limit: pageSize,
      offset: page * pageSize,
    };
    if (searchValue) {
      param = { ...param, search: searchValue };
    }
    let searchParams = new URLSearchParams(param).toString();
    let adminlist = [];
    try {
      const data = await adminList(searchParams);
      if (data) {
        setRowCountState(data?.count);
        adminlist = data?.users?.map((user) => ({
          id: _getValue(user, "id").value,
          email: _getValue(user, "email").value,
          firstName: `${_getValue(user, "first_name", "").value}`,
          lastName: `${_getValue(user, "last_name", "").value}`,
          dateAdded: formatDateField(
            `${_getValue(user, "date_created").value}`
          ),
          action: "Action",
        }));
        setAdmins(adminlist);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [page, pageSize]);

  const fetchAdminsData = useCallback(() => {
    setLoading(true);
    getAdminListData();
  }, [getAdminListData]);

  const columns = [
    { field: "id", headerName: "Id", width: 130, hide: true },
    {
      field: "email",
      headerName: "E-Mail",
      renderCell: (params) => {
        return(
        <Link to={`/Manage/Admin/Edit/${params.id}`}>
          <Typography variant="purpleText">{params.value}</Typography>
        </Link>);
      },
      width: 300,
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 300,
      renderCell: (params) => {
        return(
        <Link to={`/Manage/Admin/Edit/${params.id}`}>
          <Typography variant="purpleText">{params.value}</Typography>
        </Link>);
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 300,
      renderCell: (params) => {
        return( 
        <Link to={`/Manage/Admin/Edit/${params.id}`}>
          <Typography variant="purpleText">{params.value}</Typography>
        </Link>);
      },
    },

    {
      field: "dateAdded",
      headerName: "Date Added",
      width: 300,
      renderCell: (params) => {
        return <Typography variant="purpleText">{params.value}</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      type: "actions",
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <AdminListActions adminId={params?.id} row={params.row} fetchListData={fetchAdminsData} />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getAdminListData().then();
  }, [page, pageSize, getAdminListData]);

  return (
    <Card
      sx={{
        background: "#FFFFFF",
        boxShadow:
          "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
        borderRadius: "4px",
      }}
    >
      <div style={{ height: "10vh", width: "100%" }}>
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid container spacing={2}>
            <Grid item>
              <InputBase
                inputRef={searchForm}
                sx={{
                  width: "420px",
                  height: "36px",
                  background: "#FFFFFF",
                  border: "1px solid #E0E0E0",
                  borderRadius: "4px",
                  textAlign: "right",
                  display: "flex",
                  padding: 2,
                }}
                startAdornment={
                  <SvgIcon fontSize="small" color="action" sx={{}}>
                    <SearchIcon />
                  </SvgIcon>
                }
                placeholder="Search customers"
                variant="outlined"
                disabled={clearButtonVisible}
              />
            </Grid>
            <Grid item>
              {clearButtonVisible === true ? (
                <Button
                  sx={{
                    height: "36px",
                    background: "#F5F5F5",
                    borderRadius: "4px",
                    color: "#263238",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "24px",
                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                    textTransform: "uppercase",
                  }}
                  onClick={handleSearchClear}
                >
                  Clear Filter
                </Button>
              ) : (
                <Button
                  sx={{
                    background: "#F5F5F5",
                    borderRadius: "4px",
                    color: "#263238",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "24px",
                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                    textTransform: "uppercase",
                    padding: "6px 16px",
                  }}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </div>
      {loading ? (
        <Box sx={{ width: "100%", height: "50vh" }}>
          <LoadingAnimation />
        </Box>
      ) : (
        <Box sx={{ widht: "100%" }}>
          <DataGrid
            autoHeight
            rows={admins}
            columns={columns}
            checkboxSelection
            disableColumnMenu={true}
            sx={{
              "& .MuiDataGrid-virtualScroller": {
                bg: "#eee",
                opacity: "0.9",
              },
            }}
            components={{
              ColumnSortedAscendingIcon: SortIcon,
              ColumnSortedDescendingIcon: SortIcon,
              ColumnUnsortedIcon: SortIcon,
            }}
            rowCount={rowCountState}
            loading={loading}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
        </Box>
      )}
    </Card>
  );
};

export default AdminList;
