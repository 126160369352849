import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TopBar from "./TopBar";
import { Avatar, Link, ListSubheader } from "@mui/material";
import {
  Mail as MailIcon2,
  MessageCircle as MessageCircleIcon,
  MessageSquare as CommentsIcon,
  Settings as UserSettings,
  ShoppingCart as ShoppingCartIcon,
  User as UserIcon,
  Users as UsersIcon,
} from "react-feather";
import { Outlet, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../util/textConverter";
import NavListItem from "./NavListItem";
import SimpleAccordion from "./Accordion/SimpleAccordion";
import { ListItemStyled, ListStyledButton } from "./Styled";

const drawerWidth = 256;
export default function ClippedDrawer() {
  const { first_name, last_name, role, photo_url } = JSON.parse(
    localStorage.getItem("userInfo")
  );
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <TopBar></TopBar>

        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              overflow: "",
            },
            [`& .MuiDrawer-docked`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />

          <Box height="100%" display="flex" flexDirection="column">
            <Box p={2} display="flex" justifyContent="center">
              <Link to="/">
                <Avatar
                  src={photo_url}
                  alt="Logo"
                  sx={{ border: "1px solid black" }}
                />
              </Link>
            </Box>

            <Box mb={2}>
              <Box textAlign="center">
                <Link
                  to="/app/account"
                  variant="h5"
                  color="textPrimary"
                  underline="none"
                >
                  {`${first_name} ${last_name}`}
                </Link>
                <Typography variant="body2" color="textSecondary">
                  {capitalizeFirstLetter(role, "_")}
                </Typography>
              </Box>
            </Box>

            <Divider />
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <strong>MANAGE</strong>
                </ListSubheader>
              }
            >
              <SimpleAccordion title="Accounts" icon={<UsersIcon />}>
                <ListItemStyled 
                  onClick={() =>
                    navigate("/Manage/ManageListings/Users", {
                      replace: true,
                    })
                  }
                >
                  <ListItemIcon>
                    <UsersIcon />
                  </ListItemIcon>
                  <ListStyledButton>
                    <ListItemText primary="Users " />
                  </ListStyledButton>
                </ListItemStyled>
                <ListItemStyled
                  onClick={() =>
                    navigate("/Manage/Profiles", {
                      replace: true,
                    })
                  }
                >
                  <ListItemIcon>
                    <UsersIcon />
                  </ListItemIcon>
                  <ListStyledButton>
                    <ListItemText primary="Profiles " />
                  </ListStyledButton>
                </ListItemStyled>
                <ListItemStyled
                  onClick={() =>
                    navigate("/Manage/Brands", {
                      replace: true,
                    })
                  }
                >
                  <ListItemIcon>
                    <ShoppingCartIcon />
                  </ListItemIcon>
                  <ListStyledButton>
                    <ListItemText primary="Brands" />
                  </ListStyledButton>
                </ListItemStyled>
                <ListItemStyled
                  onClick={() =>
                    navigate("/Manage/Posts", {
                      replace: true,
                    })
                  }
                >
                  <ListItemIcon>
                    <UsersIcon />
                  </ListItemIcon>
                  <ListStyledButton>
                    <ListItemText primary="Posts " />
                  </ListStyledButton>
                </ListItemStyled>
                <ListItemStyled
                  onClick={() =>
                    navigate("/Manage/Orders", {
                      replace: true,
                    })
                  }
                >
                  <ListItemIcon>
                    <UsersIcon />
                  </ListItemIcon>
                  <ListStyledButton>
                    <ListItemText primary="Orders " />
                  </ListStyledButton>
                </ListItemStyled>
                <ListItemStyled
                  onClick={() =>
                    navigate("/Manage/Categories", {
                      replace: true,
                    })
                  }
                >
                  <ListItemIcon>
                    <UsersIcon />
                  </ListItemIcon>
                  <ListStyledButton>
                    <ListItemText primary="Categories " />
                  </ListStyledButton>
                </ListItemStyled>
              </SimpleAccordion>
              <SimpleAccordion
                title="Reported Comments"
                icon={<CommentsIcon />}
              >
                <ListItemStyled
                  onClick={() =>
                    navigate("/Manage/Comments", {
                      replace: true,
                    })
                  }
                >
                  <ListItemIcon>
                    <CommentsIcon />
                  </ListItemIcon>
                  <ListStyledButton>
                    <ListItemText primary="Comments" />
                  </ListStyledButton>
                </ListItemStyled>
              </SimpleAccordion>
            </List>
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <strong>EXTRA</strong>
                </ListSubheader>
              }
            >
              <SimpleAccordion title="Settings" icon={<UserSettings />}>
                <ListItemStyled
                  onClick={() =>
                    navigate("/Settings", {
                      replace: true,
                    })
                  }
                >
                  <ListItemIcon>
                    <UserSettings />
                  </ListItemIcon>
                  <ListStyledButton>
                    <ListItemText primary="Settings" />
                  </ListStyledButton>
                </ListItemStyled>
              </SimpleAccordion>
              <SimpleAccordion title="Admin" icon={<UserIcon />}>
                <ListItemStyled
                  onClick={() =>
                    navigate("/Admin", {
                      replace: true,
                    })
                  }
                >
                  <ListItemIcon>
                    <UserIcon />
                  </ListItemIcon>
                  <ListStyledButton>
                    <ListItemText primary="Admin" />
                  </ListStyledButton>
                </ListItemStyled>
              </SimpleAccordion>
            </List>
          </Box>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 3 ,pl: 3 }}>
          <Toolbar />
          <Outlet></Outlet>
        </Box>
      </Box>
    </>
  );
}
