import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import AuthContextProvider from "./context/AuthContext";
import { AppRouter } from "./router/AppRouter";
import { AppTheme } from "./theme/AppTheme";

function App() {
  return (
    <AppTheme>
      <GoogleReCaptchaProvider reCaptchaKey= {process.env.REACT_APP_CAPTCHA_SITE_KEY}>
        <AuthContextProvider>
          <AppRouter />
        </AuthContextProvider>
      </GoogleReCaptchaProvider>
    </AppTheme>
  );
}

export default App;
