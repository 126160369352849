import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SigInButton, useStyles } from "./style";
import { ReactComponent as LockIcon } from "../../assets/images/icons/lock.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { _getValue } from "../../util/lodashWrapper";
import { useNavigate } from "react-router-dom";
import { userInfo, verifyCaptcha } from "../../services/user/authUserService";
import CustomizedSnackbars from "../../components/Alert/CustomizedSnackbars";
import { Eye, EyeOff } from "react-feather";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function AdminSignIn() {
  const [validUser, setValidUser] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [captchVerified, setCaptchVerified] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [alert, setAlert] = useState({});
  const navigate = useNavigate();
  const { login, setUser } = useContext(AuthContext);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    setCaptchVerified(false);
    setAlert({});
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("login");
    let data = {
      response: token,
    };
    try {
      const tokenVerification = await verifyCaptcha(data);
      if (!!tokenVerification?.success) {
        setCaptchVerified(true);
      } 
    } catch (error) {
      console.log(error);
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Please enter a valid email address"),
    password: Yup.string()
      .required("Incorrect password")
      .min(8, "Incorrect password")
      .max(16, "Incorrect password"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, getValues, reset } =
    useForm(formOptions);
  const { errors } = formState;

  const onSubmit = async () => {
    setAlert({});
    const email = getValues("email");
    const password = getValues("password");
    try {
      if (!captchVerified) {
        throw new Error(
          "Captcha verification failed, Please refresh to try again!"
        );
      }
      const response = await login(email, password);
      if (_getValue(response, "user").result) {
        localStorage.setItem(
          "adminToken",
          _getValue(response, "user.stsTokenManager.accessToken").value
        );
        const responseUserInfo = await userInfo();
        if (
          responseUserInfo.role === "super_admin" ||
          responseUserInfo.role === "creator" ||
          responseUserInfo.role === "admin"
        ) {
          setUser(
            _getValue(response, "user").value,
            _getValue(response, "user.stsTokenManager.refreshToken").value
          );
          localStorage.setItem("userInfo", JSON.stringify(responseUserInfo));
          setValidUser(true);
        }
      }
    } catch (err) {
      localStorage.removeItem("adminToken");
      setAlert({ ...alert, error: true, message: err.message });
    }

    if (validUser) {
      handleReCaptchaVerify();
      navigate("/Home", {
        replace: true,
      });
    } else {
      reset();
    }
    return false;
  };

  const gotoResetPassword = (e) => {
    e.preventDefault();
    navigate("/reset-password");
  };

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const AvatarStyle = {
    top: "-32px",
    left: "24px",
    color: "#fff",
    width: "64px",
    height: "64px",
    padding: "4px",
    position: "absolute",
    borderRadius: "4px",
    backgroundColor: "#4caf50",
    zIndex: 0,
    overflow: "visible",
  };

  return (
    <div className={classes.root} style={{ paddingBottom: 0 }}>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justify="flex-end"
      >
        <Card sx={{ overflow: "visible" }} className={classes.card}>
          <CardContent
            sx={{
              paddingLeft: "32px",
              paddingRight: "25px",
              paddingTop: "40px",
            }}
          >
            <Box mt={3}>
              <Avatar style={AvatarStyle}>
                <LockIcon />
              </Avatar>
              <Grid container rowSpacing={2}>
                <Typography variant="h1">
                  Sign in to Rocket Administration
                </Typography>
                <Typography variant="subtitleLogin">
                  Sign in on the internal platform
                </Typography>
              </Grid>
            </Box>
            <Divider
              variant="fullWidth"
              component={"div"}
              sx={{
                "&::before, &::after": {
                  borderColor: "secondary.light",
                },
                marginY: 1,
              }}
            />
            <Box mt={3}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
                <Grid
                  container
                  rowSpacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "initial" : "center",
                  }}
                >
                  <TextField
                    fullWidth
                    autoFocus
                    label="Email Address"
                    margin="normal"
                    name="email"
                    type="email"
                    variant="outlined"
                    className={classes.subtitleLogin}
                    error={!!errors.email?.message}
                    helperText={
                      errors.email?.message ? "enter a valid email" : ""
                    }
                    {...register("email")}
                  />
                </Grid>
                <Grid
                  container
                  rowSpacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "initial" : "center",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Password"
                    margin="normal"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <Eye width={20} height={15} color="#263238" />
                            ) : (
                              <EyeOff width={20} height={15} color="#263238" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      autoComplete: "on",
                    }}
                    variant="outlined"
                    error={!!errors.password?.message}
                    helperText={
                      errors.password?.message ? "enter a valid password" : ""
                    }
                    {...register("password")}
                  />
                </Grid>
                <Grid mt={1} mb={3}>
                  <Typography variant="purpleText2">
                    <Link
                      component="a"
                      href=""
                      underline="always"
                      color="primary"
                      onClick={gotoResetPassword}
                    >
                      Forgot Password?
                    </Link>
                  </Typography>
                </Grid>

                <Grid
                  mt={2}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <SigInButton type="submit" disableRipple>
                    Log In
                  </SigInButton>
                </Grid>
              </form>
            </Box>
          </CardContent>
        </Card>

        {alert.error ? (
          <CustomizedSnackbars
            open={alert?.error}
            vertical="bottom"
            horizontal="center"
            message={alert?.message}
            color="error"
          />
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
}
