import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Box, Icon, Typography } from "@mui/material";
import { ChevronDown, ChevronUp } from "react-feather";
import { createSearchParams, useNavigate } from "react-router-dom";
import { deletePost,editPost } from "../../../../services/postsService";
import CustomizedSnackbars from "../../../../components/Alert/CustomizedSnackbars";
import { getErrorMessageFromErrorResponse } from "../../../../services/helper";

export default function PostListActions(props) {
  const {
    row: { status },
    postId,
    profile_id,
    fetchListData,
    is_hidden
  } = props;

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alert,setAlert] = React.useState({});
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditAction = () => {
    navigate("/Manage/EditPost", {
      replace: true,
      state: { post_id: postId, profile_id: profile_id },
    });
    handleClose();
  };

  const handleHideAction = () => {
    handleClose();
    handleClose();
    let param = {
      post_id: postId,
      profile_id: profile_id,
      is_hidden: is_hidden ? false : true
    };
    let params = new URLSearchParams(param).toString();
    editPost(params)
      .then(function (response) {
        fetchListData();
      })
      .catch(function (error) {
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
        console.error("edit post error:", error)
        return true
      })
  };

  const handleDeleteAction = async () => {
    handleClose();
    let param = {
      id: postId,
      profile_id: profile_id
    };
    let params = new URLSearchParams(param).toString();
    deletePost(params)
      .then(function (response) {
        fetchListData();
      })
      .catch(function (error) {
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
        console.error("delete post error:", error)
        return true
      })
  };

  let actions = (
    <div>
      <MenuItem onClick={handleEditAction}>
        <Typography variant="blackText">Edit</Typography>
      </MenuItem>
      <MenuItem onClick={handleHideAction}>
        <Typography variant="blackText">{is_hidden ? 'UnHide' : 'Hide'}</Typography>
      </MenuItem>
      <MenuItem onClick={handleDeleteAction}>
        <Typography variant="blackText">Delete</Typography>
      </MenuItem>
    </div>
  );

  return (
    <div>
      <Button
        sx={{
          textTransform: "unset",
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Typography variant="blackText" sx={{ width: "60px" }}>
          Action
        </Typography>
        {open ? (
          <Icon
            sx={{ marginLeft: "14px", display: "flex", alignItems: "center" }}
          >
            {" "}
            <ChevronUp color="#3F51B5" />{" "}
          </Icon>
        ) : (
          <Icon
            sx={{ marginLeft: "14px", display: "flex", alignItems: "center" }}
          >
            {" "}
            <ChevronDown color="black" />{" "}
          </Icon>
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        sx={{
          "& .MuiMenu-root": {},
          "& .MuiMenu-list": {
            padding: "6px 0px 6px 4px",
          },
        }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {actions}
      </Menu>
      {
        alert.error ?
          <CustomizedSnackbars
            open={alert.error}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="error"
          /> :
          alert.success && <CustomizedSnackbars
            open={alert.success}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="success"
          />
      }
    </div>
  );
}
