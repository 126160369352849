import { ListItem, ListItemButton} from "@mui/material";
import {styled} from "@mui/material/styles";

export const ListItemStyled = styled(ListItem)({
    padding: "2px 8px",
    ":hover" :{
        background: "#F5F5F5",
    },
    
});

export const ListStyledButton = styled(ListItemButton)({
        padding: "4px 8px 4px 4px",
        ":hover" : {
            background : "none"
        }
})