import axiosAdminInstance from '../config/api/axiosAdmin';

const createMuxAsset = (data) => {
    return axiosAdminInstance.post(`/v1/video/create-asset`, data);
};

const getMuxAssetId = (data) => {
    return axiosAdminInstance.get(`/v1/video/get-asset/${data}`);
};

const listPosts = (searchKey) => {
    let url = `/v1/users/get-profile-list?limit=10&offset=0&status=All${searchKey ? `&search=${searchKey}` : ""}`
    return axiosAdminInstance.get(url);
};

const getHashTags = (searchKey) => {
    let url = `/v1/posts/get-hashtags?limit=10&offset=0${searchKey ? `&search=${searchKey}` : ""}`
    return axiosAdminInstance.get(url);
};

const createPost = (data) => {
    return axiosAdminInstance.post(`/v1/posts/create-post`, data);
};

const editPost = (data) => {
    return axiosAdminInstance.put(`/v1/posts/edit-post`, data);
};

const deletePost = (data) =>{
    return axiosAdminInstance.delete(`/v1/posts/delete-post?${data}`);
}

const getAllPosts = (data) => {
    return axiosAdminInstance.get(`/v1/posts/get-post-list?`+ data);
};

const getAllCategories = (data) => {
    return axiosAdminInstance.get(`/v1/posts/get-category-list?`+ data);
};

const getPostDetail = (data) =>{
    return axiosAdminInstance.get(`/v1/posts/get-post-detail?`+ data);
}

export {createMuxAsset, listPosts, getHashTags, createPost, editPost,getAllPosts,getAllCategories, getPostDetail, deletePost,getMuxAssetId};
