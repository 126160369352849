import {
  Button,
  Card,
  Grid,
  InputBase,
  SvgIcon,
  Box,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { Search as SearchIcon, Plus } from "react-feather";
import AvatarDefalutImg from "../../../../assets/images/AvatarDefalutImg.png";
import Label from "../../../../components/Label";
import PostListActions from "./PostListActions";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback } from "react";

import { getAllPosts } from "../../../../services/postsService";
import MuxVideo from "@mux/mux-video-react";
import SortIcon from "../../../../components/UI/SortIcon";
import { formateDate } from "../../../../util/dateformat";
import LoadingAnimation from "../../../../components/LoadingAnimation/LoadingAnimation";

const labelColors = {
  yes: "success",
  hidden: "warning",
  draft: "drafted",
};

const compareString = (v1,v2) => v1?.userName.localeCompare(v2?.userName); 


const PostList = () => {
  const searchForm = useRef("");
  const [clearButtonVisible, setClearButtonVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [rowCountState, setRowCountState] = useState(0);

  const columns = [
    { field: "id", headerName: "Id", width: 89, hide: true },
    { field: "profile_id", headerName: "ProfileId", width: 89, hide: true },
    {
      field: "postId",
      headerName: "Post",
      headerClassName: "",
      width: 113,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              paddingY: 2,
              display: "flex",
              alignItems: "center",
              height: "56px",
              width: "52px",
              justifyContent: "center",
              borderRadius: "4px",
            }}
            component="div"
          >
            <MuxVideo
              style={{ height: "56px", width: "100%" }}
              playbackId={params.value}
            />
          </Box>
        );
      },
    },
    {
      field: "postAuthor",
      headerName: "Post Author",
      headerClassName: "",
      width: 300,
      sortComparator: compareString,
      renderCell: (params) => {
        return (
          <Box
            sx={{ paddingY: 2, display: "flex", alignItems: "center" }}
            component="div"
          >
            <Avatar
              src={
                params?.value?.photo_url
                  ? params?.value?.photo_url
                  : null
              }
              sx={{}}
            />
            <Typography variant="purpleText" sx={{ marginLeft: 1 }}>
              {params?.value?.userName}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "products",
      headerName: "Products",
      width: 290,
      renderCell: (params) => {
        return (
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Typography variant="blackText">{`Product Name (${params.value})`}</Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <IconButton aria-label="add">
                <Plus height="9px" width="12px" color="#263238" />
              </IconButton>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "likes",
      headerName: "Likes",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 140,
    },
    {
      field: "shares",
      headerName: "Shares",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 140,
    },
    {
      field: "addToCart",
      headerName: "Add to Cart",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 140,
    },
    {
      field: "earnings",
      headerName: "Earnings",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 150,
    },
    {
      field: "postDate",
      headerName: "Post Date",
      width: 150,
      renderCell: (params) => {
        const { newForamteDate } = formateDate(params.value);
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="blackText" component="span">
              {newForamteDate}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "posted",
      headerName: "Posted",
      width: 105,
      renderCell: (params) => {
        return (
          <Label
            color={
              params.value === "Yes"
                ? labelColors["yes"]
                : params.value === "Draft"
                  ? labelColors["draft"]
                  : labelColors["hidden"]
            }
          >
            {params.value}
          </Label>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      type: "actions",
      renderCell: (params) => {
        return (
          <>
            <PostListActions postId={params?.id} profile_id={params?.row?.profile_id} row={params.row} is_hidden={params.row.is_hidden} fetchListData={fetchPostData} />
          </>
        );
      },
    },
  ];

  const handleSearch = () => {
    const form = searchForm.current;
    if (form.value) {
      setSearchValue(form.value);
      setClearButtonVisible(true);
    }
  };

  const handleSearchClear = () => {
    setSearchValue("");
    searchForm.current.value = "";
    setClearButtonVisible(false);
  };

  const fetchListData = useCallback(async () => {
    let param = {
      limit: pageSize,
      offset: page * pageSize,
    };
    if(!!searchValue){
      param["search"] = searchValue;
    }
    let searchParams = new URLSearchParams(param).toString();
    let postlist = [];
    try {
      const data = await getAllPosts(searchParams);
      if (data) {
        setRowCountState(data?.count);
        postlist = data?.posts?.map((post) => {
          if (!post.is_deleted) {
            return {
              id: post.id,
              profile_id: post?.profile_id,
              postId: post.mux_playback_id,
              postAuthor: {
                photo_url: post.Profile?.photo_url,
                userName: post.Profile?.user_name,
              },
              products: post.product_list?.length,
              likes: post?.like_total,
              shares: "NA",
              addToCart: "NA",
              earnings: "NA",
              postDate: post?.date_created,
              posted: post.is_hidden ? "Hidden" : post.status === "published" ? "Yes" : post.status==="draft" && "Draft",
              action: "Action",

              is_hidden:post.is_hidden
            }
          }
          return null;
        });
        postlist = postlist.filter(function (element) {
          return element !== undefined;
        });
        setPosts(postlist);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [page, pageSize, searchValue]);

  const fetchPostData = useCallback(() => {
    setLoading(true);
    fetchListData();
  }, [fetchListData]);

  useEffect(() => {
    setLoading(true);
    fetchPostData();
  }, [page, pageSize, fetchPostData, searchValue]);

  return (
    <Card
      sx={{
        background: "#FFFFFF",
        boxShadow:
          "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
        borderRadius: "4px",
      }}
    >
      <div style={{ height: "10vh", width: "100%" }}>
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid container spacing={2}>
            <Grid item>
              <InputBase
                inputRef={searchForm}
                sx={{
                  width: "420px",
                  height: "36px",
                  background: "#FFFFFF",
                  border: "1px solid #E0E0E0",
                  borderRadius: "4px",
                  textAlign: "right",
                  display: "flex",
                  padding: 2,
                }}
                startAdornment={
                  <SvgIcon fontSize="small" color="action" sx={{}}>
                    <SearchIcon />
                  </SvgIcon>
                }
                placeholder="Search Posts"
                variant="outlined"
                disabled={clearButtonVisible}
              />
            </Grid>

            <Grid item>
              {clearButtonVisible === true ? (
                <Button
                  sx={{
                    height: "36px",
                    background: "#F5F5F5",
                    borderRadius: "4px",
                    color: "#263238",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "24px",
                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                    textTransform: "uppercase",
                  }}
                  onClick={handleSearchClear}
                >
                  Clear Filter
                </Button>
              ) : (
                <Button
                  sx={{
                    background: "#F5F5F5",
                    borderRadius: "4px",
                    color: "#263238",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "24px",
                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                    textTransform: "uppercase",
                    padding: "6px 16px",
                  }}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              )}
            </Grid>
          </Grid>

          <Box flexGrow={1} />
        </Box>
      </div>
      {loading ?
        <Box sx={{ width: "100%", height: "50vh" }}><LoadingAnimation /></Box> :
        <Box sx={{ width: '100%' }}>
          <DataGrid
            autoHeight
            rows={posts}
            columns={columns}
            checkboxSelection
            disableColumnMenu={true}
            sx={{
              border: 0,
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                display: "flex",
                justifyContent: "space-between",
              },
              "& .MuiDataGrid-iconButtonContainer": {
                display: "flex",
                width: "auto",
                visibility: "visible",
              },
            }}
            components={{
              ColumnSortedAscendingIcon: SortIcon,
              ColumnSortedDescendingIcon: SortIcon,
              ColumnUnsortedIcon: SortIcon,
            }}
            rowCount={rowCountState}
            loading={loading}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
        </Box>
      }
    </Card>
  );
};

export default PostList;
