import {useEffect, useRef} from "react";
import {TextField} from "@mui/material";

const AddressAutoComplete = ({
    id,
    name,
    label,
    value,
    onChange,
    fieldNames,
    setValue,
    register,
    alert,
    setAlert,
    isRequired = false
}) => {
    const autoCompleteRef = useRef();
    const inputRef = useRef();

    const fillInAddress = () => {
        let postcode = "";
        let address1 = "";
        let city = "";
        let country = "";
        let state = "";
        const place = autoCompleteRef.current.getPlace();
        for (const component of place.address_components) {
            if(place?.address_components)
            {
                const componentType = component.types[0];
                // eslint-disable-next-line default-case
                switch (componentType) {
    
                    case "street_number":
                        address1 = `${component.long_name} ${address1}`;
                        break;
    
                    case "route":
                        address1 += component.short_name;
                        break;
    
                    case "postal_code":
                        postcode = `${component.long_name}${postcode}`;
                        break;
    
                    case "postal_code_prefix":
                        postcode = postcode ? `${postcode}-${component.long_name}` : `${component.long_name}`;
                        break;
    
                    case "locality":
                        city = component.short_name;
                        break;
    
                    case "administrative_area_level_2":
                        city = component.short_name;
                        break;
    
                    case "administrative_area_level_1":
                        state = component.long_name;
                        break;
    
                    case "country":
                        country = component.long_name;
                        break;
                }
            }
        }
        if (fieldNames?.route) {
            setValue(fieldNames['route'], address1)
        }
        if (fieldNames?.postal_code) {
            setValue(fieldNames['postal_code'], postcode)
        }
        if (fieldNames?.country) {
            setValue(fieldNames['country'], country)
        }
        if (fieldNames?.locality) {
            setValue(fieldNames['locality'], city)
        }
        if (fieldNames?.administrative_area_level_1) {
            setValue(fieldNames['administrative_area_level_1'], state)
        }
        if (fieldNames?.geometry) {
            setValue(fieldNames['geometry'],
                {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()})
        }
        setAlert({...alert, error: false})
    }

    useEffect(() => {
        const fillInAddress = () => {
            let postcode = "";
            let address1 = "";
            let city = "";
            let country = "";
            let state = "";
            const place = autoCompleteRef.current.getPlace();
            for (const component of place.address_components) {
                const componentType = component.types[0];
                // eslint-disable-next-line default-case
                switch (componentType) {
                    case "street_number":
                        address1 = `${component.long_name} ${address1}`;
                        break;
                    case "route":
                        address1 += component.short_name;
                        break;
                    case "neighborhood":
                        address1 += " , "+component.short_name;
                        break;
                    case "postal_code":
                        postcode = `${component.long_name}${postcode}`;
                        break;
                    case "postal_code_prefix":
                        postcode = postcode ? `${postcode}-${component.long_name}` : `${component.long_name}`;
                        break;
                    case "locality":
                        city = component.short_name;
                        break;
                    case "sublocality_level_1":
                        
                        city = city === "" ? component.short_name : city;
                        break;
                    case "administrative_area_level_2":
                        city = city === "" ? component.short_name : city;
                        break;
                    case "administrative_area_level_1":
                        state = component.long_name;
                        break;
                    case "country":
                        country = component.long_name;
                        break;
                }
            }
            if (fieldNames.route) {
                setValue(fieldNames['route'], address1)
            }
            if (fieldNames.postal_code) {
                setValue(fieldNames['postal_code'], postcode)
            }
            if (fieldNames.country) {
                setValue(fieldNames['country'], country)
            }
            if (fieldNames.locality) {
                setValue(fieldNames['locality'], city)
            }
            if (fieldNames.administrative_area_level_1) {
                setValue(fieldNames['administrative_area_level_1'], state)
            }
            if (fieldNames.geometry) {
                setValue(fieldNames['geometry'],
                    {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()})
            }
            setAlert({...alert, error: false})
        }

        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            {
                fields: ["address_components", "geometry"],
                types: ["address"]
            }
        );
        autoCompleteRef.current.addListener("place_changed", fillInAddress);
    }, [alert, fieldNames, setAlert, setValue]);

    return (
        <TextField
            inputRef={inputRef}
            id={id}
            name={name}
            label={label}
            value={value}
            onChange={onChange}
            fullWidth
            required={isRequired}
            {...register(fieldNames['route'])}
        />
    );
};
export default AddressAutoComplete;
