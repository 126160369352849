import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 100,
    paddingTop: 100,
    overflow: "visible",
  },
  card: {
    "& .MuiCard-root ": {
      overflow: "visible",
    },
    display: "flex",
    position: "relative",
    maxWidth: "480px",
    borderRadius: "4px",
    margin: "auto",
    alignSelf: "center",
  },
  content: {
    paddingLeft: "32px",
    paddingTop: "40px",
    paddingRight: "25px",
  },

  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  buttonLogin: {
    background: "#7986CB",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
    borderRadius: "4px",
  },
  textFieldEmail: {
    width: "423px",
    height: "56px",
    boxSizing: "border-box",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    color: "#546E7A",
    textAlign: "right",
    padding: 16,
  },
  textFieldPsw: {
    width: "423px",
    height: "56px",
    boxSizing: "border-box",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    color: "#546E7A",
    textAlign: "right",
    padding: 16,
  },
  iconAvatar: {
    top: "-32px",
    left: "24px",
    color: "#fff",
    width: "64px",
    height: "64px",
    padding: "4px",
    position: "absolute",
    borderRadius: "4px",
    backgroundColor: "#4caf50",
  },
}));

export const SigInButton = styled(Button)({
  width: "99px",
  height: "42px",
  backgroundColor: "#7986CB",
  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
  borderRadius: 4,
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: 15,
  lineHeight: "26px",

  letterSpacing: "0.3px",
  textTransform: "uppercase",
  padding: "8px 22px",

  color: "#FFFFFF",

  "&:hover": {
    backgroundColor: "#7986CB",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#7986CB",
  },
});

export const ResetPassButton = styled(Button)({
  backgroundColor: "#5850EC",
  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
  borderRadius: 4,
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: 15,
  lineHeight: "26px",

  letterSpacing: "0.3px",
  textTransform: "uppercase",
  padding: "8px 22px",

  color: "#FFFFFF",

  "&:hover": {
    backgroundColor: "#7986CB",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#7986CB",
  },
});

export const BacktoLogInButton = styled(Button)({
  backgroundColor: "#FFFFFF",

  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
  borderRadius: 4,
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: 15,
  lineHeight: "26px",

  letterSpacing: "0.3px",
  textTransform: "uppercase",
  padding: "8px 22px",
  color: "#7986CB",
});

export const EmailSuccessMessage = styled(Typography)({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 20,
  lineHeight: "28px",
  textAlign: "center",
  letterSpacing: "-0.06px",
});
