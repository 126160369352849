import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Shuttle from "../../assets/images/icons/shuttle.svg";
import IconLogOut from "../../assets/images/icons/log-out.svg";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../services/user/authUserService";
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function TopBar() {
  const { logout } = useContext(AuthContext);
  const onLogout = async () => {
    try {
      logOut().then((res) => {
        logout();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { first_name, last_name, photo_url } = JSON.parse(
    localStorage.getItem("userInfo")
  );
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSetting = (event) => {
    const { value } = event.currentTarget.dataset;
    if (value === "Logout") {
      onLogout();
    }
  };

  return (
    <div style={{ height: "10vh" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Icon
            sx={{
              height: 36,
              width: 36,
              display: { xs: "none", md: "flex" },
              mr: 1,
            }}
          >
            <img src={Shuttle} alt="Shuttle" />
          </Icon>

          <Typography
            variant="navBarFont"
            noWrap
            component="a"
            href="/"
            sx={{
              display: { xs: "none", md: "flex" },
              textDecoration: "none",
            }}
          >
            Rocket Admin
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button sx={{ color: "#fff" }} onClick={onLogout}>
              <img src={IconLogOut} alt="Shuttle" />
            </Button>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src={photo_url} />
                <Typography
                  variant="navBarFont"
                  noWrap
                  component="a"
                  sx={{
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    flexGrow: 1,
                    textDecoration: "none",
                  }}
                >
                  {`${first_name} ${last_name}`}
                </Typography>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  data-value={setting}
                  onClick={handleSetting}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default TopBar;
