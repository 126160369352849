import axiosAdminInstance from "../../config/api/axiosAdmin";

const getAllComments = (data) => {
    return axiosAdminInstance.get(`/v1/flagged/get-report-list?`+ data);
};

const updateReport = (param,data) => {
    return axiosAdminInstance.put(`/v1/flagged/update-report?${param}`, data);
};
export {getAllComments, updateReport};
