import { Cancel } from '@mui/icons-material';
import CropIcon from '@mui/icons-material/Crop';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Slider,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { ArrowRight } from 'react-feather';
import getCroppedImg from '../../services/cropImage';

const CropEasy = ({ profileImage, setOpenCrop, setProfileImage,cropShape, coverImage, setCoverImage, cropCoverPhoto}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [photoUrl,setPhotoUrl] = useState(!cropCoverPhoto ? profileImage[0]?.preview || profileImage[0]?.presignedUrl : coverImage[0]?.preview || coverImage[0]?.presignedUrl);

    const cropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const cropImage = async () => {
        try {
            const { file, url } = await getCroppedImg(
                !cropCoverPhoto ? profileImage[0].preview : coverImage[0]?.preview,
                croppedAreaPixels,
                rotation
            );
            var newfile = new File([file], "cropped.jpeg");
            newfile['preview'] = url;
            !cropCoverPhoto ?
            setProfileImage([newfile]) :
            setCoverImage([newfile]);
            setOpenCrop(false);
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <>
            <DialogContent
                dividers
                sx={{
                    background: '#333',
                    position: 'relative',
                    height: 400,
                    width: 'auto',
                    minWidth: { sm: 500 },
                }}
            >
                <Cropper
                    image={photoUrl}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    aspect={1}
                    onZoomChange={setZoom}
                    onRotationChange={setRotation}
                    onCropChange={setCrop}
                    onCropComplete={cropComplete}
                    cropShape={cropShape}
                />
            </DialogContent>
            <DialogActions sx={{ flexDirection: 'column', mx: 3, my: 2 }}>
                <Box sx={{ width: '100%', mb: 1 }}>
                    <Box>
                        <Typography>Zoom: {zoomPercent(zoom)}</Typography>
                        <Slider
                            valueLabelDisplay="auto"
                            valueLabelFormat={zoomPercent}
                            min={1}
                            max={3}
                            step={0.1}
                            value={zoom}
                            onChange={(e, zoom) => setZoom(zoom)}
                        />
                    </Box>
                    <Box>
                        <Typography>Rotation: {rotation + '°'}</Typography>
                        <Slider
                            valueLabelDisplay="auto"
                            min={0}
                            max={360}
                            value={rotation}
                            onChange={(e, rotation) => setRotation(rotation)}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        flexWrap: 'wrap',
                    }}
                >
                    <Button
                        variant="outlined"
                        startIcon={<ArrowRight />}
                        onClick={() => setOpenCrop(false)}
                    >
                        Skip
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<CropIcon />}
                        onClick={cropImage}
                    >
                        Crop
                    </Button>
                </Box>
            </DialogActions>
        </>
    );
};

export default CropEasy;

const zoomPercent = (value) => {
    return `${Math.round(value * 100)}%`;
};