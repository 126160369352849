import axiosAdminInstance from '../../config/api/axiosAdmin';

const createCategory = (data) => {
    return axiosAdminInstance.post(`/v1/posts/create-category`, data);
};

const editCategory = (data) => {
    return axiosAdminInstance.put(`/v1/posts/edit-category`, data);
};

const getCategoryDetail = (id) => {
    return axiosAdminInstance.get(`/v1/posts/get-category-detail?id=${id}`);
};

export { createCategory, editCategory, getCategoryDetail};


