import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { User as UserIcon } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import { editProfile } from "../../../../services/user/createuserProfileService";
import CustomizedSnackbars from "../../../../components/Alert/CustomizedSnackbars";
import "./Profile.css";
import AddressAutoComplete from "../../../../components/AddressAutocomplete";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getProfileDetail } from "../../../../services/profile/profileService";
import { checkDuplicateSpecialCharactrer } from "../../../../util/textConverter";
import LoadingAnimation from "../../../../components/LoadingAnimation/LoadingAnimation";
import { getErrorMessageFromErrorResponse } from "../../../../services/helper";

function EditProfilePage() {
  const [dob, setDob] = useState();
  const [addShopProfile, setAddShopProfile] = useState(false);
  const [alert, setAlert] = useState({});
  const [showShopifyAccessTokenPassword, setShowShopifyAccessTokenPassword] =
    useState(false);
  const [showShopifySecretKeyPassword, setShowShopifySecretKeyPassword] =
    useState(false);
  const [profileDetail, setProfileDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchemaProfile = Yup.object().shape({
    profileType: Yup.string().required("Profile Type is required"),
    rocketHandle: Yup.string().required("Rocket handle is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number is too short")
      .max(12, "Phone number is too long"),
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemaProfile),
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      getProfileDetail(id)
        .then(function (response) {
          setProfileDetail(response);
          if (
            "profile_info" in response &&
            Object.keys(response["profile_info"]).length > 0
          ) {
            const { profile_info } = response;
            setValue("profileType", profile_info?.Shop ? "seller" : "user");
            setValue("rocketHandle", profile_info.user_name);
            setValue("displayName", profile_info.display_name);
            setValue("email", profile_info.nautical_email);
            setValue("profileType", profile_info.profile_type);
            setValue("address", profile_info.address_1);
            setValue("city", profile_info.city);
            setValue("state", profile_info.state);
            setValue("zip", profile_info.zip_code);
            setValue("country", profile_info.country);
            setValue("phoneNumber", profile_info.phone_number);
            setValue("is_verified", profile_info.is_verified);
            setValue("is_brand", profile_info.is_brand);

            if (profile_info?.Shop) {
              setAddShopProfile(true);
              setValue("shopName", profile_info.Shop?.shop_name);
              setValue("shopifyDomain", profile_info.Shop?.shopifyDomain);
              setValue("shopifySecretkey", profile_info.Shop?.shopifySecretKey);
              setValue(
                "shopifyAccessToken",
                profile_info.Shop?.shopifyAccessToken
              );
              setValue("shopifyAPIKey", profile_info.Shop?.shopifyAPIKey);
            }
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) });
          console.error("get profile error:", error);
          return true;
        });
    }
  }, [id]);

  const onSubmit = async (data) => {
    setLoading(true);
    setAlert({ ...alert, error: false });
    editProfile(data, id)
      .then(function (response) {
        const { id } = response;
        setLoading(false);
        navigate(`/Manage/Profiles/${id}`, { replace: true });
      })
      .catch(function (error) {
        setLoading(false);
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) });
      });
  };

  const handleClickShowPassword = (name) => {
    if (name === "shopifyAccessToken")
      setShowShopifyAccessTokenPassword(!showShopifyAccessTokenPassword);
    else if (name === "shopifySecretKey")
      setShowShopifySecretKeyPassword(!showShopifySecretKeyPassword);
  };

  const handleProfileTypeSelect = (e) => {
    setValue("profileType", e.target.value);
    e.target.value === "seller"
      ? setAddShopProfile(true)
      : setAddShopProfile(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRocketHandleChange = async (event) => {
    let isDuplicate = await checkDuplicateSpecialCharactrer(event.target.value);
    if (isDuplicate) {
      setError("rocketHandle", {
        type: "custom",
        message: "Special Character repeated",
      });
    } else {
      setValue("rocketHandle", event.target.value.toLowerCase());
      setError("rocketHandle", {});
    }
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      DashBoard
    </Link>,
    <Typography key="2" color="inherit">
      Manage
    </Typography>,
  ];

  return (
    <div>
      <>
        <div style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Grid container justify="space-between" spacing={3}>
            <Grid item xs={9}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
                <Typography key="2" color="text.primary">
                  Profiles
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <h2>
            <strong>{"Edit Profile"}</strong>
          </h2>
          <Paper style={{ marginTop: 5 }}>
            {loading ? (
              <Box sx={{ width: "100%", height: "50vh" }}>
                <LoadingAnimation />
              </Box>
            ) : (
              <Box px={3} py={2}>
                <FormControl>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={2}>
                      <Button
                        variant="text"
                        startIcon={
                          <Avatar sx={{ bgcolor: "#5850EC" }}>
                            <UserIcon />
                          </Avatar>
                        }
                        disabled
                        className="button-text"
                      >
                        {"Edit Profile"}
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={4.25}>
                      <TextField
                        required
                        id="profileType"
                        name="profileType"
                        label="Profile Type"
                        margin="dense"
                        fullWidth
                        select
                        value={addShopProfile ? "seller" : "user"}
                        {...register("profileType")}
                        InputLabelProps={{
                          shrink: getValues("profileType") && true,
                        }}
                        onChange={(event) => handleProfileTypeSelect(event)}
                        error={errors.profileType ? true : false}
                      >
                        <MenuItem value="user">User</MenuItem>
                        <MenuItem value="seller">Seller</MenuItem>
                      </TextField>
                      <Typography variant="inherit" color="textSecondary">
                        {errors.profileType?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={0.5}></Grid>
                    <Grid item xs={6} sm={4.25} style={{ marginTop: 8 }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          label="Date of Birth"
                          id="dob"
                          name="dob"
                          value={dob}
                          onChange={(newDob) => {
                            setDob(newDob);
                          }}
                          InputLabelProps={{
                            shrink: getValues("dob") && true,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...register("dob")}
                              fullWidth
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} sm={1}></Grid>
                    <Grid item xs={6} sm={2}></Grid>
                    <Grid item xs={6} sm={4.25}>
                      <TextField
                        id="displayName"
                        name="displayName"
                        label="Display Name"
                        fullWidth
                        margin="dense"
                        {...register("displayName")}
                        InputLabelProps={{
                          shrink: getValues("displayName") && true,
                        }}
                        error={errors.displayName ? true : false}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.profileName?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={0.5}></Grid>
                    <Grid item xs={6} sm={4.25}>
                      <AddressAutoComplete
                        id="address"
                        name="address"
                        label="Address"
                        fieldNames={{
                          route: "address",
                          postal_code: "zip",
                          locality: "city",
                          administrative_area_level_1: "state",
                          country: "country",
                        }}
                        setValue={setValue}
                        register={register}
                        alert={alert}
                        setAlert={setAlert}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.address?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={1}></Grid>
                    <Grid item xs={6} sm={2}></Grid>
                    <Grid item xs={6} sm={4.25}>
                      <TextField
                        required
                        id="rocketHandle"
                        name="rocketHandle"
                        label="Rocket handle"
                        fullWidth
                        margin="dense"
                        {...register("rocketHandle")}
                        InputLabelProps={{
                          shrink: getValues("rocketHandle") && true,
                        }}
                        onChange={(event) => handleRocketHandleChange(event)}
                        error={errors.rocketHandle?.message ? true : false}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.rocketHandle?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={0.5}></Grid>
                    <Grid item xs={6} sm={4.25}>
                      <TextField
                        id="city"
                        name="city"
                        label="City"
                        fullWidth
                        InputLabelProps={{
                          shrink: getValues("city") && true,
                        }}
                        margin="dense"
                        {...register("city")}
                      />
                    </Grid>
                    <Grid item xs={6} sm={1}></Grid>
                    <Grid item xs={6} sm={2}></Grid>
                    <Grid item xs={6} sm={4.25}>
                      <TextField
                        required
                        id="email"
                        name="email"
                        label="Email"
                        fullWidth
                        margin="dense"
                        {...register("email")}
                        InputLabelProps={{
                          shrink: getValues("email") && true,
                        }}
                        error={errors.email ? true : false}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.email?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={0.5}></Grid>
                    <Grid item xs={6} sm={4.25}>
                      <TextField
                        id="state"
                        name="state"
                        label="State"
                        fullWidth
                        InputLabelProps={{
                          shrink: getValues("state") && true,
                        }}
                        margin="dense"
                        {...register("state")}
                      />
                    </Grid>
                    <Grid item xs={6} sm={1}></Grid>
                    <Grid item xs={6} sm={2}></Grid>
                    <Grid item xs={6} sm={4.25}>
                      <TextField
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone Number"
                        fullWidth
                        margin="dense"
                        InputLabelProps={{
                          shrink: getValues("phoneNumber") && true,
                        }}
                        {...register("phoneNumber")}
                        error={errors.phoneNumber ? true : false}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.phoneNumber?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={0.5}></Grid>
                    <Grid item xs={6} sm={4.25}>
                      <TextField
                        id="zip"
                        name="zip"
                        label="Zip"
                        fullWidth
                        InputLabelProps={{
                          shrink: getValues("zip") && true,
                        }}
                        margin="dense"
                        {...register("zip")}
                      />
                    </Grid>
                    <Grid item xs={6} sm={1}></Grid>
                    <Grid item xs={6} sm={2}></Grid>
                    <Grid item xs={6} sm={4.25}>
                      <TextField
                        id="country"
                        name="country"
                        label="Country"
                        fullWidth
                        InputLabelProps={{
                          shrink: getValues("country") && true,
                        }}
                        margin="dense"
                        {...register("country")}
                      />
                    </Grid>
                    <Grid item xs={6} sm={0.5}></Grid>
                    <Grid item xs={6} sm={4.25}>
                      <FormControlLabel
                        control={
                          <Controller
                            control={control}
                            id="is_verified"
                            name="is_verified"
                            defaultValue="false"
                            inputRef={register("is_verified")}
                            render={({ field: { onChange } }) => (
                              <Checkbox
                                color="primary"
                                checked={getValues("is_verified")}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                }}
                              />
                            )}
                          />
                        }
                        label="Is Verified?"
                      />
                    </Grid>
                    <Grid item xs={6} sm={1}></Grid>
                    <Grid item xs={6} sm={2}></Grid>
                    <Grid item xs={6} sm={4.25}>
                      <FormControlLabel
                        control={
                          <Controller
                            control={control}
                            name="attachShopProfile"
                            defaultValue="false"
                            render={({ field: { onChange } }) => (
                              <Checkbox
                                color="primary"
                                checked={addShopProfile}
                                onChange={(e) => {
                                  setAddShopProfile(!addShopProfile);
                                }}
                              />
                            )}
                          />
                        }
                        label="Attach a shop profile to this account"
                      />
                    </Grid>
                    <Grid item xs={6} sm={0.5}></Grid>
                    <Grid item xs={6} sm={4.25}></Grid>

                    <Grid item xs={6} sm={1}></Grid>
                    {addShopProfile && (
                      <>
                        <Grid item xs={6} sm={2}></Grid>
                        <Grid item xs={6} sm={4.25}>
                          <TextField
                            id="shopName"
                            name="shopName"
                            label="Shop Name"
                            fullWidth
                            margin="dense"
                            InputLabelProps={{
                              shrink: getValues("shopName") && true,
                            }}
                            {...register("shopName")}
                            error={errors.shopName ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.shopName?.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={0.5}></Grid>
                        <Grid item xs={6} sm={4.25}>
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                id="is_brand"
                                name="is_brand"
                                defaultValue="false"
                                inputRef={register("is_brand")}
                                render={({ field: { onChange } }) => (
                                  <Checkbox
                                    color="primary"
                                    checked={getValues("is_brand")}
                                    onChange={(e) => {
                                      onChange(e.target.checked);
                                    }}
                                  />
                                )}
                              />
                            }
                            label="Verified brand?"
                          />
                        </Grid>
                        <Grid item xs={6} sm={1}></Grid>
                        <Grid item xs={6} sm={2}></Grid>
                        <Grid item xs={6} sm={8}>
                          <TextField
                            id="shopifyDomain"
                            name="shopifyDomain"
                            label="Shopify Domain"
                            fullWidth
                            margin="dense"
                            InputLabelProps={{
                              shrink: getValues("shopifyDomain") && true,
                            }}
                            {...register("shopifyDomain")}
                            error={errors.shopifyDomain ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.shopifyDomain?.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={2}></Grid>
                        <Grid item xs={6} sm={2}></Grid>
                        <Grid item xs={6} sm={8}>
                          <TextField
                            id="shopifyAccessToken"
                            name="shopifyAccessToken"
                            label="Shopify Access Token"
                            type={
                              showShopifyAccessTokenPassword
                                ? "text"
                                : "password"
                            }
                            fullWidth
                            margin="dense"
                            InputLabelProps={{
                              shrink: getValues("shopifyAccessToken") && true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {" "}
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword(
                                        "shopifyAccessToken"
                                      )
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showShopifyAccessTokenPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            {...register("shopifyAccessToken")}
                            error={errors.shopifyAccessToken ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.shopifyAccessToken?.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={2}></Grid>
                        <Grid item xs={6} sm={2}></Grid>
                        <Grid item xs={6} sm={8}>
                          <TextField
                            id="shopifyAPIKey"
                            name="shopifyAPIKey"
                            label="Shopify API Key"
                            fullWidth
                            margin="dense"
                            InputLabelProps={{
                              shrink: getValues("shopifyAPIKey") && true,
                            }}
                            {...register("shopifyAPIKey")}
                            error={errors.shopifyAPIKey ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.shopifyAPIKey?.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={2}></Grid>
                        <Grid item xs={6} sm={2}></Grid>
                        <Grid item xs={6} sm={8}>
                          <TextField
                            id="shopifySecretKey"
                            name="shopifySecretKey"
                            label="Shopify Secret Key"
                            type={
                              showShopifySecretKeyPassword ? "text" : "password"
                            }
                            fullWidth
                            margin="dense"
                            InputLabelProps={{
                              shrink: getValues("shopifySecretKey") && true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {" "}
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      handleClickShowPassword(
                                        "shopifySecretKey"
                                      )
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showShopifySecretKeyPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            {...register("shopifySecretKey")}
                            error={errors.shopifySecretKey ? true : false}
                          />
                          <Typography variant="inherit" color="textSecondary">
                            {errors.shopifySecretKey?.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={2}></Grid>
                      </>
                    )}
                    <Grid item xs={6} sm={6.75}></Grid>
                    <Grid item xs={6} sm={4.25} className="add-user-button">
                      <Button
                        color="primary"
                        className="cancel-btn"
                        onClick={() =>
                          navigate("/Manage/Users", { replace: true })
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                        className="next-btn"
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </FormControl>
              </Box>
            )}
          </Paper>
        </div>
        {alert.error && (
          <CustomizedSnackbars
            open={alert.error}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="error"
          />
        )}
      </>
    </div>
  );
}

export default EditProfilePage;
