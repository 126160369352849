import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Divider } from '@mui/material';
import UploadFile from '../UploadFile/UploadFile';
import { PlusCircle as PlusCircleIcon, } from 'react-feather';
import "./Card.css"


export default function OutlinedCard({ title, photoUrl, width, actions, setOpenCrop,image,handleImageSelect, handleCoverImageSelect}) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Card raised
      sx={{
        maxWidth: width,
      }}>
      <CardHeader
        action={actions}
        title={title}
      />
      <Divider />
      {photoUrl ?
        <CardMedia
          component="img"
          image={photoUrl}
          height="300px"
          alt="No Image Present"
          sx={{ padding: "1em 1em 1em 1em", objectFit: "contain" }} />
        :
        <div className="main-upload-profile-image">
        <div className="upload-profile-image">
          <UploadFile
            required
            id="Image"
            inputName="Image"
            setFile={(value) => image==="Cover" ? handleCoverImageSelect(value) : handleImageSelect(value)}
            sx={{ padding: "1em 1em 1em 1em", objectFit: "contain"}}
          >
            <PlusCircleIcon className="add-icon" />
            <h5>Add Image</h5>
            <p className="image-upload-text">
              Drag image file here or <br></br>
              click <span className="hover-browse-link">browse</span> your machine
            </p>
          </UploadFile>
        </div>
        </div>

      }
    </Card>
  );
}
