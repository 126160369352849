import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import NewUsersManagePage from "../pages/Manage/Accounts/Users/NewUsersPage";
import LoginPage from "../pages/LoginPage";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import ClippedDrawer from "../components/ClippedDrawer";
import PostPage from "../pages/Manage/Accounts/BrandPost/PostsPage";
import DetailManagePage from "../pages/Manage/DetailManagePage/DetailManagePage";
import ResetPassword from "../containers/Authentication/ResetPassword";
import Brands from "../pages/Manage/Accounts/Brands";
import Posts from "../pages/Manage/Accounts/PostsPage";
import Users from "../pages/Manage/Accounts/Users";
import Categories from "../pages/Manage/Accounts/Categories";
import NewCetegory from "../pages/Manage/Accounts/Categories/NewCetegory";
import Profiles from "../pages/Manage/Accounts/Profiles";
import ReportedComments from "../pages/Manage/ReportedComments/ReportedComments";
import EmailPage from "../pages/Notifications/EmailPage";
import ChatPage from "../pages/Notifications/ChatPage";
import Settings from "../pages/Extra/Settings";
import Admin from "../pages/Extra/Admin";
import Orders from "../pages/Manage/Accounts/Orders";
import CategoryDetails from "../pages/Manage/Accounts/Categories/CategoryDetails";
import EditPostPage from "../pages/Manage/Accounts/BrandPost/EditPostPage";
import OrderDetailPage from "../pages/Manage/Accounts/Orders/OrderDetail";
import EditProfilePage from "../pages/Manage/Accounts/Profiles/EditProfiles";
import AdminNew from "../pages/Extra/Admin/CreateAdmin/AdminNew";
import ActivateAccount from "../pages/Extra/Admin/CreateAdmin/ActivateAccount";
import EditAdmin from "../pages/Extra/Admin/EditAdmin/EditAdmin";
import UserDetailScreen from "../pages/Manage/Accounts/Users/UserDetail/UserDetailScreen";

export const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route
          path="reset-password/*"
          element={
            <PublicRoute>
              <Routes>
                <Route path="/*" element={<ResetPassword />} />
              </Routes>
            </PublicRoute>
          }
        />
        <Route
          path="login/*"
          element={
            <PublicRoute>
              <Routes>
                <Route path="/*" element={<LoginPage />} />
              </Routes>
            </PublicRoute>
          }
        />

        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Routes>
                <Route path="/" element={<ClippedDrawer />}>
                  <Route index path="/Home" element={<HomePage />} />
                  <Route
                    path="/Manage/Comments"
                    element={<ReportedComments />}
                  />
                  <Route
                    path="/Manage/ManageListings/Users"
                    element={<Users />}
                  />
                  <Route
                    path="/Manage/Users/:id"
                    element={<UserDetailScreen/>}
                  />
                  <Route path="/Manage/Profiles" element={<Profiles />} />
                  <Route
                    path="/Manage/Users/New"
                    element={<NewUsersManagePage />}
                  />
                  <Route
                    path="/Manage/Profile/EditProfile/:id"
                    element={<EditProfilePage />}
                  />
                  <Route
                    path="/Manage/Profiles/:id"
                    element={<DetailManagePage />}
                  />
                  <Route
                    path="/Manage/Users/New"
                    element={<NewUsersManagePage />}
                  />
                  <Route path="/Manage/Users" element={<DetailManagePage />} />
                  <Route path="/Manage/Brands" element={<Brands />} />
                  <Route path="/Manage/Posts/New" element={<PostPage />} />
                  <Route path="/Manage/EditPost" element={<EditPostPage />} />
                  <Route path="/Manage/Posts" element={<Posts />} />
                  <Route path="/Manage/Orders" element={<Orders />} />
                  <Route
                    path="/Manage/Orders/:id"
                    element={<OrderDetailPage />}
                  />
                  <Route path="/Manage/Categories" element={<Categories />} />
                  <Route path="/Manage/Newcategory" element={<NewCetegory />} />
                  <Route
                    path="/Manage/CategoryDetail/:id"
                    element={<CategoryDetails />}
                  />
                  <Route path="/Notifications/Mail" element={<EmailPage />} />
                  <Route path="/Notifications/Chat" element={<ChatPage />} />
                  <Route path="/Settings" element={<Settings />} />
                  <Route path="/Admin" element={<Admin />} />
                  <Route path="/Manage/Admin/New" element={<AdminNew />} />
                  <Route path="/Manage/Admin/Edit/:id" element={<EditAdmin/>} />
                  <Route
                    path="/Manage/Admin/New/Activate"
                    element={<ActivateAccount />}
                  />
                </Route>
              </Routes>
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Navigate to="/Login" />} />
      </Routes>
    </>
  );
};
