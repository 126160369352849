import React from "react";
import { Box, Breadcrumbs, Button, Container, Grid, Link, SvgIcon, Typography, } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Download as DownloadIcon, PlusCircle as PlusCircleIcon, } from "react-feather";
import ProfileList from "./Profiles/ProfileList";
import { useNavigate } from "react-router-dom";


function Profiles() {
    const navigate = useNavigate();
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">
            DashBoard
        </Link>,
        <Typography key="2" color="text.primary">
            Manage
        </Typography>,
    ];
    return (
        <>
            <div style={{ height: "10vh", width: "100%" }}>
                <Grid container spacing={3}>

                    <Grid item xs={9}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            {breadcrumbs}
                            <Typography>Profiles</Typography>
                        </Breadcrumbs>


                    </Grid>

                    <Grid item container xs={3} justifyContent="flex-end">
                        <Button sx={{ backgroundColor: "#FFFFFF", borderRadius: "4px", width: "52px", height: "36px" }}>
                            <SvgIcon fontSize="small">
                                <DownloadIcon />
                            </SvgIcon>
                        </Button>

                        <Button sx={{
                            background: "#5850EC",
                            borderRadius: "4px",
                            color: "#FFFFFF",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "24px",
                            textTransform: "uppercase"
                        }} startIcon={<PlusCircleIcon />} onClick={() => navigate("/Manage/Users/New", { replace: true, })}>
                            New Profile
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <Grid container>
                <Grid item style={{ width: "100%" }}>

                    <Box mt={3}>
                        <ProfileList></ProfileList>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default Profiles;
