import React from 'react';
import Dropzone from 'react-dropzone'
import "./UploadFile.css"

function UploadFile({
    children,
    noBrowseOpen = false,
    setFile,
    inputRootclassName,
    onDragnDrop = true,
    inputName,
    register,
    className
}) {

    const handleDrop = (acceptedFiles) => {
        return (setFile(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
        )
    };

    

    return (
        <Dropzone
            onDrop={(acceptedFiles) => {
                (onDragnDrop || !noBrowseOpen) && handleDrop(acceptedFiles)
            }}
            multiple={false}
            noClick={noBrowseOpen}
        >
            {({getRootProps, getInputProps}) => (
                <div {...getRootProps({className: inputRootclassName})} className={className ? className: inputRootclassName }>
                    {register ?
                        <input name={inputName} {...getInputProps()} {...register(inputName)} />
                        :
                        <input name={inputName} {...getInputProps()} />
                    }
                    {children}
                </div>
            )}
        </Dropzone>
    );
}

export default UploadFile;
