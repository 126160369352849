import React from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import { ArrowRight as ArrowRightIcon } from "react-feather";
import "./ShopDetail.css";
import { useContext, useEffect, useState } from "react";
import { DetailContext } from "./DetailManagePage";
import { getProfileDetail } from "../../../services/profile/profileService";
import OutlinedCard from "../../../components/Card/Card";
import ProfileActions from "./ProfileActions";

function ShopDetail() {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offSet, setOffSet] = useState(0);
  const [shopProfileInfo, setShopProfileInfo] = useState(0);

  const style = {
    bgcolor: "background.paper",
  };

  const detailContext = useContext(DetailContext);
  const { profileImage, coverImage, setOpenCrop, setCropCoverPhoto, handleImageSelect, handleImageDelete, handleCoverImageSelect } = detailContext;

  useEffect(() => {
    if (detailContext) {
      const { setProgress, profileId } = detailContext;
      getProfileDetail(profileId, rowsPerPage, offSet)
        .then(function (response) {
          setShopProfileInfo(response);
          setProgress(false);
        })
        .catch(function (error) {
          return true;
        })

    }
  }, [])

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            height: 116,
            width: 1,
          },
        }}
      >
        <Paper className="detail-banner">
          <div className="detail-banner-inner">
            <div className="banner-text">
              <h2>{shopProfileInfo?.post_info?.posts.length}</h2>
              <p>POSTS</p>
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div className="banner-text">
              <h2>{shopProfileInfo?.view_total}</h2>
              <p>VIEWS</p>
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div className="banner-text">
              <h2>{shopProfileInfo?.profile_info?.follower_count}</h2>
              <p>FOLLOWERS</p>
            </div>
          </div>
        </Paper>
      </Box>
      <div className="border-text">
        <p>Sales</p>
      </div>
      <div className="sales-content">
        <List component="div" sx={style} className="sales-box">
          <ListItem button>
            <ListItemText primary="7 Day Earnings" />
            <ArrowRightIcon />
          </ListItem>
          <Divider />
          <ListItem button divider>
            <ListItemText primary="Total Orders" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Customer/s" />
          </ListItem>
          <Divider light />
          <ListItem button>
            <ListItemText primary="Total Earnings" />
          </ListItem>
        </List>

        <List sx={style} component="div" className="sales-box">
          <ListItem button>
            <ListItemText primary="30 Day Earnings" />
            <ArrowRightIcon />
          </ListItem>
          <Divider />
          <ListItem button divider>
            <ListItemText primary="Total Orders" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Customer/s" />
          </ListItem>
          <Divider light />
          <ListItem button>
            <ListItemText primary="Total Earnings" />
          </ListItem>
        </List>

        <List sx={style} component="div" className="sales-box">
          <ListItem button>
            <ListItemText primary="Last Order" />
            <ArrowRightIcon />
          </ListItem>
          <Divider />
          <ListItem button divider>
            <ListItemText primary="Total Orders" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Customer/s" />
          </ListItem>
          <Divider light />
          <ListItem button>
            <ListItemText primary="Total Earnings" />
          </ListItem>
        </List>
      </div>
      <div className="border-text">
        <p>Commerce</p>
      </div>
      <div className="commerce-content">
        <List component="div" sx={style} className="sales-box">
          <ListItem button>
            <ListItemText primary="Active Products" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="150" />
          </ListItem>
        </List>

        <List sx={style} component="div" className="sales-box">
          <ListItem button>
            <ListItemText primary="Q&A" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="20/15" />
          </ListItem>
        </List>

        <List sx={style} component="div" className="sales-box">
          <ListItem button>
            <ListItemText primary="Active Promotion" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="Libero sem" />
          </ListItem>
        </List>

        <List sx={style} component="div" className="sales-box">
          <ListItem button>
            <ListItemText primary="Free Shipping Threshold" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="$1000" />
          </ListItem>
        </List>
      </div>
      <div className="border-text">
        <p>Payouts</p>
      </div>
      <div className="payouts-content">
        <List component="div" sx={style} className="sales-box">
          <ListItem button>
            <ListItemText primary="7 Day Paid" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="$3,570" />
          </ListItem>
        </List>

        <List sx={style} component="div" className="sales-box">
          <ListItem button>
            <ListItemText primary="30 Day Paid" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="$15,000" />
          </ListItem>
        </List>

        <List sx={style} component="div" className="sales-box">
          <ListItem button>
            <ListItemText primary="Total Paid" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="$1,15,000" />
          </ListItem>
        </List>
      </div>
      <div></div>
      <div className="border-text">
        <p>Clubs</p>
      </div>
      <div className="clubs-content">
        <List component="div" sx={style} className="sales-box">
          <ListItem button>
            <ListItemText primary="Created" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="5" />
          </ListItem>
        </List>

        <List sx={style} component="div" className="sales-box">
          <ListItem button>
            <ListItemText primary="Total Products" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="2000" />
          </ListItem>
        </List>

        <List sx={style} component="div" className="sales-box">
          <ListItem button>
            <ListItemText primary="Total Shares" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="2000" />
          </ListItem>
        </List>

        <List sx={style} component="div" className="sales-box">
          <ListItem button>
            <ListItemText primary="7 Day Sales" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="$3,750" />
          </ListItem>
        </List>
        <List sx={style} component="div" className="sales-box">
          <ListItem button>
            <ListItemText primary="30 Day Sales" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="$15,000" />
          </ListItem>
        </List>
      </div>
      <div></div>
      <div className="border-text-imagery">
        <p>Profile Imagery</p>
      </div>
      <div className="imagery-content" style={{ marginBottom: "20px" }}>
        <OutlinedCard {...{ title: "Profile Picture", photoUrl: profileImage && (profileImage[0]?.preview || profileImage[0]?.presignedUrl), width: "100%", image: "Profile", handleImageSelect, handleCoverImageSelect, actions: <ProfileActions {...{ setOpenCrop, image: "Profile", setCropCoverPhoto, handleImageSelect, handleImageDelete, handleCoverImageSelect }} /> }} />
        <OutlinedCard {...{ title: "Cover Picture", photoUrl: coverImage && (coverImage[0]?.preview || coverImage[0]?.presignedUrl), width: "100%", image: "Cover", handleCoverImageSelect, handleImageSelect, actions: <ProfileActions {...{ setOpenCrop, image: "Cover", setCropCoverPhoto, handleImageSelect, handleImageDelete, handleCoverImageSelect }} /> }} />
      </div>
    </>
  );
}

export default ShopDetail;
