import React, {createContext, useState} from "react";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../services/firebase/firebase";

export const AuthContext = createContext({
    user: null,
    logged: false,
    logout: () => {
    },
    login: () => {
    },
    setUser: () => {
    },
});

const AuthContextProvider = ({children}) => {

    const [userData, setUserData] = useState(() => {
        const user = localStorage.getItem("user");
        if (!!user) {
            return JSON.parse(user);
        }
        return null;
    });

    const logoutHandler = () => {
        // remove adminRefreshToken, adminToken, user and set state to null.
        localStorage.removeItem("user");
        localStorage.removeItem("adminToken");
        localStorage.removeItem("adminRefreshToken");
        localStorage.removeItem("userInfo");
        
        setUserData(null);
    };

    const loginHandler = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    const setUser = (user, adminRefreshToken) => {
        if (user && adminRefreshToken) {
            // set refreshToken, and user;
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("adminRefreshToken", adminRefreshToken);
            setUserData(user);
        }
    };

    const value = {
        user: userData,
        logged: !!userData,
        logout: logoutHandler,
        login: loginHandler,
        setUser: setUser,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
