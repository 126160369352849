import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";
import axiosAdminInstance from "../../config/api/axiosAdmin";

const createProfile = (data) => {
  let payloadData = {
    user_name: data.rocketHandle,
    display_name: data.displayName,
    nautical_email: data.email,
    profile_type: data.profileType,
    address_1: data.address,
    city: data.city,
    state: data.state,
    zip_code: data.zip,
    country: data.country,
    phone_number: data.phoneNumber,
    is_brand: data.verifiedBrand,
    shop_name: data.shopName,
    has_shop: data.shopName ? true : false,
    shopify_domain: data.shopifyDomain,
    shopify_access_token: data.shopifyAccessToken,
    shopify_api_key: data.shopifyAPIKey,
    shopify_secret_key: data.shopifySecretKey,
  };
  return axiosAdminInstance.post(`/v1/users/create-profile`, payloadData);
};

const editProfile = (data, id) => {
  let payloadData = {
    profile_id: id,
    user_name: data.rocketHandle,
    display_name: data.displayName,
    nautical_email: data.email,
    profile_type: data.profileType,
    address_1: data.address,
    city: data.city,
    state: data.state,
    zip_code: data.zip,
    country: data.country,
    phone_number: data.phoneNumber,
    is_brand: data.is_brand,
    is_verified: data.is_verified,
    shop_name: data.shopName,
    has_shop: data.shopName ? true : false,
    shopify_domain: data.shopifyDomain,
    shopify_access_token: data.shopifyAccessToken,
    shopify_api_key: data.shopifyAPIKey,
    shopify_secret_key: data.shopifySecretKey,
  };
  return axiosAdminInstance.put(`/v1/users/edit-profile`, payloadData);
};
export { createProfile, editProfile };
