import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { visuallyHidden } from '@mui/utils';
import { productList } from '../../../../services/brands/productService';
import "./ProductTable.css"
import { useContext } from 'react';
import { DetailContext } from '../../DetailManagePage/DetailManagePage';
import NoProductImage from '../../../../assets/images/no-product-image.png';
import CustomizedSnackbars from '../../../../components/Alert/CustomizedSnackbars';
import { getErrorMessageFromErrorResponse } from '../../../../services/helper';

const mainHeadCells = [
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, tableHeader } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                {tableHeader.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function ProductTable({ searchValue, handleSelectProduct, profileId, shopId, handleProductDragStart }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [page, setPage] = useState(0);
    const [dense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [pageInfo, setPageInfo] = useState({});
    const [productData, setProductData] = useState([]);
    const [totalproductData, setTotalProductData] = useState(0);
    const [subHeadCells, setSubHeadCells] = useState([]);
    const [expanded, setExpanded] = React.useState(false);
    const detailContext = useContext(DetailContext);
    const [alert, setAlert] = useState({});

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const createSubHeadCells = (variant) => {
        let variantKeys = Object.keys(variant);
        return (variantKeys.map((key, index) => ({ id: key, label: key })))
    }

    const getProductList = (data) => {
        let shop = shopId ? shopId : 0
        let profile = profileId ? profileId : 0
        productList(data, profile, shop)
            .then(function (response) {
                let productResult = response.data.products.edges
                setPageInfo(response.data.products.pageInfo)
                setProductData(response.data.pinned_products.concat(response.data.products.edges))
                setTotalProductData(response.data.products.totalCount)
                if (productResult.length > 0) {
                    setSubHeadCells(createSubHeadCells(productResult[0].node.variants[0]))
                }
                if (detailContext) {
                    const { setProgress } = detailContext
                    setProgress(false);
                }
            }).catch(function (error) {
                setAlert({ error: true, message: getErrorMessageFromErrorResponse(error) || error.key })
                return true;
            })
    }

    useEffect(() => {
        let data = {
            "variables": {
                "filter": {
                    "search": null,
                    "sellers": null
                },
                "sortBy": {
                    "direction": "DESC",
                    "field": "CREATED"
                },
                "first": rowsPerPage,
                "after": null,
                "before": null,
            }
        }

        if (searchValue) {
            data['variables']['filter']['search'] = searchValue
        }

        getProductList(data);

    }, [searchValue, rowsPerPage])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = () => {
        return true;
    };

    const handleChangePage = (event, newPage) => {
        let data = {
            "variables": {
                "filter": {
                    "search": null,
                    "sellers": null
                },
                "sortBy": {
                    "direction": "DESC",
                    "field": "CREATED"
                },
                "after": null,
                "before": null
            }
        }

        if (searchValue) {
            data['variables']['filter']['search'] = searchValue
        }

        let previousPage = page;
        if ((newPage < previousPage) && pageInfo.hasPreviousPage) {
            data['variables']['last'] = rowsPerPage;
            data['variables']['before'] = pageInfo.startCursor;
        } else if ((newPage > previousPage) && pageInfo.hasNextPage) {
            data['variables']['first'] = rowsPerPage;
            data['variables']['after'] = pageInfo.endCursor
        }
        setPage(newPage);
        getProductList(data);

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    return (
        <>
            <div>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            tableHeader={mainHeadCells}
                        />
                        <TableBody>
                            {productData.length > 0 ? productData.map((product, index) => {
                                return (
                                    <>
                                        <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <div className="product-desc">
                                                    <img src={product?.node?.thumbnail?.url ? product?.node?.thumbnail?.url : NoProductImage} height="100%" alt="" />
                                                    {product.node.name}
                                                </div>

                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <TableContainer>
                                                    <Table
                                                        sx={{ minWidth: 750 }}
                                                        aria-labelledby="tableTitle"
                                                        size={dense ? 'small' : 'medium'}
                                                    >
                                                        <EnhancedTableHead
                                                            order={order}
                                                            orderBy={orderBy}
                                                            onSelectAllClick={handleSelectAllClick}
                                                            onRequestSort={handleRequestSort}
                                                            tableHeader={subHeadCells}
                                                        />
                                                        <TableBody>
                                                            {product?.node?.variants.length > 0 && product?.node?.variants.map(
                                                                (variant, index) => {
                                                                    return (
                                                                        <TableRow
                                                                            hover
                                                                            draggable
                                                                            onDragStart={(event) => handleProductDragStart(event, {
                                                                                name: product?.node?.name,
                                                                                imgSrc: product?.node?.thumbnail?.url,
                                                                                productId: product?.node?.id,
                                                                                variantId: variant?.id
                                                                            })}

                                                                            onDoubleClick={() => handleSelectProduct(
                                                                                product?.node?.name,
                                                                                product?.node?.thumbnail?.url, product?.node?.id,
                                                                                variant?.id)}
                                                                            role="checkbox"
                                                                            tabIndex={-1}
                                                                            key={index}
                                                                            className="product-table-row"
                                                                        >
                                                                            {subHeadCells.map((row) => {
                                                                                return (
                                                                                    <>
                                                                                        {row.id === 'pricing' || row.id
                                                                                            === 'weight' || row.id
                                                                                            === 'priceUndiscounted' ?
                                                                                            <TableCell>0</TableCell> :
                                                                                            <TableCell>{variant[row.id]}</TableCell>
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionDetails>
                                        </Accordion>
                                    </>
                                )

                            })
                                :
                                <>
                                    No Products
                                </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalproductData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {alert.error ? (
                    <CustomizedSnackbars
                        open={alert.error}
                        vertical="top"
                        horizontal="right"
                        message={alert.message}
                        color="error"
                    />
                ) : (
                    alert.success && (
                        <CustomizedSnackbars
                            open={alert.success}
                            vertical="top"
                            horizontal="right"
                            message={alert.message}
                            color="success"
                        />
                    )
                )}
            </div>
        </>
    );
}
