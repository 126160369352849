import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { generatePreSignedURL } from "../../../../services/aws/s3FileUpload";

const RenderImage = ({ s3ObjectId }) => {
  const [url, setURL] = useState("");
  useEffect(() => {
    if (!!s3ObjectId) {
      generatePreSignedURL(s3ObjectId)
        .then((data) => {
          setURL(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [s3ObjectId]);

  return <Avatar src={!!url ? url : ""} sx={{}} />;
};

export default RenderImage;
