import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { DataGrid } from "@mui/x-data-grid";
import Label from "../../../../components/Label";
import {
  ArrowRight as ArrowRightIcon,
  Edit as EditIcon,
} from "react-feather";
import SortIcon from "../../../../components/UI/SortIcon";
import { formateDate } from "../../../../util/dateformat";

const labelColors = {
  complete: "success",
  pending: "warning",
  rejected: "error",
};
const OderProfileDetails = (props) => {
    let date = new Date();
    let data =[
        {
            id: "123",
            handle:{
                name: "Sapien",
                photo: null,
            },
            name:"Libero sem",
            email:"myname@email.com",
            avgSpend:"$250",
            lastLogin: date,
            status:"Active",
            action:"action"
        }
    ]
    const columns = [
        { field: "id", headerName: "Id", width: 130, hide: true },
        {
          field: "handle",
          headerName: "Handle",
          sortable: false,
          type: "action",
          width: 190,
          renderCell: (params) => {
            return (
              <Link to={`/Manage/Profiles/${params.id}`} className="handle">
                <Avatar src={params.value.photo} />
                <Typography variant="purpleText" sx={{ marginLeft: "8px" }}>{params.value.name}</Typography>
              </Link>
            );
          },
        },
        {
          field: "name",
          headerName: "Name",
          sortable: false,
          width: 190,
          renderCell: (params) => {
            return <Typography variant="purpleText">{params.value}</Typography>;
          },
        },
        {
          field: "email",
          headerName: "E-Mail",
          sortable: false,
          renderCell: (params) => {
            return <Typography variant="purpleText">{params.value}</Typography>;
          },
          width: 190,
        },
        {
            field: "avgSpend",
            headerName: "Avg Spend",
            sortable: false,
            renderCell: (params) => {
              return <Typography variant="balckText">{params.value}</Typography>;
            },
            width: 190,
        },
        {
            field: "lastLogin",
            headerName: "Last Login",
            sortable: false,
            renderCell: (params) => {
            const { newForamteDate } = formateDate(params.value);

              return <Typography variant="balckText">{newForamteDate}</Typography>;
            },
            width: 180,
        },
        {
          field: "status",
          headerName: "Status",
          sortable: false,
          width: 100,
          renderCell: (params) => {
            return (
              <>
                <Label
                  color={
                    params.value === "Active"
                      ? labelColors["complete"]
                      : labelColors["pending"]
                  }
                >
                  {params.value}
                </Label>
              </>
            );
          },
        },
        {
          field: "action",
          headerName: "Action",
          width: 120,
          sortable: false,
          type:"actions",
          renderCell: () => {
            return (
                <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "space-around",
                  alignItems: "center",
                  paddingLeft: "20px",
                }}
              >
                <IconButton
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <EditIcon color="#263238" height="18px" width="18px" />
                </IconButton>
                <IconButton
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ArrowRightIcon color="#5850EC" height="14px" width="14px" />
                </IconButton>
              </Box>
            );
          },
        },
      ];
  return (
    <div> <Box sx={{ width: '100%' }}>
    <DataGrid
      autoHeight
      disableColumnMenu={true}
      rows={data}
      columns={columns}
      sx={{
        border: 0,
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          display: "flex",
          justifyContent: "space-between",
        },
        "& .MuiDataGrid-iconButtonContainer": {
          display: "flex",
          width: "auto",
          visibility: "visible",
        },
        "& .MuiDataGrid-footerContainer" :{
            display: "none"
        }
      }}
      components={{
        ColumnSortedAscendingIcon: SortIcon,
        ColumnSortedDescendingIcon: SortIcon,
        ColumnUnsortedIcon: SortIcon,
      }}
      loading={props.loading}
      rowCount={1}
      rowsPerPageOptions={[1]}
      pagination
      page={0}
      pageSize={1}
      paginationMode=""
    />
  </Box></div>
  )
}

export default OderProfileDetails;
