import React, { useCallback, useEffect, useState } from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import PropTypes from 'prop-types';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {PlusCircle as PlusCircleIcon, Search as SearchIcon, X as CloseIcon} from 'react-feather';
import PostVideoPreview from "./PostVideoPreview";
import ProductTable from "./ProductTable";
import CustomizedSnackbars from "../../../../components/Alert/CustomizedSnackbars";
import {editPost, getHashTags, listPosts, getPostDetail} from "../../../../services/postsService";
import AddressAutoComplete from "../../../../components/AddressAutocomplete";
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import ProgressBar from "../../../../components/UploadFile/ProgressBar";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import './PostPage.css'
import MuxVideo from '@mux/mux-video-react';
import { getErrorMessageFromErrorResponse } from "../../../../services/helper";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  tabValue: PropTypes.number.isRequired,
};

function EditPostPage() {

  const { state } = useLocation();
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const [muxId,setMuxId] = useState();
  useEffect(() => {
    handleTagPeople();
    handlePostTags();
  }, []);

  const [uploadFile, setUploadFile] = useState({
    post: [],
    product1: [],
    product2: [],
    product3: [],
    product4: [],
    product5: []
  })
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [isProduct1SearchEnabled, setIsProduct1SearchEnabled] = useState(false);
  const [isProduct2SearchEnabled, setIsProduct2SearchEnabled] = useState(false);
  const [isProduct3SearchEnabled, setIsProduct3SearchEnabled] = useState(false);
  const [isProduct4SearchEnabled, setIsProduct4SearchEnabled] = useState(false);
  const [isProduct5SearchEnabled, setIsProduct5SearchEnabled] = useState(false);
  const [description, setDescription] = useState("");
  const [searchValue, setSearchValue] = useState('');
  const [alert, setAlert] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);
  const [openPostVideo, setOpenPostVideo] = useState(false);
  const [tagPeopleOptions, setTagPeopleOptions] = useState([]);
  const [postTagsOptions, setPostTagsOptions] = useState([]);
  const [selectedPostTags, setSelectedPostTags] = useState([]);
  const [selectedTagPeople, setSelectedTagPeople] = useState([]);
  const [loading, setloading] = useState(true);
  const [initialState, setInitialState] = useState({});


  const validationSchemaProfile = Yup.object().shape({
    post: Yup.array().nullable(),
    product: Yup.array().nullable().required('Atleast one product is required'),
  });

  const initialValues = {
    postVideo: [],
    product1: [],
  };

  const {
    register,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: {errors}
  } = useForm({
    resolver: yupResolver(validationSchemaProfile)
  });


  const  fetchPostDetail = useCallback(async() => {
    let param = {
      post_id : parseInt(state.post_id),
      profile_id: parseInt(state.profile_id)
    };
    let queryParams = new URLSearchParams(param).toString();
    try {
      const data = await getPostDetail(queryParams);
      if(data){
        setDescription(data?.description);
        setInitialState({...initialState, description : data?.description});
        setMuxId(data?.mux_playback_id);
        let tagPeopleList = data?.mentions;
        let listOfPeople = [];
        for (let index = 0; index < tagPeopleList.length; index++) {
            let people = {};
            people['id'] = tagPeopleList[index]['id'];;
            people['label'] = tagPeopleList[index]['user_name'];
            people['title'] = tagPeopleList[index]['user_name'];
            listOfPeople.push(people);
        }
        setSelectedTagPeople(listOfPeople);
        setInitialState({...initialState, mentions : listOfPeople});

        let tagpostList = data?.hashtags;
        let listOfPost = [];
        for (let index = 0; index < tagpostList.length; index++) {
            let post = {};
            post['id'] = tagpostList[index]['id'];
            post['label'] = tagpostList[index]['name']
            post['title'] = tagpostList[index]['name']
            listOfPost.push(post);
        }
        setSelectedPostTags(listOfPost);
        setInitialState({...initialState, hashtags : listOfPost});

        let products = data?.product_list;
        if(products.length > 0){
            let tempUploadFile = {}
            products.forEach((prod,index) => {
                let name = prod?.nautical_product?.name;
                let imgSrc = prod?.nautical_product?.thumbnail?.url;
                let productId = prod?.nautical_product_id;
                let variantId = prod?.selected_variant_id;
                tempUploadFile[`product${index+1}`]=[{name,preview:imgSrc, productId, variantId}];
            });
            setUploadFile({...uploadFile,...tempUploadFile});
            setInitialState({...initialState, products : tempUploadFile});
            setValue("product",tempUploadFile.product1);
        }
        let address = data?.full_address;
        setValue("location", address);
        setInitialState({...initialState, location : address, longitude: data?.longitude , latitude: data?.latitude });
        setloading(false);
      }
    } catch (error) {
      console.log(error);
    }
  },[state]);
  
  useEffect(() => {
    fetchPostDetail();
  }, [fetchPostDetail]);
  

  const handleOpenVideoModal = () => {
    setOpenPostVideo(true);
  };

  const handleCloseVideoModal = () => {
    setOpenPostVideo(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTextEditorChange = (e) => {
    let tempvalue = removeHtmlTags(e.target.value);
    setDescription(tempvalue);
    setValue('description', tempvalue)
  }


  const handleSelectProduct = (name, imgSrc, productId, variantId) => {
    setAlert({});
    if (uploadFile.product1.length === 0) {
      setIsProduct1SearchEnabled(false);
      setUploadFile({...uploadFile, product1: [{name, preview: imgSrc, productId, variantId}]})
      setValue('product', uploadFile.product1)
    }
    else if (uploadFile.product2.length === 0) {
      setIsProduct2SearchEnabled(false);
      setUploadFile({...uploadFile, product2: [{name, preview: imgSrc, productId, variantId}]})
      setValue('product', uploadFile.product2)
    }
    else if (uploadFile.product3.length === 0) {
      setIsProduct3SearchEnabled(false);
      setUploadFile({...uploadFile, product3: [{name, preview: imgSrc, productId, variantId}]})
      setValue('product', uploadFile.product3)
    }
    else if (uploadFile.product4.length === 0) {
      setIsProduct4SearchEnabled(false);
      setUploadFile({...uploadFile, product4: [{name, preview: imgSrc, productId, variantId}]})
      setValue('product', uploadFile.product4)
    }
    else if (uploadFile.product5.length === 0) {
      setIsProduct5SearchEnabled(false);
      setUploadFile({...uploadFile, product5: [{name, preview: imgSrc, productId, variantId}]})
      setValue('product', uploadFile.product5)
    }

  }

  const removeHtmlTags=(value)=>{
    return value.replace(/(<([^>]+)>)/gi, "");
  }

  const handleEditPost = async (value) => {
    let data = {
      "post_id": parseInt(state.post_id),
      "profile_id": parseInt(state.profile_id),
      "products": [
        ...Object.keys(uploadFile).filter(key => {
          return key.startsWith('product') && uploadFile[key].length > 0
        })
          .map((key) => {
            return ({
              'nautical_product_id': uploadFile[key][0]['productId'],
              'nautical_variant_id': uploadFile[key][0]['variantId']
            })
          })
      ],
      "hashtags": selectedPostTags.map(data=> data.id),
      "status" : value,
    };
    
    if(initialState.description !== description){
      data["description"] = description;
    }
    if(initialState.location !== getValues("location")){
      data["longitude"] = getValues('locationGeometry') && getValues('locationGeometry').lng;
      data["latitude"] = getValues('locationGeometry') && getValues('locationGeometry').lat;
      data["full_address"] = getValues('location');
    };
    await editPost(data)
      .then(function (response) {
        navigate(`/Manage/Profiles/${parseInt(state.profile_id)}`, { replace: true})
        
      })
      .catch(function (error) {
        console.error("error:", error)
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
        return true
      })
  }

  const handleUploadFile = (key, value) => {
    setUploadFile({...uploadFile, [key]: value})
    if (key.startsWith('product')) {
      setValue('product', value)
    } else {
      setValue(key, value)
    }
  }

  const postVideo = uploadFile.post.map((file) => (
    <video width="100%" height="100%" className="video-opacity">
      <source src={file.preview}/>

    </video>
  ))

  const product1Images = uploadFile.product1.map((file) => (
    <div className="img-wrap" style={{width: "20%"}}>
      <img src={file.preview} className="product-image" alt=""/>
    </div>
  ))

  const product2Images = uploadFile.product2.map((file) => (
    <div className="img-wrap" style={{width: "20%"}}>
      <img src={file.preview} className="product-image" alt=""/>
    </div>
  ))

  const product3Images = uploadFile.product3.map((file) => (
    <div className="img-wrap" style={{width: "20%"}}>
      <img src={file.preview} className="product-image" alt=""/>
    </div>
  ))

  const product4Images = uploadFile.product4.map((file) => (
    <div className="img-wrap" style={{width: "20%"}}>
      <img src={file.preview} className="product-image" alt=""/>
    </div>
  ))

  const product5Images = uploadFile.product5.map((file) => (
    <div className="img-wrap" style={{width: "20%"}}>
      <img src={file.preview} className="product-image" alt=""/>
    </div>
  ))

  const handleTagPeople = (newValue) => {
    listPosts(newValue)
      .then(function (response) {
        let profileResponse = response.profiles;
        if (profileResponse.length > 0) {
          let listOfPeople = [];
          for (let index = 0; index < profileResponse.length; index++) {
            let people = {};
            people['id'] = profileResponse[index]['id'];;
            people['label'] = profileResponse[index]['user_name'];
            people['title'] = profileResponse[index]['user_name'];
            listOfPeople.push(people);
          }
          setTagPeopleOptions(listOfPeople);
        }

      })
      .catch(function (error) {
        console.error("list post error:", error)
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
        return true
      })
  }

  const handleDiscard = () => {
    navigate(`/Manage/Profiles/${parseInt(state.profile_id)}`, {
      replace: true,
    });
  }

  const handlePostTags = (newValue) => {
    getHashTags(newValue)
      .then(function (response) {
        let hashTagResponse = response.hashtags;
        if (hashTagResponse.length > 0) {
          let listOfPost = [];
          for (let index = 0; index < hashTagResponse.length; index++) {
            let post = {};
            post['id'] = hashTagResponse[index]['id'];
            post['label'] = hashTagResponse[index]['name']
            post['title'] = hashTagResponse[index]['name']
            listOfPost.push(post);
          }
          setPostTagsOptions(listOfPost)
        }

      })
      .catch(function (error) {
        console.error("hash tag error:", error)
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
        return true
      })
  }
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Brands Profile
    </Link>
  ];

  return (

    <div style={{marginLeft: "10%", marginRight: "10%"}}>
      <div>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={9}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small"/>}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
              <Typography key="2" color="text.primary">Posts</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <h2><strong>Edit Post</strong></h2>
      </div>
       <Paper style={{marginTop: 5}}>
        <>
          <Box
            px={3} py={2}
            sx={{
              display: 'flex',
              '& > :not(style)': {

                height: 400,
              },
            }}>
            <Box

              sx={{
                display: 'flex',
                '& > :not(style)': {
                  width: 1 / 2
                },
              }}
            >
              <Box
                mr={2}
                sx={{
                  '& > :not(style)': {
                    width: 1,
                    height: 400,
                  },
                }}
              >
                <Paper variant="outlined">

                  <div className="custom-file-upload post-upload fas">
                    {!uploadFile.post.length > 0 ?
                      <>
                      {muxId?
                      <>
                        <div className="post-video video">
                          <MuxVideo
                            style={{ height: '100%',  maxWidth: "223px" , maxHeight: "398px" }}
                            playbackId={muxId}
                            controls
                          />
                        </div>
                         {/* <div className="image-cross">
                           <CloseIcon size="16px" onClick={() => setMuxId(null)}/>
                       </div> */}
                       </>
                        :
                        <div onClick={() => setIsSearchEnabled(false)}>
                          <UploadFile
                            required
                            id="post"
                            error={!!errors.postVideo}
                            setFile={(value) => handleUploadFile("post", value)}
                            inputName="post"
                            register={register}
                          >
                            <PlusCircleIcon className="add-icon"/>
                            <h5>Add Post</h5>
                            <p className="post-upload-text">Drag post file here or click
                              browse your machine</p>
                          </UploadFile>
                        </div>
                        }
                      </>
                      :
                      <>
                      
                        <div onClick={handleOpenVideoModal} className="post-video video">
                          {postVideo}
                          {uploadProgress > 0 ?

                            <div className="play-icon">
                              <ProgressBar value={uploadProgress}/>
                            </div>
                            :
                            <PlayCircleIcon sx={{fontSize: 80}} className="play-icon"/>}
                        </div>
                        <div className="image-cross">
                          {uploadFile.post.length >0 || muxId &&
                            <CloseIcon size="16px" onClick={() => setUploadFile(
                              {...uploadFile, post: []})}/>}
                        </div>
                      </>
                    }
                  </div>
                  <Typography variant="inherit" style={{color: "red"}}>
                    {errors.post?.message}
                  </Typography>
                </Paper>
              </Box>

              <Box
                mr={2}
                sx={{
                  display: 'grid',
                  gap: 1.5,
                  '& > :not(style)': {

                    width: 1,
                    height: 70,
                  },
                }}
              >

                <Paper variant="outlined">
                  {!isProduct1SearchEnabled ?
                    <UploadFile
                      required
                      id="product1"
                      name="product1"
                      {...register('product1')}
                      error={!!errors.product1}
                      setFile={(value) => handleUploadFile("product1", value)}
                      inputName="product"
                      register={register}
                      noBrowseOpen={true}
                      inputRootclassName="custom-file-upload product-selection"
                    >
                      {!uploadFile.product1.length > 0 ?
                        <div className="add-icon-main">
                          <PlusCircleIcon className="add-icon"/>
                        </div> :
                        <>
                          {product1Images}
                        </>
                      }
                      <div style={{width: "80%"}}>
                        {uploadFile.product1.length > 0 ?

                          <div>
                            {uploadFile.product1[0].name}
                          </div>
                          :
                          <div onClick={() => {
                            setIsProduct1SearchEnabled(true);
                            setTabValue(0);
                          }}>
                            <div>Product 1</div>
                            <div className="product-sub-desc">Drag selection here or
                              Search
                            </div>
                          </div>

                        }
                      </div>
                      <div className="image-cross">
                        {uploadFile.product1.length > 0 && !uploadFile.product2.length > 0 &&
                          <CloseIcon size="16px" onClick={() => setUploadFile(
                            {...uploadFile, product1: []})}/>}
                      </div>
                    </UploadFile> :
                    <TextField
                      id="outlined-search"
                      name="searchProduct1"
                      placeholder="Search products"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment
                          position="start"><SearchIcon/></InputAdornment>,
                        endAdornment: <InputAdornment position="end"><IconButton><CloseIcon
                          onClick={() => setIsProduct1SearchEnabled(
                            false)}/></IconButton></InputAdornment>,
                        sx: {height: 70}
                      }}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                        setTabValue(0)
                      }}
                    />
                  }
                </Paper>
                <Paper variant="outlined">

                  {!isProduct2SearchEnabled ?
                    <UploadFile
                      setFile={(value) => handleUploadFile("product2", value)}
                      inputName="product"
                      register={register}
                      noBrowseOpen={true}
                      inputRootclassName="custom-file-upload product-selection fas"
                    >
                      {!uploadFile.product2.length > 0 ?
                        <div className="add-icon-main">
                          <PlusCircleIcon className="add-icon"/>
                        </div> :
                        <>
                          {product2Images}
                        </>
                      }
                      <div style={{width: "80%"}}>
                        {uploadFile.product2.length > 0 ?
                          <div>
                            {uploadFile.product2[0].name}
                          </div>
                          :
                          <div onClick={() => {
                            uploadFile.product1.length > 0 && setIsProduct2SearchEnabled(
                              true)
                          }}>
                            <div>Product 2</div>
                            <div className="product-sub-desc">Drag selection here or
                              Search
                            </div>
                          </div>

                        }
                      </div>
                      <div className="image-cross">
                        {uploadFile.product2.length > 0 && !uploadFile.product3.length > 0 &&
                          <CloseIcon
                            onClick={() => setUploadFile({...uploadFile, product2: []})}/>}
                      </div>
                    </UploadFile> :
                    <TextField
                      id="outlined-search"
                      name="searchProduct2"
                      placeholder="Search products"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment
                          position="start"><SearchIcon/></InputAdornment>,
                        endAdornment: <InputAdornment position="end"><IconButton><CloseIcon
                          onClick={() => setIsProduct2SearchEnabled(
                            false)}/></IconButton></InputAdornment>,
                        sx: {height: 70}
                      }}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                        setTabValue(0)
                      }}
                    />}
                </Paper>
                <Paper variant="outlined">
                  {!isProduct3SearchEnabled ?
                    <UploadFile
                      setFile={(value) => handleUploadFile("product3", value)}
                      inputName="product"
                      register={register}
                      noBrowseOpen={true}
                      inputRootclassName="custom-file-upload product-selection fas"
                    >
                      {!uploadFile.product3.length > 0 ?
                        <div className="add-icon-main">
                          <PlusCircleIcon className="add-icon"/>
                        </div> :
                        <>
                          {product3Images}
                        </>
                      }
                      <div style={{width: "80%"}}>
                        {uploadFile.product3.length > 0 ?
                          <div>
                            {uploadFile.product3[0].name}
                          </div> :
                          <div onClick={() => {
                            uploadFile.product2.length > 0 && setIsProduct3SearchEnabled(
                              true)
                          }}>
                            <div>Product 3</div>
                            <div className="product-sub-desc">Drag selection here or
                              Search
                            </div>
                          </div>

                        }
                      </div>
                      <div className="image-cross">
                        {uploadFile.product3.length > 0 && !uploadFile.product4.length > 0 &&
                          <CloseIcon
                            onClick={() => setUploadFile({...uploadFile, product3: []})}/>}
                      </div>
                    </UploadFile> :
                    <TextField
                      id="outlined-search"
                      name="searchProduct3"
                      placeholder="Search products"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment
                          position="start"><SearchIcon/></InputAdornment>,
                        endAdornment: <InputAdornment position="end"><IconButton><CloseIcon
                          onClick={() => setIsProduct3SearchEnabled(
                            false)}/></IconButton></InputAdornment>,
                        sx: {height: 70}
                      }}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                        setTabValue(0)
                      }}
                    />}
                </Paper>
                <Paper variant="outlined">
                  {!isProduct4SearchEnabled ?
                    <UploadFile
                      setFile={(value) => handleUploadFile("product4", value)}
                      inputName="product"
                      register={register}
                      noBrowseOpen={true}
                      inputRootclassName="custom-file-upload product-selection fas"
                    >
                      {!uploadFile.product4.length > 0 ?
                        <div className="add-icon-main">
                          <PlusCircleIcon className="add-icon"/>
                        </div> :
                        <>
                          {product4Images}
                        </>
                      }
                      <div style={{width: "80%"}}>
                        {uploadFile.product4.length > 0 ?
                          <div>
                            {uploadFile.product4[0].name}
                          </div> :
                          <div onClick={() => {
                            uploadFile.product3.length > 0 && setIsProduct4SearchEnabled(
                              true)
                          }}>
                            <div>Product 4</div>
                            <div className="product-sub-desc">Drag selection here or
                              Search
                            </div>
                          </div>

                        }
                      </div>
                      <div className="image-cross">
                        {uploadFile.product4.length > 0 && !uploadFile.product5.length > 0 &&
                          <CloseIcon
                            onClick={() => setUploadFile({...uploadFile, product4: []})}/>}
                      </div>
                    </UploadFile> :
                    <TextField
                      id="outlined-search"
                      name="searchProduct4"
                      placeholder="Search products"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment
                          position="start"><SearchIcon/></InputAdornment>,
                        endAdornment: <InputAdornment position="end"><IconButton><CloseIcon
                          onClick={() => setIsProduct4SearchEnabled(
                            false)}/></IconButton></InputAdornment>,
                        sx: {height: 70}
                      }}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                        setValue(1)
                      }}
                    />}
                </Paper>
                <Paper variant="outlined">
                  {!isProduct5SearchEnabled ?
                    <UploadFile
                      setFile={(value) => handleUploadFile("product5", value)}
                      inputName="product"
                      register={register}
                      noBrowseOpen={true}
                      inputRootclassName="custom-file-upload product-selection fas"
                    >
                      {!uploadFile.product5.length > 0 ?
                        <div className="add-icon-main">
                          <PlusCircleIcon className="add-icon"/>
                        </div> :
                        <>
                          {product5Images}
                        </>
                      }
                      <div style={{width: "80%"}}>
                        {uploadFile.product5.length > 0 ?
                          <div>
                            {uploadFile.product5[0].name}
                          </div> :
                          <div onClick={() => {
                            uploadFile.product4.length > 0 && setIsProduct5SearchEnabled(
                              true)
                          }}>
                            <div>Product 5</div>
                            <div className="product-sub-desc">Drag selection here or
                              Search
                            </div>
                          </div>

                        }
                      </div>
                      <div className="image-cross">
                        {uploadFile.product5.length > 0 &&
                          <CloseIcon
                            onClick={() => setUploadFile({...uploadFile, product5: []})}/>}
                      </div>
                    </UploadFile> :
                    <TextField
                      id="outlined-search"
                      name="searchProduct5"
                      placeholder="Search products"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment
                          position="start"><SearchIcon/></InputAdornment>,
                        endAdornment: <InputAdornment position="end"><IconButton><CloseIcon
                          onClick={() => setIsProduct5SearchEnabled(
                            false)}/></IconButton></InputAdornment>,
                        sx: {height: 70}
                      }}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                        setTabValue(0)
                      }}
                    />}
                  <Typography variant="inherit" style={{color: "red"}}>
                    {errors.product?.message}
                  </Typography>
                </Paper>

              </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                width:'50%',
                flexFlow:'column',
                '& > :not(style)': {
                  width: 1 
                }}}>
              <Box>
                <TextField
                    fullWidth
                    multiline
                    rows={6}
                    label="Description"
                    margin="normal"
                    name="description"
                    variant="outlined"
                    {...register("description")}
                    style={{marginTop:0, marginBottom: 16}}
                    value={description}
                    onChange={handleTextEditorChange}
                />
              </Box>
              <Box
                sx={{
                  display:'grid',
                  gap:2.75,
                  width: '100%'
                }}
              >
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={postTagsOptions}
                  getOptionLabel={(option) => option.title || ""}
                  isOptionEqualToValue={(option, value)=>option?.id=== value?.id}
                  filterSelectedOptions
                  onInputChange={(event, newValue) => handlePostTags(newValue)}
                  onChange={(event, newValue) => {
                    setSelectedPostTags(newValue)
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={option.title}
                        size="small"
                        onChange={() => true}
                        {...getTagProps({index})}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Post Tags"
                      placeholder="Post Tags"
                    />
                  )}
                  value={selectedPostTags}
                />

                <AddressAutoComplete
                  id="location"
                  name="location"
                  label="Add Location"
                  InputLabelProps={{
                    shrink: getValues('location') && true,
                  }}
                  fieldNames={{
                    'route': 'location',
                    'geometry': 'locationGeometry'
                  }}
                  setValue={setValue}
                  register={register}
                />
              </Box>
              <Typography variant="inherit" style={{color: "red"}}>
                {errors.description?.message}
              </Typography>
            </Box>
          </Box>
        </>
        <Box px={3} py={2}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label="PRODUCTS"
              />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <ProductTable
              searchValue={searchValue}
              handleSelectProduct={handleSelectProduct}
            />
          </TabPanel>

          <Box px={3} py={2}>
            <Grid item xs={6} sm={7}></Grid>
            <Grid item xs={6} sm={4} className="add-user-button">
              <Button
                color="primary"
                variant="text"
                className="cancel-btn"
                onClick={handleDiscard}
              >
                Discard
              </Button>
              <Button
                color="primary"
                className="next-btn"
                onClick={()=>{handleSubmit(handleEditPost("draft"))}}
              >
                Draft
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="next-btn"
                onClick={()=>{handleSubmit(handleEditPost("published"))}}
              >
                Post
              </Button>
            </Grid>
            <Grid item xs={6} sm={1}></Grid>
          </Box>
        </Box>


        </Paper>
      {openPostVideo &&
        <PostVideoPreview
          open={openPostVideo}
          handleClose={handleCloseVideoModal}
          videoSrc={uploadFile.post.length > 0 ? uploadFile.post[0].preview : ''}
        />
      }
      {
        alert.error ?
          <CustomizedSnackbars
            open={alert.error}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="error"
          /> :
          alert.success && <CustomizedSnackbars
            open={alert.success}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="success"
          />
      }
    </div>
  );
}

export default EditPostPage;
