import {
  Backdrop,
  Button,
  Fade,
  Modal,
  Paper,
  Typography,
  Box,
  Avatar,
  Grid,
  InputBase,
  SvgIcon,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Search as SearchIcon } from "react-feather";
import LoadingAnimation from "../../../../../components/LoadingAnimation/LoadingAnimation";
import { DataGrid } from "@mui/x-data-grid";
import SortIcon from "../../../../../components/UI/SortIcon";
import { profileList } from "../../../../../services/user/userService";
import { generatePreSignedURL } from "../../../../../services/aws/s3FileUpload";
import { capitalizeFirstLetter } from "../../../../../util/textConverter";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "650px",
  paddingTop: "30px",
  paddingBottom: "22px",
  paddingLeft: "40px",
  paddingRight: "40px",
  bgcolor: "background.paper",
  boxShadow: "0px 4px 18px rgba(0, 0, 0, 0.25)",
  borderRadius: "4px",
  outline: "none",
  "& .modalHeading": {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "31px",
    letterSpacing: "0.83px",
    textTransform: "uppercase",
    color: "#546E7A",
  },
  "& .userInfo": {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "-0.0555556px",
    color: "263238",
  },
};

const ProfileListModal = ({ open, handleClose, handleOpen, userInfo, assignProfiles, connectedProfile }) => {
  const nameForm = useRef(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [clearButtonVisible, setClearButtonVisible] = useState(false);
  const [users, setUsers] = useState([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCountState, setRowCountState] = useState(0);
  const [selectedProfilesModel, setSelectedProfilesModel] = useState([]);

  const fetchListData = useCallback(async () => {
    setUsers([]);
    let param = {
      limit: pageSize,
      offset: page * pageSize,
      status: "Active",
    };
    if (searchValue) {
      param = { ...param, search: searchValue };
    }
    let searchParams = new URLSearchParams(param).toString();
    let userList = [];
    try {
      const data = await profileList(searchParams);
      if (data) {
        setRowCountState(data?.count);
        for (let i = 0; i < data?.profiles?.length; i++) {
          const photoUrl =
            data?.profiles[i]?.profile_photo_s3_object_id !== null &&
            !!data?.profiles[i]?.profile_photo_s3_object_id
              ? await generatePreSignedURL(
                  data?.profiles[i]?.profile_photo_s3_object_id
                )
              : null;
          let obj = {
            id: data?.profiles[i]?.id,
            handle: {
              photo: photoUrl,
              name: data?.profiles[i]?.user_name,
            },
            name: data?.profiles[i]?.display_name,
            hasShop: !!data?.profiles[i]?.Shop ? "YES" : "NO",
          };
          userList.push(obj);
        }

        setUsers(userList);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [page, pageSize, searchValue]);

  useEffect(() => {
    setLoading(true);
    fetchListData();
  }, [page, pageSize, searchValue, fetchListData]);

  const columns = [
    { field: "id", headerName: "Id", width: 45, hide: true, sortable: false },
    {
      field: "handle",
      headerName: "Handle",
      type: "action",
      sortable: false,
      width: 183,
      renderCell: (params) => {
        return (
          <Link to={`/Manage/Profiles/${params.id}`} className="handle">
            <Avatar src={params.value.photo} />
            <Typography variant="purpleText" sx={{ marginLeft: "8px" }}>
              {params.value.name}
            </Typography>
          </Link>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 221,
      sortable: false,
      renderCell: (params) => {
        return <Typography variant="purpleText">{params.value}</Typography>;
      },
    },
    {
      field: "hasShop",
      headerName: "Has Shop?",
      sortable: false,
      width: 113,
      renderCell: (params) => {
        return <Typography variant="purpleText">{params.value}</Typography>;
      },
    },
  ];

 const handleConnect = () =>{
  console.log("Call API assigning the profiles to the users");
  console.log("selected proifles Id's", selectedProfilesModel);
  assignProfiles(selectedProfilesModel);
  setSelectedProfilesModel([]);
  handleClose();
 }

  const handleClickEvent = () => {
    const form = nameForm.current;
    if (form.value) {
      setSearchValue(form.value);
      setClearButtonVisible(true);
    }
  };

  const handleClickEventClear = () => {
    setSearchValue("");
    setClearButtonVisible(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography className="modalHeading">
            Connect a Profile to:
          </Typography>
          <Typography className="userInfo">
            <strong>{capitalizeFirstLetter(userInfo?.first_name) + " " +capitalizeFirstLetter(userInfo?.last_name)} • </strong> {userInfo.email}
          </Typography>
          <Box minHeight={56} display="flex" alignItems="center">
            <Grid container spacing={2} mt="3px">
              <Grid item>
                <InputBase
                  inputRef={nameForm}
                  sx={{
                    width: "420px",
                    height: "36px",
                    background: "#FFFFFF",
                    border: "1px solid #E0E0E0",
                    borderRadius: "4px",
                    textAlign: "right",
                    display: "flex",
                    padding: 2,
                  }}
                  startAdornment={
                    <SvgIcon fontSize="small" color="action" sx={{marginRight: "11px"}} >
                      <SearchIcon />
                    </SvgIcon>
                  }
                  placeholder="Search profiles"
                  variant="outlined"
                  disabled={clearButtonVisible}
                />
              </Grid>
              <Grid item>
                {clearButtonVisible === true ? (
                  <Button
                    sx={{
                      height: "36px",
                      background: "#F5F5F5",
                      borderRadius: "4px",
                      color: "#263238",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                      textTransform: "uppercase",
                    }}
                    onClick={handleClickEventClear}
                  >
                    Clear Filter
                  </Button>
                ) : (
                  <Button
                    sx={{
                      height: "36px",
                      background: "#F5F5F5",
                      borderRadius: "4px",
                      color: "#263238",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                      textTransform: "uppercase",
                    }}
                    onClick={handleClickEvent}
                  >
                    Search
                  </Button>
                )}
              </Grid>
            </Grid>

            <Box flexGrow={1} />
          </Box>

          {loading ? (
            <Box sx={{ width: "100%", height: "50vh" }}>
              <LoadingAnimation />
            </Box>
          ) : (
            <Box sx={{ width: "100%" }}>
              <DataGrid
                autoHeight
                disableColumnMenu={true}
                rows={users}
                columns={columns}
                checkboxSelection
                sx={{
                  border: 0,
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    display: "flex",
                    justifyContent: "space-between",
                  },
                  "& .MuiDataGrid-iconButtonContainer": {
                    display: "flex",
                    width: "auto",
                    visibility: "visible",
                  },
                }}
                components={{
                  ColumnSortedAscendingIcon: SortIcon,
                  ColumnSortedDescendingIcon: SortIcon,
                  ColumnUnsortedIcon: SortIcon,
                }}
                loading={loading}
                rowCount={rowCountState}
                rowsPerPageOptions={[5]}
                pagination
                page={page}
                pageSize={pageSize}
                paginationMode="server"
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectedProfilesModel(newSelectionModel);
                }}
                selectionModel={selectedProfilesModel}
              />
            </Box>
          )}
          <Grid item xs={6} sm={1}></Grid>
          <Grid item xs={6} sm={6.75}></Grid>
          <Grid item xs={6} sm={4.25} className="add-user-button">
            <Button
              color="primary"
              className="cancel-btn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConnect}
              className="next-btn"
            >
              Connect
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ProfileListModal;
