import React from "react";
import {
  Breadcrumbs,
  Typography,
  Link,
  Container,
  Box,
  Grid,
  SvgIcon,
  Button,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ArrowDown as DownloadIcon } from "react-feather";
import CommentList from "./CommentList";

function ReportedComments() {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      DashBoard
    </Link>,
    <Typography key="2" color="text.primary">
      Reported Comments
    </Typography>,
  ];
  return (
    <>
      <div style={{ height: "10vh", width: "100%" }}>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={9}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>

            <Typography variant="h1">Reported Comments</Typography>
          </Grid>

          <Grid item xs={3} sx={{ textAlign: 'end', paddingRight: "24px" }}>
            <Button
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "4px",
                width: "52px",
                height: "36px",
              }}
            >
              <SvgIcon fontSize="small" >
                <DownloadIcon color="#263238" />
              </SvgIcon>
            </Button>
          </Grid>
        </Grid>
      </div>
      <div style={{ height: "80vh", width: "100%" }}>
        <Container maxWidth={false}>
          <Box mt={3}>
            <CommentList />
          </Box>
        </Container>
      </div>
    </>
  );
}

export default ReportedComments;
