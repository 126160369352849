import React, { useState }  from 'react';
import './loadingAnimation.css'

export default function LoadingAnimation(){
  

    return(
        <div className="container">
            <div className="loader">
                <div className="one"></div>
                <div className="two"></div>
                <div className="three"></div>
                <div className="four"></div>
            </div>
        </div>
    );

}