import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars({
    open, vertical,
    horizontal, message, color,
    setAlert

}) {
    const [show, setShow] = React.useState(open);

    const handleClose = (event, reason) => {
        if (setAlert) setAlert({});
        if (reason === 'clickaway') {
            return;
        }
        setShow(false);
    };

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={show} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert severity={color} onClose={handleClose} sx={{ width: '100%' }}>{message}</Alert>
            </Snackbar>

        </Stack>
    );
}
