import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import React from 'react'
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import OrderList from './Orders/OrderList';

const Orders = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Admin
    </Link>,
    <Typography key="2" color="text.primary">
      Orders
    </Typography>,
  ];
  return (
    <>
      <div style={{ height: "10vh", width: "100%" }}>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
            <Typography variant="h1">Orders</Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ height: "80vh", width: "100%" }}>
        <Container maxWidth={false}>
          <Box mt={3}>
            <OrderList />
          </Box>
        </Container>
      </div>

    </>
  )
}

export default Orders