import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  InputBase,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { DataGrid } from "@mui/x-data-grid";
import Label from "../../../../components/Label";
import {
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
} from "react-feather";
import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { profileList } from "../../../../services/user/userService";
import { useNavigate } from "react-router-dom";
import { updateProfileDetail } from "../../../../services/profile/profileService";
import { generatePreSignedURL } from "../../../../services/aws/s3FileUpload";
import SortIcon from "../../../../components/UI/SortIcon";
import LoadingAnimation from "../../../../components/LoadingAnimation/LoadingAnimation";
import CustomizedSnackbars from "../../../../components/Alert/CustomizedSnackbars";
import { formateDate } from "../../../../util/dateformat";
import { getErrorMessageFromErrorResponse } from "../../../../services/helper";

const labelColors = {
  complete: "success",
  pending: "warning",
  rejected: "error",
};

const columnGroupingModel = [
  {
    groupId: "user",
    headerName: "User",
    headerClassName: "mygroupcolumn-css",
    description: "",
    renderHeaderGroup: (params) => (
      <Typography {...params} variant="blackText" sx={{ lineHeight: "22px" }}>
        {params.headerName}{" "}
      </Typography>
    ),
    children: [
      { field: "id" },
      { field: "handle" },
      { field: "name" },
      { field: "email" },
      { field: "status" },
      { field: "hasShop" },
      { field: "joined" },
      { field: "lastLogin" },
      { field: "days7Login" },
      { field: "days30Login" },
    ],
  },
  {
    groupId: "social",
    headerName: "Social",
    headerClassName: "mygroupcolumn-css",
    description: "",
    renderHeaderGroup: (params) => (
      <Typography {...params} variant="blackText" sx={{ lineHeight: "22px" }}>
        {params.headerName}{" "}
      </Typography>
    ),
    children: [
      { field: "followers" },
      { field: "videos" },
      { field: "lastPostDate" },
    ],
  },
  {
    groupId: "shopping",
    headerName: "Shopping",
    headerClassName: "mygroupcolumn-css",
    description: "",
    renderHeaderGroup: (params) => (
      <Typography {...params} variant="blackText" sx={{ lineHeight: "22px" }}>
        {params.headerName}{" "}
      </Typography>
    ),
    children: [
      {field: "lastorder_id"},
      { field: "lastOrder" },
      { field: "orderDate" },
      { field: "amount" },
      { field: "action" },
    ],
  },
];

function ProfileList() {
  const nameForm = useRef(null);
  const [users, setUsers] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [clearButtonVisible, setClearButtonVisible] = useState(false);
  const [value, setValue] = useState("All");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [alert, setAlert] = useState({});

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [rowCountState, setRowCountState] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const columns = [
    { field: "id", headerName: "Id", width: 130, hide: true },
    { field: "lastorder_id", headerName: "LastOrderId", width: 130, hide: true },
    {
      field: "handle",
      headerName: "Handle",
      type: "action",
      width: 200,
      renderCell: (params) => {
        return (
          <Link to={`/Manage/Profiles/${params.id}`} className="handle">
            <Avatar src={params.row.avatar} />
            <Typography variant="purpleText" sx={{ marginLeft: "8px" }}>
              {params.value}
            </Typography>
          </Link>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => {
        return <Typography variant="purpleText">{params.value}</Typography>;
      },
    },
    {
      field: "email",
      headerName: "E-Mail",
      renderCell: (params) => {
        return <Typography variant="purpleText">{params.value}</Typography>;
      },
      width: 300,
    },
    {
      field: "hasShop",
      headerName: "Has Shop?",
      renderCell: (params) => {
        return <Typography variant="blacktext">{params.value}</Typography>;
      },
      width: 200,
    },
    {
      field: "joined",
      headerName: "Joined",
      renderCell: (params) => {
        const { mmddyy} = formateDate(params.value);
        let date = mmddyy ;
        if (date.includes("N/A")) {
          date = "N/A";
        }
        return <Typography variant="blackText">{date}</Typography>;
      },
      width: 200,
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      renderCell: (params) => {
        const { mmddyy} = formateDate(params.value);
        let date = mmddyy ;
        if (date.includes("N/A")) {
          date = "N/A";
        }
        return <Typography variant="blackText">{date}</Typography>;
      },
      width: 200,
    },
    {
      field: "days7Login",
      headerName: "7 Days Logins",
      renderCell: (params) => {
        return <Typography variant="blacktext">{params.value}</Typography>;
      },
      width: 200,
    },
    {
      field: "days30Login",
      headerName: "30 Days Logins",
      renderCell: (params) => {
        return <Typography variant="blacktext">{params.value}</Typography>;
      },
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Label
              color={
                params.value === "Active"
                  ? labelColors["complete"]
                  : labelColors["pending"]
              }
            >
              {params.value}
            </Label>
          </>
        );
      },
    },
    {
      field: "followers",
      headerName: "Followers",
      width: 200,
      renderCell: (params) => (
        <Typography variant="blackText">{params.value}</Typography>
      ),
    },
    {
      field: "videos",
      headerName: "Videos",
      width: 200,
      renderCell: (params) => (
        <Typography variant="blackText">{params.value}</Typography>
      ),
    },
    {
      field: "lastPostDate",
      headerName: "Last Post",
      width: 156,
      renderCell: (params) => {
        const { mmddyy } = formateDate(params.value);
        return <Typography variant="blackText">{mmddyy}</Typography>;
      },
    },
    {
      field: "lastOrder",
      headerName: "Last order",
      type: "action",
      width: 154,
      renderCell: (params) => {
        if (params?.value) {
          return params?.row?.lastorder_id ? (
            <Link to={`/Manage/Orders/${params.row.lastorder_id}`}>
              <Typography variant="purpleText">{params.value}</Typography>
            </Link>
          ) : (
            <Typography variant="purpleText">{params.value}</Typography>
          );
        }
        return <Typography variant="purpleText">N/A</Typography>;
      },
    },
    {
      field: "orderDate",
      headerName: "Date",
      width: 156,
      renderCell: (params) => {
        const { mmddyy, timeHHMM } = formateDate(params.value);
        let date = mmddyy + " " + timeHHMM;
        if (date.includes("N/A")) {
          date = "N/A";
        }
        return <Typography variant="blackText">{date}</Typography>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 166,
      renderCell: (params) => {
        if (params?.value) {
          return <Typography variant="blackText">{params.value}</Typography>;
        }
        return <Typography variant="blackText">N/A</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <Link to={`/Manage/Profiles/${params.id}`}>
            <IconButton>
              <SvgIcon fontSize="small">
                <ArrowRightIcon color="#5850EC" />
              </SvgIcon>
            </IconButton>
          </Link>
        );
      },
    },
  ];

  const handleActionMenuOpen = (event, status) => {
    if (status === "Active") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileUpdate = (data) => {
    updateProfileDetail(data)
      .then(function (response) {
        return true;
      })
      .catch(function (error) {
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) });
        console.error("update profile error grid user:", error);
        return true;
      });
  };

  const handlePauseUnPause = (profileId) => {
    let data = {
      profile_id: parseInt(profileId),
      status: isActive ? false : true,
      is_pause: isActive ? true : false,
    };
    handleProfileUpdate(data);
    fetchListData().then();
  };

  const handleDelete = (profileId) => {
    let data = {
      profile_id: parseInt(profileId),
      is_deleted: true,
    };
    handleProfileUpdate(data);
    fetchListData().then();
  };

  const handleClickEvent = () => {
    const form = nameForm.current;
    if (form.value) {
      setSearchValue(form.value);
      setClearButtonVisible(true);
    }
  };

  const handleClickEventClear = () => {
    setSearchValue("");
    setClearButtonVisible(false);
  };

  const fetchListData = useCallback(async () => {
    setUsers([]);
    let param = {
      limit: pageSize,
      offset: page * pageSize,
      status: value,
    };
    if (searchValue) {
      param = { ...param, search: searchValue };
    }
    let searchParams = new URLSearchParams(param).toString();
    let userList = [];
    try {
      const data = await profileList(searchParams);
      if (data) {
        setRowCountState(data?.count);
        for (let i = 0; i < data?.profiles?.length; i++) {
          const photoUrl =
            data?.profiles[i]?.profile_photo_s3_object_id !== null && !!(data?.profiles[i]?.profile_photo_s3_object_id) 
              ? await generatePreSignedURL(
                  data?.profiles[i]?.profile_photo_s3_object_id
                )
              : null;
          let obj = {
            id: data?.profiles[i]?.id,
            handle:data?.profiles[i]?.user_name,
            avatar: photoUrl,
            name: data?.profiles[i]?.display_name,
            email: data?.profiles[i]?.User?.email,
            hasShop: data.profiles[i]?.Shop ? "Yes" : "No",
            joined:data?.profiles[i]?.date_created,
            lastLogin: data?.profiles[i]?.last_login || "N/A",
            created: data?.profiles[i]?.date_created,
            days7Login: data?.profiles[i]?.days_7_login,
            days30Login: data?.profiles[i]?.days_30_login,
            status: getUserStatus(
              data?.profiles[i].is_active,
              data?.profiles[i].is_paused
            ),
            followers: data?.profiles[i]?.follower_count,
            videos: data?.profiles[i]?.video_total,
            lastPostDate: data?.profiles[i]?.last_post_info?.date_created,
            views: "N/A",
            clubsJoined: "N/A",
            lastPost: "N/A",
            day7Earnings: "N/A",
            day30Earnings: "N/A",
            lastorder_id: data?.profiles[i]?.last_order_info?.nautical_order_id,
            lastOrder:data?.profiles[i]?.last_order_info?.nautical_order_number,
            orderDate:data?.profiles[i]?.last_order_info?.nautical_order_date,
            amount:data?.profiles[i]?.last_order_info?.nautical_order_localized_amount,
            action: "N/A",
          };
          userList.push(obj);
        }

        setUsers(userList);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [page, pageSize, value, searchValue]);

  const getUserStatus = (is_active, is_paused) => {
    if (is_active) {
      return "Active";
    }else if(!is_active && is_paused){
      return "Paused";
    }
    return "Inactive";
  };

  useEffect(() => {
    setLoading(true);
    fetchListData();
  }, [page, pageSize, value, searchValue, fetchListData]);

  return (
    <>
      <Card>
        <div style={{ height: "5vh", width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab value="All" label="All" />
            <Tab value="Active" label="Active" />
            <Tab value="Inactive" label="Inactive" />
          </Tabs>
        </div>

        <div style={{ height: "10vh", width: "100%" }}>
          <Divider />
          <Box p={2} minHeight={56} display="flex" alignItems="center">
            <Grid container spacing={2}>
              <Grid item>
                <InputBase
                  inputRef={nameForm}
                  sx={{
                    width: "420px",
                    height: "36px",
                    background: "#FFFFFF",
                    border: "1px solid #E0E0E0",
                    borderRadius: "4px",
                    textAlign: "right",
                    display: "flex",
                    padding: 2,
                  }}
                  startAdornment={
                    <SvgIcon fontSize="small" color="action" sx={{}}>
                      <SearchIcon />
                    </SvgIcon>
                  }
                  placeholder="Search customers"
                  variant="outlined"
                  disabled={clearButtonVisible}
                />
              </Grid>
              <Grid item>
                {clearButtonVisible === true ? (
                  <Button
                    sx={{
                      height: "36px",
                      background: "#F5F5F5",
                      borderRadius: "4px",
                      color: "#263238",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                      textTransform: "uppercase",
                    }}
                    onClick={handleClickEventClear}
                  >
                    Clear Filter
                  </Button>
                ) : (
                  <Button
                    sx={{
                      height: "36px",
                      background: "#F5F5F5",
                      borderRadius: "4px",
                      color: "#263238",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                      textTransform: "uppercase",
                    }}
                    onClick={handleClickEvent}
                  >
                    Search
                  </Button>
                )}
              </Grid>
            </Grid>

            <Box flexGrow={1} />
          </Box>
        </div>
        {loading ? (
          <Box sx={{ width: "100%", height: "50vh" }}>
            <LoadingAnimation />
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <DataGrid
              autoHeight
              disableColumnMenu={true}
              rows={users}
              columns={columns}
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={columnGroupingModel}
              checkboxSelection
              sx={{
                border: 0,
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  display: "flex",
                  justifyContent: "space-between",
                },
                "& .MuiDataGrid-iconButtonContainer": {
                  display: "flex",
                  width: "auto",
                  visibility: "visible",
                },
              }}
              components={{
                ColumnSortedAscendingIcon: SortIcon,
                ColumnSortedDescendingIcon: SortIcon,
                ColumnUnsortedIcon: SortIcon,
              }}
              loading={loading}
              rowCount={rowCountState}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </Box>
        )}
      </Card>
      {alert.error ? (
        <CustomizedSnackbars
          open={alert.error}
          vertical="top"
          horizontal="right"
          message={alert.message}
          color="error"
        />
      ) : (
        alert.success && (
          <CustomizedSnackbars
            open={alert.success}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="success"
          />
        )
      )}
    </>
  );
}

ProfileList.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array,
};

ProfileList.defaultProps = {
  users: [],
};

export default ProfileList;
