import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Box,
  SvgIcon,
  Typography,
  InputBase,
  Card,
  IconButton,
  Link,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import Label from "../../../../components/Label";

import {
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
} from "react-feather";
import { _getValue } from "../../../../util/lodashWrapper";
import SortIcon from "../../../../components/UI/SortIcon";
import { formateDate } from "../../../../util/dateformat";
import { getOrderList } from "../../../../services/orders/orderService";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../../../../components/LoadingAnimation/LoadingAnimation";
import CustomizedSnackbars from "../../../../components/Alert/CustomizedSnackbars";
import { getErrorMessageFromErrorResponse } from "../../../../services/helper";

const labelColors = {
  active: "success",
  paused: "warning",
  inactive: "drafted",
};

const OrderList = () => {
  const searchForm = useRef(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [clearButtonVisible, setClearButtonVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [rowCountState, setRowCountState] = useState(0);
  const [alert, setAlert] = useState({});
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [startCursor, setStartCursor] = useState("");
  const [endCursor, setEndCursor] = useState("");
  const [pageInfo, setPageInfo] = useState({});
  const navigate = useNavigate();

  const columns = [
    { field: "id", headerName: "Id", width: 130, hide: true },
    {field: "profileId", headerName: "ProfileId", width: 130, hide: true},
    {
      field: "customerHandle",
      headerName: "Customer Handle",
      width: 300,
      renderCell: (params) => {
        if(!!params?.row?.profileId ){
          return (
            <Link href= {`/Manage/Profiles/${params.row.profileId}`} underline="none">
              <Typography variant="purpleText">{params.value}</Typography>
            </Link>
          );
        }
        return (
            <Typography variant="purpleText">{params.value}</Typography>
        );
      },
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 216,
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
    },
    {
      field: "customerEMail",
      headerName: "Customer E-Mail",
      renderCell: (params) => {
        return <Typography variant="purpleText">{params.value}</Typography>;
      },
      width: 300,
    },
    {
      field: "orderNumber",
      headerName: "Order Number",
      renderCell: (params) => {
        return <Typography variant="purpleText">{params.value}</Typography>;
      },
      width: 300,
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      width: 156,
      renderCell: (params) => {
        const { date, time } = formateDate(params.value);
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="blackText" component="span">
              {" "}
              {date}{" "}
            </Typography>
            <Typography
              variant="blackText"
              component="span"
              sx={{ fontSize: "8px", marginX: "2px" }}
            >
              &#9679;{" "}
            </Typography>
            <Typography variant="blackText" component="span">
              {time}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 166,
    },
    {
      field: "status",
      headerName: "Status",
      width: 105,
      renderCell: (params) => {
        return (
          <Label
            color={
              params.value === "fulfilled"
                ? labelColors["active"]
                : params.value === "canceled"
                ? labelColors["paused"]
                : labelColors["inactive"]
            }
          >
            {params.value}
          </Label>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      type: "actions",
      width: 120,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "space-around",
              alignItems: "center",
              paddingLeft: "20px",
            }}
          >
            <IconButton
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={() =>
                navigate(`/Manage/Orders/${params.id}`, {
                  replace: true,
                })
              }
            >
              <ArrowRightIcon color="#5850EC" height="14px" width="14px" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleSearch = () => {
    const form = searchForm.current;
    if (form.value) {
      setSearchValue(form.value);
      setClearButtonVisible(true);
    }
  };

  const handleSearchClear = () => {
    setSearchValue("");
    searchForm.current.value = "";
    setClearButtonVisible(false);
  };

  const handlePageChange = async (newPage) => {
    setLoading(true);
    let body = {
      variables: {
        sortBy: {
          direction: "DESC",
          field: "CREATION_DATE",
        },
        filter: {
          paymentStatus: [],
          customer: "",
          search: searchValue,
        },
      },
    };

    if (searchValue) {
      body["variables"]["filter"]["search"] = searchValue;
    }

    let previousPage = page;
    if (newPage < previousPage && pageInfo.hasPreviousPage) {
      body.variables.last = pageSize;
      body.variables.before = pageInfo.startCursor;
    } else if (newPage > previousPage && pageInfo.hasNextPage) {
      body.variables.first = pageSize;
      body.variables.after = pageInfo.endCursor;
    }
    setPage(newPage);
    await getOrders(body);
    setLoading(false);
  };

  const getOrderListData = useCallback(async () => {
    let body = {
      variables: {
        sortBy: {
          direction: "DESC",
          field: "CREATION_DATE",
        },
        filter: {
          paymentStatus: [],
          customer: "",
          search: searchValue,
        },
        first: pageSize,
      },
    };

    await getOrders(body);
    setLoading(false);
  }, [pageSize, searchValue]);

  const getOrders = async (body) => {
    let orderList = [];
    try {
      const { data } = await getOrderList(body);
      if (data) {
        setRowCountState(data?.nauticalOrders?.totalCount);
        setPageInfo(data?.nauticalOrders?.pageInfo);
        orderList = data?.nauticalOrders?.edges?.map((order) => ({
          id: order?.node?.id,
          customerHandle: !!(order?.profile_info) ? (order?.profile_info.user_name) : "N/A",
          profileId: !!(order?.profile_info) ? (order?.profile_info?.id) : null,
          customerName: `${order?.node?.shippingAddress?.firstName} ${order?.node?.shippingAddress?.lastName}`,
          customerEMail: order?.node?.userEmail,
          orderNumber: order?.node?.number,
          orderDate: order?.node?.created,
          amount: order?.node?.total?.gross?.localized,
          status: order?.node?.statusDisplay,
          action: "Action",
        }));
        setOrders(orderList);
      }
    } catch (error) {
      setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) });
      console.error(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getOrderListData();
  }, [pageSize, searchValue, getOrderListData]);

  return (
    <>
      <Card
        sx={{
          background: "#FFFFFF",
          boxShadow:
            "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
          borderRadius: "4px",
        }}
      >
        {loading ? (
          <Box sx={{ width: "100%", height: "50vh" }}>
            <LoadingAnimation />
          </Box>
        ) : (
          <>
            <div style={{ height: "10vh", width: "100%" }}>
              <Box p={2} minHeight={56} display="flex" alignItems="center">
                <Grid container spacing={2}>
                  <Grid item>
                    <InputBase
                      inputRef={searchForm}
                      sx={{
                        width: "420px",
                        height: "36px",
                        background: "#FFFFFF",
                        border: "1px solid #E0E0E0",
                        borderRadius: "4px",
                        textAlign: "right",
                        display: "flex",
                        padding: 2,
                      }}
                      startAdornment={
                        <SvgIcon fontSize="small" color="action" sx={{}}>
                          <SearchIcon />
                        </SvgIcon>
                      }
                      placeholder="Search orders"
                      variant="outlined"
                      disabled={clearButtonVisible}
                    />
                  </Grid>
                  <Grid item>
                    {clearButtonVisible === true ? (
                      <Button
                        sx={{
                          height: "36px",
                          background: "#F5F5F5",
                          borderRadius: "4px",
                          color: "#263238",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "24px",
                          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                          textTransform: "uppercase",
                        }}
                        onClick={handleSearchClear}
                      >
                        Clear Filter
                      </Button>
                    ) : (
                      <Button
                        sx={{
                          background: "#F5F5F5",
                          borderRadius: "4px",
                          color: "#263238",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "24px",
                          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                          textTransform: "uppercase",
                          padding: "6px 16px",
                        }}
                        onClick={handleSearch}
                      >
                        Search
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </div>

            <Box sx={{ width: "100%" }}>
              <DataGrid
                autoHeight
                rows={orders}
                columns={columns}
                checkboxSelection
                disableColumnMenu={true}
                sx={{
                  border: 0,
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    display: "flex",
                    justifyContent: "space-between",
                  },
                  "& .MuiDataGrid-iconButtonContainer": {
                    display: "flex",
                    width: "auto",
                    visibility: "visible",
                  },
                }}
                components={{
                  ColumnSortedAscendingIcon: SortIcon,
                  ColumnSortedDescendingIcon: SortIcon,
                  ColumnUnsortedIcon: SortIcon,
                }}
                rowCount={rowCountState}
                loading={loading}
                rowsPerPageOptions={[5, 10, 20]}
                pagination
                page={page}
                pageSize={pageSize}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              />
            </Box>
          </>
        )}
      </Card>
      {alert.error ? (
        <CustomizedSnackbars
          open={alert.error}
          vertical="top"
          horizontal="right"
          message={alert.message}
          color="error"
        />
      ) : (
        alert.success && (
          <CustomizedSnackbars
            open={alert.success}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="success"
          />
        )
      )}
    </>
  );
};

export default OrderList;
