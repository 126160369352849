import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Edit as EditIcon, } from "react-feather";
import { visuallyHidden } from '@mui/utils';
import "./DetailPosts.css"
import MuxVideo from '@mux/mux-video-react';
import { getProfileDetail } from '../../../services/profile/profileService';
import { useContext } from 'react';
import { DetailContext } from './DetailManagePage';
import Label from '../../../components/Label';
import { getMuxAssetId } from '../../../services/postsService';


const labelColors = {
  yes: "success",
  hidden: "warning",
  draft: "drafted",
};

const headCells = [
  {
    id: 'thumb',
    numeric: false,
    label: 'Thumb',
  },
  {
    id: 'postCaption',
    numeric: false,
    label: 'Post Caption',
  },
  {
    id: 'length',
    numeric: true,
    label: 'Length',
  },
  {
    id: 'uploaded',
    numeric: false,
    label: 'Uploaded',
  },
  {
    id: 'modified',
    numeric: false,
    label: 'Modified',
  },
  {
    id: 'posted',
    numeric: false,
    label: 'Posted',
  },
  {
    id: 'products',
    numeric: false,
    label: 'Products',
  },
  {
    id: 'action',
    numeric: false,
    label: 'Action',
  },
  {
    id: '',
    numeric: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function DetailPosts() {
  const [posts, setPosts] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [postDuration, setPostDuration] = useState([]);
  const [postCount, setPostCount] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const { id } = useParams();
  const detailContext = useContext(DetailContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (detailContext) {
      const { setProgress } = detailContext;
      setProgress(true);
    }
    let assetId = '';
    getProfileDetail(id, rowsPerPage, offSet)
      .then(function (response) {
        setPosts(response.post_info.posts)
        let durations = []
        response.post_info.posts.length > 0 && response.post_info.posts.forEach(post => {
          post?.mux_asset_id && getMuxAssetId(post?.mux_asset_id)
            .then(function (response) {
              durations.push(response?.duration)
              setPostDuration([...durations])
            })
            .catch(function (error) {
              return true;
            })

        });
        setPostCount(response.post_info.count)
        if (detailContext) {
          const { setProgress } = detailContext;
          setProgress(false);
        }
      })
      .catch(function (error) {
        if (detailContext) {
          const { setProgress } = detailContext;
          setProgress(false);
        }
        return true;
      })
  }, [rowsPerPage, page])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage > page) {
      setOffSet(page * rowsPerPage);
    } else {
      setOffSet((newPage * rowsPerPage) - rowsPerPage)
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {posts.length > 0 && posts
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      className="post-row"
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <div className="post-thumb">
                          <MuxVideo
                            style={{ height: '100%' }}
                            playbackId={row.mux_playback_id}
                            controls
                          />
                        </div>
                      </TableCell>
                      <TableCell>{row.product_list.length > 0 && row.product_list[0].nautical_product?.name}</TableCell>
                      <TableCell>{postDuration[index] && `${postDuration[index].toString().split(".")[0]}:${postDuration[index].toString().split(".")[1].slice(0,2)}`}</TableCell>
                      <TableCell>{row.date_created}</TableCell>
                      <TableCell>{row.date_modified}</TableCell>
                      <TableCell style={{ width: 105 }}><Label
                        color={
                          row.status === "published"
                            ? labelColors["yes"]
                            : row.value === "draft"
                              ? labelColors["draft"]
                              : labelColors["hidden"]
                        }
                      >
                        <span>{row.status === 'published' ? 'yes' : row.status}</span>
                      </Label></TableCell>
                      <TableCell>{row.product_list.length}</TableCell>
                      <TableCell
                        onClick={() => {
                          navigate("/Manage/EditPost", {
                            replace: true,
                            state: { post_id: row.id, profile_id: id },
                          })
                        }}> <IconButton
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}

                        >
                          <EditIcon color="#263238" height="18px" width="18px" />
                        </IconButton></TableCell>

                      <TableCell></TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={postCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

    </Box>
  );
}

export default DetailPosts;
