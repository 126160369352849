import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Tabs,
  Tab,
  Button,
  Box,
  SvgIcon,
  Typography,
  InputBase,
  Card,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import Label from "../../../../components/Label";
import {
  ArrowRight as ArrowRightIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from "react-feather";
import { userList } from "../../../../services/user/userService";
import { _getValue } from "../../../../util/lodashWrapper";
import SortIcon from "../../../../components/UI/SortIcon";
import LoadingAnimation from "../../../../components/LoadingAnimation/LoadingAnimation";
import UserActions from "./UserActions";
import { Link } from "react-router-dom";

const labelColors = {
  active: "success",
  paused: "warning",
  inactive: "drafted",
};



const UsersList = () => {
  const searchForm = useRef(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [clearButtonVisible, setClearButtonVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState("All");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [rowCountState, setRowCountState] = useState(0);

  const columns = [
    { field: "id", headerName: "Id", width: 130, hide: true },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      renderCell: (params) => {
        return (
          <Link to={`/Manage/Users/${params.id}`} className="profileListItemWrapper">
            <Typography variant="purpleText">{params.value}</Typography>
          </Link>
          );
      },
    },
  
    {
      field: "email",
      headerName: "E-Mail",
      renderCell: (params) => {
        return <Typography variant="purpleText">{params.value}</Typography>;
      },
      width: 300,
    },
    {
      field: "status",
      headerName: "Status",
      width: 105,
      renderCell: (params) => {
        return (
          <Label
            color={
              params.value === "Active"
                ? labelColors["active"]
                : params.value === "Inactive"
                  ? labelColors["inactive"]
                  : labelColors["paused"]
            }
          >
            {params.value}
          </Label>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      type: "actions",
      width: 120,
      renderCell: (params) => {
        return (
          <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "space-around",
            alignItems: "center",
            paddingLeft: "20px",
          }}
        >
          <UserActions
            id={params?.id}
            row={params.row}
            fetchListData={fetchUserData}
          />
          <IconButton
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to={`/Manage/Users/${params.id}`} className="profileListItemWrapper">
            <ArrowRightIcon color="#5850EC" height="14px" width="14px" />
            </Link>
          </IconButton>
        </Box>
        );
      },
    },
  ];

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleSearch = () => {
    const form = searchForm.current;
    if (form.value) {
      setSearchValue(form.value);
      setClearButtonVisible(true);
    }
  };

  const handleSearchClear = () => {
    setSearchValue("");
    searchForm.current.value = "";
    setClearButtonVisible(false);
  };

  const getUserListData = useCallback(async () => {
    let param = {
      limit: pageSize,
      offset: page * pageSize,
      status: currentTab,
      search: searchValue,
    };
    let searchParams = new URLSearchParams(param).toString();
    let userlist = [];
    try {
      const data = await userList(searchParams);
      if (data) {
        setRowCountState(data?.count);
        userlist = data?.users?.map((user) => ({
          id: _getValue(user, "id").value,
          name: `${_getValue(user, "first_name", "").value} ${_getValue(user, "last_name", "").value}`,
          email: _getValue(user, "email").value,
          status: getUserStatus(
            _getValue(user, "is_active").value,
            _getValue(user, "is_paused").value,
            _getValue(user, "is_deleted").value
          ),
          action: "Action",
        }));
        setUsers(userlist);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [page, pageSize, currentTab, searchValue]);

  useEffect(() => {
    fetchUserData();
  }, [page, pageSize, currentTab, searchValue, getUserListData]);

  const fetchUserData = () => {
    setLoading(true);
    getUserListData();
  }

  const getUserStatus = (is_active, is_paused, is_deleted) => {
    if (is_active) {
      return "Active";
    }

    if (is_paused && is_deleted) {
      return "Inactive";
    }

    if (is_paused && !is_deleted) {
      return "Paused";
    }

    return "Inactive";
  };

  return (
    <Card
      sx={{
        background: "#FFFFFF",
        boxShadow:
          "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
        borderRadius: "4px",
      }}
    >
      <div style={{ height: "5vh", width: "100%" }}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="All" label="All" />
          <Tab value="Active" label="Active" />
          <Tab value="Inactive" label="Inactive" />
        </Tabs>
      </div>
      <div style={{ height: "10vh", width: "100%" }}>
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid container spacing={2}>
            <Grid item>
              <InputBase
                inputRef={searchForm}
                sx={{
                  width: "420px",
                  height: "36px",
                  background: "#FFFFFF",
                  border: "1px solid #E0E0E0",
                  borderRadius: "4px",
                  textAlign: "right",
                  display: "flex",
                  padding: 2,
                }}
                startAdornment={
                  <SvgIcon fontSize="small" color="action" sx={{}}>
                    <SearchIcon />
                  </SvgIcon>
                }
                placeholder="Search customers"
                variant="outlined"
                disabled={clearButtonVisible}
              />
            </Grid>
            <Grid item>
              {clearButtonVisible === true ? (
                <Button
                  sx={{
                    height: "36px",
                    background: "#F5F5F5",
                    borderRadius: "4px",
                    color: "#263238",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "24px",
                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                    textTransform: "uppercase",
                  }}
                  onClick={handleSearchClear}
                >
                  Clear Filter
                </Button>
              ) : (
                <Button
                  sx={{
                    background: "#F5F5F5",
                    borderRadius: "4px",
                    color: "#263238",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "24px",
                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                    textTransform: "uppercase",
                    padding: "6px 16px",
                  }}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </div>
      {loading ?
        <Box sx={{ width: "100%", height: "50vh" }}><LoadingAnimation /></Box> :
        <Box sx={{ width: '100%' }}>
          <DataGrid
            autoHeight
            rows={users}
            columns={columns}
            checkboxSelection
            disableColumnMenu={true}
            sx={{
              "& .MuiDataGrid-virtualScroller": {
                bg: "#eee",
                opacity: "0.9",
              },
            }}
            components={{
              ColumnSortedAscendingIcon: SortIcon,
              ColumnSortedDescendingIcon: SortIcon,
              ColumnUnsortedIcon: SortIcon,
            }}
            rowCount={rowCountState}
            loading={loading}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
        </Box>
      }
    </Card>
  );
};

export default UsersList;
