import React from 'react'
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  PlusCircle as PlusCircleIcon,
} from "react-feather";
import CategoriesList from './Categories/CategoriesList';

const Categories = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Admin
    </Link>,
    <Typography key="2" color="text.primary">
      Manage Users
    </Typography>,
    <Typography key="3" color="text.primary">
      Categories
    </Typography>,
  ];
  return (
    <>
      <div style={{ height: "10vh", width: "100%" }}>
        <Grid container justify="space-between" spacing={3}>

          <Grid item xs={12} md={8}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
            <Typography variant="h1">Categories</Typography>
          </Grid>

          <Grid item xs={12} md={4} sx={{
            paddingRight: "24px"
          }}>
            <Box sx={{ display: "flex", justifyContent: 'end', alignItems: 'center', }}>
              <Button
                sx={{
                  background: "#5850EC",
                  borderRadius: "4px",
                  color: "#FFFFFF",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "24px",
                  textTransform: "uppercase",
                  padding: "6px 16px"
                }}
                href="/Manage/Newcategory"
                startIcon={<PlusCircleIcon />}
              >
                New Category
              </Button>
            </Box>

          </Grid>
        </Grid>
      </div>
      <div style={{ height: "80vh", width: "100%" }}>
        <Container maxWidth={false}
        >
          <Box mt={3}>
            <CategoriesList />
          </Box>
        </Container>
      </div>

    </>
  )
}

export default Categories;