import React, { useEffect, useState, createContext } from "react";
import {
  Avatar,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link as MuiLink,
  Tab,
  Tabs,
  Typography,
  Divider,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PropTypes from "prop-types";
import {
  Image as ImageIcon,
  Mail as MailIcon,
  PlusCircle as PlusCircleIcon,
} from "react-feather";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./DetailManagePage.css";
import Details from "./UserDetails";
import ShopDetail from "./ShopDetail";
import DetailPosts from "./DetailPosts";
import UploadFile from "../../../components/UploadFile/UploadFile";
import {
  generatePreSignedURL,
  uploadFileToS3,
} from "../../../services/aws/s3FileUpload";
import { useNavigate, useParams, Link } from "react-router-dom";
import { generateRandomString, getErrorMessageFromErrorResponse } from "../../../services/helper";
import {
  getProfileDetail,
  updateProfileDetail,
} from "../../../services/profile/profileService";
import ProductTable from "../Accounts/BrandPost/ProductTable";
import CustomizedSnackbars from "../../../components/Alert/CustomizedSnackbars";
import CropEasy from "../../../components/CropImage/CropEasy";
import ModalPage from "../../../components/Modal/ModalPage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const DetailContext = createContext();

function DetailManagePage() {
    const [value, setValue] = useState(0);
    const [profileImage, setProfileImage] = useState([]);
    const [coverImage, setCoverImage] = useState([]);
    const [detailPageType, setDetailpageType] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [progress, setProgress] = useState(true);
    const { id } = useParams();
    const [profileDetail, setProfileDetail] = useState({})
    const [shopId, setShopId] = useState(0);
    const imageExtension = ['jpeg', 'jpg', 'png', 'JPEG', 'JPG', 'PNG'];
    const [imageError, setImageError] = useState('');
    const [alert, setAlert] = useState({});
    const [openCrop, setOpenCrop] = useState(false);
    const [cropCoverPhoto, setCropCoverPhoto] = useState(false);
    const cropShape = 'round';
    const [showEmailSendButton, setShowEmailSendButton] = useState(true);

  const navigate = useNavigate();

    useEffect(() => {
        if (profileImage.length > 0 && !openCrop && (profileImage[0]?.preview ? true : false)) handleProfileUpload();
    }, [profileImage]);

    useEffect(() => {
        if (coverImage.length > 0 && !openCrop && (coverImage[0]?.preview ? true : false)) handleProfileUpload();
    }, [coverImage]);

    useEffect(() => {
        getProfileDetail(id)
            .then(function (response) {
                setProfileDetail(response)
                const shopInfo = response.profile_info.Shop
                if (shopInfo !== null) setShopId(shopInfo.id)
                setDetailpageType(shopInfo === null ? false : true)
                if (response.profile_info.profile_photo_s3_object_id !== null) {
                    generatePreSignedURL(`${response.profile_info.profile_photo_s3_object_id}`)
                        .then(function (response) {
                            setProfileImage([{ "presignedUrl": response }]);
                        })
                        .catch(function (error) {
                            console.error("get profile image error:", error)
                            return true
                        })
                }
                if (response.profile_info.cover_photo_s3_object_id !== null) {
                    generatePreSignedURL(`${response.profile_info.cover_photo_s3_object_id}`)
                        .then(function (response) {
                            setCoverImage([{ "presignedUrl": response }]);
                        })
                        .catch(function (error) {
                            console.error("get profile cover image error:", error)
                            return true
                        })

                }
                setProgress(false);
            })
            .catch(function (error) {
                setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
                console.error("get profile error:", error)
                return true
            })
    }, [])

    const handleImageSelect = (files) => {
        if (files) {
            setProfileImage([...files]);
            setOpenCrop(true);
        }

    }

    const handleCoverImageSelect = (files) => {
        if (files) {
            setCropCoverPhoto(true);
            setCoverImage([...files])
            setOpenCrop(true);
        }

    }


    const handleImageDelete = (action) => {
        let data;
        if (action) {
            data = {
                "profile_id": id,
                "cover_photo_s3_object_id": "",
            }
            setCoverImage([])
        }
        else {
            data = {
                "profile_id": id,
                "profile_photo_s3_object_id": "",
            }
            setProfileImage([])
        }
        editProfileDetail(data)

    }

    const handleProfileUpload = async () => {
        const fileName = generateRandomString(16);
        if (cropCoverPhoto) {
            if (coverImage[0]?.name && imageExtension.indexOf(coverImage[0]?.name.split('.').pop()) < 0) {
                setImageError('Selected File is not an image type');
                setCoverImage([]);
            }
            else {
                const data = {
                    "profile_id": id,
                    "cover_photo_s3_object_id": `profile-images/${fileName}`
                }
                const { presignedUrl, url } = await uploadFileToS3(coverImage[0], "profile-images", setUploadProgress, fileName);
                editProfileDetail(data)
                setCoverImage([{ presignedUrl }]);
                setImageError('');
            }
        }
        else {
            if (profileImage[0]?.name && imageExtension.indexOf(profileImage[0]?.name.split('.').pop()) < 0) {
                setImageError('Selected File is not an image type');
                setProfileImage([]);
            }
            else {
                const data = {
                    "profile_id": id,
                    "profile_photo_s3_object_id": `profile-images/${fileName}`
                }
                const { presignedUrl, url } = await uploadFileToS3(profileImage[0], "profile-images", setUploadProgress, fileName);
                editProfileDetail(data)
                setProfileImage([{ presignedUrl }]);
                setImageError('');
            }
        }

    }

  const handleProfileDeactivation = async (status) => {
    handleMoreVerticalClose();
    const data = {
      profile_id: id,
      is_paused: !status,
    };
    editProfileDetail(data);
  };

  const editProfileDetail = (data) => {
    updateProfileDetail(data)
      .then(function (response) {
        setProfileDetail({ ...profileDetail, profile_info: response });
      })
      .catch(function (error) {
        console.error("update profile error:", error);
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) });
        return true;
      });
  };

  const handleTabChange = (event, newValue) => {
    setProgress(true);
    setValue(newValue);
  };

  const handleMoreVerticalOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreVerticalClose = () => {
    setAnchorEl(null);
  };

  const profileImages = profileImage.map((file, index) => (
    <div key={index}>
      <img
        src={file.preview || file.presignedUrl}
        className="product-image"
        alt=""
      />
    </div>
  ));

  const breadcrumbs = [
    <MuiLink underline="hover" key="1" color="inherit" href="/">
      DashBoard
    </MuiLink>,
    <Typography key="2" color="inherit">
      Manage
    </Typography>,
  ];

    return (!openCrop ?
        <DetailContext.Provider value={{ profileId: id, progress, setProgress, shopId, profileImage, coverImage, setOpenCrop, setCropCoverPhoto, handleImageSelect, handleImageDelete, handleCoverImageSelect }}>
            <div>
                <>
                    <div style={{ marginLeft: "10%", marginRight: "10%" }}>
                        <Grid container justify="space-between" spacing={3}>
                            <Grid item xs={9}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                    <Typography key="3" color="text.primary">Profiles</Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                        <div className="profile">
                            <div className="profile-main">
                                <div className="profile-section">
                                    <div className="profile-image">
                                        <Badge
                                            overlap="circular"
                                            badgeContent={
                                                <UploadFile
                                                    setFile={(file) => { handleImageSelect(file) }}
                                                    onDragnDrop={false}
                                                >
                                                    <Avatar
                                                        sx={{
                                                            bgcolor: "white",
                                                            border: "1px solid black",
                                                            width: "28px",
                                                            height: "28px"
                                                        }}
                                                    >
                                                        <EditIcon style={{ color: "#000000", fontSize: "18px" }} />
                                                    </Avatar>
                                                </UploadFile>

                                            }

                                        >
                                            <Avatar sx={{ bgcolor: "white", width: "118px", height: "118px" }}>
                                                {profileImage.length > 0 ?
                                                    <>
                                                        {profileImages}
                                                    </> :
                                                    <ImageIcon color="#8E8E93" size="40px" />
                                                }
                                            </Avatar>
                                        </Badge>
                                        <Typography variant="inherit" color="red">
                                            {imageError}
                                        </Typography>
                                    </div>
                                    {!detailPageType ?
                                        <>
                                            <div className="profile-text">
                                                {progress && <span style={{ color: "green", whiteSpace : "nowrap" }}>SETUP PROCESSING</span>}
                                                <br />
                                                <span>Profile</span>
                                                <h3> {Object.keys(profileDetail).length > 0 && (profileDetail.profile_info.display_name || profileDetail.profile_info.user_name)}
                                                </h3>
                                            </div>

                                        </>
                                        :
                                        <>
                                            <div className="shop-detail-profile-text">
                                                <div className="profile-text-left">
                                                    {progress && <span style={{ color: "green", whiteSpace : "nowrap"  }}>SETUP PROCESSING</span>}
                                                    <p>SHOP</p>
                                                    <h3>{Object.keys(profileDetail).length > 0 && profileDetail.profile_info.Shop.shop_name}</h3>
                                                </div>
                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                <div className="profile-text-right">
                                                    <p>OWNER</p>
                                                    <h3> {Object.keys(profileDetail).length > 0 && (profileDetail.profile_info.display_name || profileDetail.profile_info.user_name)}-<span className="master-admin-text">Master Admin</span></h3>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="profile-section-btn">
                                {showEmailSendButton && (
                                    <Button sx={{
                                        background: "#5850EC",
                                        borderRadius: "4px",
                                        color: "#FFFFFF",
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        textTransform: "uppercase",
                                        marginRight: "10px"
                                    }}
                                        startIcon={<MailIcon />}>
                                        SEND EMAIL
                                    </Button>
                                )}
                                    <Link style={{ textDecoration: "none" }} to="/Manage/Posts/New" state={{ profileId: id , shopId }}>
                                        <Button sx={{
                                            background: "#5850EC",
                                            borderRadius: "4px",
                                            color: "#FFFFFF",
                                            fontFamily: "Roboto",
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            textTransform: "uppercase",
                                            marginRight: "10px"
                                        }} startIcon={<PlusCircleIcon />}
                                        >
                                            New Post
                                        </Button>
                                    </Link>
                                    <IconButton

                                        aria-controls="more-vertical"
                                        onClick={handleMoreVerticalOpen}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="more-vertical"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMoreVerticalClose}
                                    >
                                        <MenuItem onClick={
                                            () => navigate(`/Manage/Profile/EditProfile/${id}`, { replace: true, })
                                        }>Edit</MenuItem>
                                        <MenuItem onClick={
                                            () => handleProfileDeactivation(profileDetail?.profile_info?.is_paused)}
                                        >{profileDetail?.profile_info?.is_paused ? "Activate" : "Deactivate"}</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                        {!detailPageType ?
                            <>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                                        <Tab label="DETAILS"
                                        />
                                        <Tab label="POSTS"
                                        />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <Details />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <DetailPosts profileId={id} />
                                </TabPanel>
                            </> :
                            <>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                                        <Tab label="SHOP DETAIL"
                                        />
                                        <Tab label="POSTS"
                                        />
                                        <Tab label="PRODUCTS"
                                        />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <ShopDetail />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <DetailPosts />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <ProductTable />
                                </TabPanel>
                            </>
                        }
                    </div>

                </>
            </div>
            {
                alert.error ?
                    <CustomizedSnackbars
                        open={alert.error}
                        vertical="top"
                        horizontal="right"
                        message={alert.message}
                        color="error"
                    /> :
                    alert.success && <CustomizedSnackbars
                        open={alert.success}
                        vertical="top"
                        horizontal="right"
                        message={alert.message}
                        color="success"
                    />
            }
        </DetailContext.Provider> :
        <ModalPage {...{ show: openCrop }}><CropEasy {...{ profileImage, setOpenCrop, setProfileImage, cropShape, coverImage, setCoverImage, cropCoverPhoto }} /></ModalPage>
    );
          }

export default DetailManagePage;
