import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./SimpleAccordion.css"
export default function SimpleAccordion({icon, title, children}) {
    return (
        <Accordion className="accordion" disableGutters sx={{
            margin: "0px",
            "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded":{
                minHeight:"fit-content",
                background: "#F5F5F5",
            },
        }}>
            <AccordionSummary
                disableGutters={true}
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="summary"
                sx={{
                    "& .MuiAccordionSummary-content":{
                        margin: "8px 0px",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded":{
                        margin: "8px 0px",
                    },
                }}
                >
                <div className="main-account-header">
                    {icon &&
                        <div className="account-icon">
                            {icon}
                        </div>
                    }
                    <div className="account-text">
                        {title}
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails sx={{
                padding: "2px 24px 16px",
            }}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}
