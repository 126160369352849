import axiosAdminInstance from "../../config/api/axiosAdmin";

const adminList = (data) => {
  return axiosAdminInstance.get(`/v1/users/get-admin-list?` + data);
};
const createAdminUser = (data) => {
  return axiosAdminInstance.post("/v1/users/create-admin-user", data);
};

const deleteAdminUser =(data) => {
  return axiosAdminInstance.delete("/v1/users/delete-admin-user?" + data);
}

const editAdminUser =(data) => {
  return axiosAdminInstance.put("/v1/users/edit-admin-user" , data);
}

const sendResetEmail =(data) => {
  return axiosAdminInstance.post("/v1/users/reset-password" , data);
}

export { adminList, createAdminUser, deleteAdminUser, editAdminUser, sendResetEmail };
