import axiosAdminInstance from '../../config/api/axiosAdmin';

const productList = (data,profileId=0,shopId=0) => {
    if(profileId!==0)
    {   
        return axiosAdminInstance.post(`/v1/products/get-product-list?profile_id=${profileId}${shopId!==0 ? `&shop_id=${shopId}` : ""}`, data);

    }
    else
    {
        return axiosAdminInstance.post(`/v1/products/get-product-list`, data);

    }
    
};

const addProduct = (data) => {
    return axiosAdminInstance.post(`/v1/posts/add-product`, data);
};

export {productList, addProduct};
