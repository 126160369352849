import { Link, useParams } from "react-router-dom";
import {
    Avatar,
  Box,
  Card,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  ArrowRight as ArrowRightIcon,
  Edit as EditIcon,
} from "react-feather";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { profileList } from "../../../../services/user/userService";
import { useNavigate } from "react-router-dom";
import { updateProfileDetail } from "../../../../services/profile/profileService";
import { generatePreSignedURL } from "../../../../services/aws/s3FileUpload";
import SortIcon from "../../../../components/UI/SortIcon";
import LoadingAnimation from "../../../../components/LoadingAnimation/LoadingAnimation";
import CustomizedSnackbars from "../../../../components/Alert/CustomizedSnackbars";
import { Image } from "@mui/icons-material";
import { getOrderDetails } from "../../../../services/orders/orderService";

const labelColors = {
  complete: "success",
  pending: "warning",
  rejected: "error",
};

function OrderDetailsTable( props) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const navigate = useNavigate();

  const columns = [
    { field: "id", headerName: "Id", width: 130, hide: true },
    {
      field: "productDescription",
      headerName: "Product Description",
      type: "action",
      width: 290,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Box sx={{ paddingY: 2, display: "flex", alignItems: 'center', height: "56px", width: '52px', backgroundColor: "#EEEEEE", justifyContent: 'center', borderRadius: '4px' }} component="div">
              <Avatar variant="square" src={params?.value?.photo} alt="Image" style={{height:"23px", width:"23px"}}/>
            </Box>
            <Box>
              <Typography variant="blackText" sx={{ padding: " 0px 8px" }}>{params?.value?.name}</Typography>
            </Box>
          </Box>
        );
     },
    },
    {
      field: "seller",
      headerName: "Seller",
      width: 140,
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
    },
    {
      field: "shop",
      headerName: "Shop",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 140,
    },
    {
        field: "brand",
        headerName: "Brand",
        renderCell: (params) => {
          return <Typography variant="blackText">{params.value}</Typography>;
        },
        width: 140,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        renderCell: (params) => {
          return <Typography variant="blackText">{params.value}</Typography>;
        },
        width: 140,
      },
      {
        field: "price",
        headerName: "Price",
        renderCell: (params) => {
          return <Typography variant="blackText">{params.value}</Typography>;
        },
        width: 150,
      },
      {
        field: "action",
        headerName: "Action",
        type: "actions",
        width: 120,
        renderCell: () => {
          return (
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-around",
                alignItems: "center",
                paddingLeft: "20px",
              }}
            >
              <IconButton
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <EditIcon color="#263238" height="18px" width="18px" />
              </IconButton>
              <IconButton
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ArrowRightIcon color="#5850EC" height="14px" width="14px" />
              </IconButton>
            </Box>
          );
        },
      },
  ];

  const getUserStatus = (is_active, is_paused) => {
    if (is_active) {
      return "Active";
    }
    if (is_paused) {
      return "Paused";
    }
    return "Inactive";
  };

  return (
    <>
      <Card>
          <Box sx={{ width: '100%' }}>
            <DataGrid
              autoHeight
              disableColumnMenu={true}
              rows={props.orderedProductList}
              columns={columns}
              checkboxSelection
              sx={{
                border: 0,
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  display: "flex",
                  justifyContent: "space-between",
                },
                "& .MuiDataGrid-iconButtonContainer": {
                  display: "flex",
                  width: "auto",
                  visibility: "visible",
                },
              }}
              components={{
                ColumnSortedAscendingIcon: SortIcon,
                ColumnSortedDescendingIcon: SortIcon,
                ColumnUnsortedIcon: SortIcon,
              }}
              loading={props.loading}
              rowCount={props?.orderedProductList?.length}
              rowsPerPageOptions={[100]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </Box>
      </Card>
      {
        alert.error ?
          <CustomizedSnackbars
            open={alert.error}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="error"
          /> :
          alert.success && <CustomizedSnackbars
            open={alert.success}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="success"
          />
      }</>
  );
}

export default OrderDetailsTable;
