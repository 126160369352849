import { Icon } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import IconSort from "../../assets/images/icons/chevrons-down.svg";

const SortIcon = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Icon
        sx={{
          height: "30px",
          width: "30px",
        }}
      >
        <img
          alt="sort"
          src={IconSort}
          style={{ height: "auto", width: "10px" }}
        />
      </Icon>
    </Box>
  );
};

export default SortIcon;
