import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import {
  PlusCircle as PlusCircleIcon,
  Search as SearchIcon,
  X as CloseIcon,
} from "react-feather";
import TextEditor from "../../../../components/TextEditor/TextEditor";
import PostVideoPreview from "./PostVideoPreview";
import ProductTable from "./ProductTable";
import CustomizedSnackbars from "../../../../components/Alert/CustomizedSnackbars";
import { uploadFileToS3 } from "../../../../services/aws/s3FileUpload";
import {
  createMuxAsset,
  createPost,
  getHashTags,
  listPosts,
} from "../../../../services/postsService";
import AddressAutoComplete from "../../../../components/AddressAutocomplete";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ProgressBar from "../../../../components/UploadFile/ProgressBar";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import "./PostPage.css";
import { generateRandomString, getErrorMessageFromErrorResponse } from "../../../../services/helper";
import NoProductImage from "../../../../assets/images/no-product-image.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  tabValue: PropTypes.number.isRequired,
};

function PostPage(props) {
  const routeData = useLocation();
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    handlePostTags();
  }, []);

  const [uploadFile, setUploadFile] = useState({
    post: [],
    product1: [],
    product2: [],
    product3: [],
    product4: [],
    product5: [],
  });
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [isProduct1SearchEnabled, setIsProduct1SearchEnabled] = useState(false);
  const [isProduct2SearchEnabled, setIsProduct2SearchEnabled] = useState(false);
  const [isProduct3SearchEnabled, setIsProduct3SearchEnabled] = useState(false);
  const [isProduct4SearchEnabled, setIsProduct4SearchEnabled] = useState(false);
  const [isProduct5SearchEnabled, setIsProduct5SearchEnabled] = useState(false);
  const [description, setDescription] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [alert, setAlert] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);
  const [openPostVideo, setOpenPostVideo] = useState(false);
  const [tagPeopleOptions, setTagPeopleOptions] = useState([]);
  const [postTagsOptions, setPostTagsOptions] = useState([]);
  const [selectedPostTags, setSelectedPostTags] = useState([]);
  const [selectedTagPeople, setSelectedTagPeople] = useState([]);
  const [postError, setPostError] = useState("");
  const [hashTags, setHashTags] = useState([]);
  const [draggedProduct, setDraggedProduct] = useState({});

  const validationSchemaProfile = Yup.object().shape({
    post: Yup.array().nullable().required("Post Video Type is required"),
    product: Yup.array().nullable().required("Atleast one product is required"),
  });

  const {
    register,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemaProfile),
  });

  const handleOpenVideoModal = () => {
    setOpenPostVideo(true);
  };

  const handleCloseVideoModal = () => {
    setOpenPostVideo(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTextEditorChange = (e) => {
    setDescription(e.target.value);
    setValue("description", e.target.value);
  };

  const handleSelectProduct = (name, imgSrc, productId, variantId) => {
    if (uploadFile.product1.length === 0) {
      setIsProduct1SearchEnabled(false);
      setUploadFile({
        ...uploadFile,
        product1: [{ name, preview: imgSrc, productId, variantId }],
      });
      setValue("product", uploadFile.product1);
    } else if (uploadFile.product2.length === 0) {
      setIsProduct2SearchEnabled(false);
      if(uploadFile.product1[0].productId === productId){
        setAlert({ error:true, message: "Same product is added" })
      }
      else{
        setUploadFile({
          ...uploadFile,
          product2: [{ name, preview: imgSrc, productId, variantId }],
        });
        setValue("product", uploadFile.product2);
      }
      
    } else if (uploadFile.product3.length === 0) {
      setIsProduct3SearchEnabled(false);
      if(uploadFile.product1[0].productId === productId || uploadFile.product2[0].productId === productId){
        setAlert({ error:true, message: "Same product is added" })
      }
      else{
        setUploadFile({
          ...uploadFile,
          product3: [{ name, preview: imgSrc, productId, variantId }],
        });
        setValue("product", uploadFile.product3);
      }
    } else if (uploadFile.product4.length === 0) {
      setIsProduct4SearchEnabled(false);
      if(uploadFile.product1[0].productId === productId || uploadFile.product2[0].productId === productId || uploadFile.product3[0].productId === productId){
        setAlert({ error:true, message: "Same product is added"})
      }
      else{
        setUploadFile({
          ...uploadFile,
          product4: [{ name, preview: imgSrc, productId, variantId }],
        });
        setValue("product", uploadFile.product4);
      }
    } else if (uploadFile.product5.length === 0) {
      setIsProduct5SearchEnabled(false);
      if(uploadFile.product1[0].productId === productId || uploadFile.product2[0].productId === productId || uploadFile.product3[0].productId === productId || uploadFile.product4[0].productId === productId){
        setAlert({ error:true, message: "Same product is added"})
      }
      else{
        setUploadFile({
          ...uploadFile,
          product5: [{ name, preview: imgSrc, productId, variantId }],
        });
        setValue("product", uploadFile.product5);
      }
    }
    handleAddProduct(variantId);
    console.log("Alert",alert);
  };

  const handleAddProduct = (productId) => {
    let data = {
      post_id: 1,
      is_nautical_product: true,
      nautical_product_id: productId,
    };
  };

  const handleDiscard = () => {
    navigate(`/Manage/Profiles/${parseInt(routeData.state.profileId)}`, {
      replace: true,
    });
  }

  const handleCreatePost = async (value) => {
    const file = uploadFile.post;
    var assetId;
    var playbackId;
    
    
    const randomFileName = file[0]?.name ? generateRandomString(16) + "." + file[0].name.split(".").pop() : generateRandomString(16) + "." + file[0].path.split(".").pop() ;
    const newFile = file[0]?.name ? file[0] : new File([file[0].preview], randomFileName);
    const { presignedUrl, url } = await uploadFileToS3(
      newFile,
      "post-videos",
      setUploadProgress,
      randomFileName
    );
    let muxData = {
      input: [
        {
          url: presignedUrl,
        },
      ],
      playback_policy: ["public"],
      test: false,
    };
    await createMuxAsset(muxData)
      .then(function (response) {
        assetId = response.id;
        playbackId = response.playback_ids[0].id;
      })
      .catch(function (error) {
        setAlert({ error:true, message: getErrorMessageFromErrorResponse(error) || error.key})
        return true;
      });
    let data = {
      profile_id: parseInt(routeData.state.profileId),
      post_type: 0,
      mux_asset_id: assetId,
      description: description,
      s3_object_id: `post-videos/${file[0].name}`,
      mux_playback_id: playbackId,
      status:value,
      products: [
        ...Object.keys(uploadFile)
          .filter((key) => {
            return key.startsWith("product") && uploadFile[key].length > 0;
          })
          .map((key) => {
            return {
              nautical_product_id: uploadFile[key][0]["productId"],
              nautical_variant_id: uploadFile[key][0]["variantId"],
            };
          }),
      ],
      hashtags: selectedPostTags.map(data=> data.id),
      longitude:
        getValues("locationGeometry") && getValues("locationGeometry").lng,
      latitude:
        getValues("locationGeometry") && getValues("locationGeometry").lat,
      full_address: getValues("location"),
    };
    await createPost(data)
      .then(function (response) {
        localStorage.removeItem("postData");
        navigate(`/Manage/Profiles/${parseInt(routeData.state.profileId)}`, {
          replace: true,
        });
      })
      .catch(function (error) {
        setAlert({ error:true, message: getErrorMessageFromErrorResponse(error) || error.key})
        console.error("error:", error);
        return true;
      });
  };

  const handleUploadFile = (key, value) => {
    if (key.startsWith("product")) {
      setValue("product", value);
      setUploadFile({ ...uploadFile, [key]: value });
    } else if (key === "post") {
      const postFileExtentions = ["mkv", "mp4"];
      if (postFileExtentions.indexOf(value[0]?.name.split(".").pop()) >= 0) {
        setValue("post", [...value]);
        setUploadFile({ ...uploadFile, [key]: [...value] });
      } else {
        setPostError("Selected file is not video type");
      }
    }
  };

  const postVideo = uploadFile.post.map((file) => (
    <video width="100%" height="100%" className="video-opacity">
      <source src={file.preview} />
    </video>
  ));

  const product1Images = uploadFile.product1.map((file) => (
    <div className="img-wrap" style={{ width: "20%" }}>
      <img
        src={file?.preview ? file.preview : NoProductImage}
        className="product-image"
        alt=""
      />
    </div>
  ));

  const product2Images = uploadFile.product2.map((file) => (
    <div className="img-wrap" style={{ width: "20%" }}>
      <img
        src={file?.preview ? file.preview : NoProductImage}
        className="product-image"
        alt=""
      />
    </div>
  ));

  const product3Images = uploadFile.product3.map((file) => (
    <div className="img-wrap" style={{ width: "20%" }}>
      <img
        src={file?.preview ? file.preview : NoProductImage}
        className="product-image"
        alt=""
      />
    </div>
  ));

  const product4Images = uploadFile.product4.map((file) => (
    <div className="img-wrap" style={{ width: "20%" }}>
      <img
        src={file?.preview ? file.preview : NoProductImage}
        className="product-image"
        alt=""
      />
    </div>
  ));

  const product5Images = uploadFile.product5.map((file) => (
    <div className="img-wrap" style={{ width: "20%" }}>
      <img
        src={file?.preview ? file.preview : NoProductImage}
        className="product-image"
        alt=""
      />
    </div>
  ));

  const handlePostTags = (newValue) => {
    getHashTags(newValue)
      .then(function (response) {
        let hashTagResponse = response.hashtags;
        if (hashTagResponse.length > 0) {
          for (let index = 0; index < hashTagResponse.length; index++) {
            hashTagResponse[index]["label"] = hashTagResponse[index]["name"];
            hashTagResponse[index]["title"] = hashTagResponse[index]["name"];
          }
          setPostTagsOptions(hashTagResponse);
        }
      })
      .catch(function (error) {
        console.error("hash tag error:", error);
        return true;
      });
  };

  const handleProductDragStart = (event, data) => {
    setDraggedProduct(data)
  }

  const handleResetAlert = () => {
    setAlert({})
  }

  const handleProductDrop = (event) => {
    const { name, imgSrc, productId, variantId } = draggedProduct;
    setAlert({})
    handleSelectProduct(name, imgSrc, productId, variantId)
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Brands Profile
    </Link>,
  ];

  return (
    <div style={{ marginLeft: "10%", marginRight: "10%" }}>
      <div>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={9}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
              <Typography key="2" color="text.primary">
                Posts
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <h2>
          <strong>Create Post</strong>
        </h2>
      </div>
      <Paper style={{ marginTop: 5 }}>
        <>
          <Box
            px={3}
            py={2}
            sx={{
              display: "flex",
              "& > :not(style)": {
                height: 400,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                "& > :not(style)": {
                  width: 1 / 2,
                },
              }}
            >
              <Box
                mr={2}
                sx={{
                  "& > :not(style)": {
                    width: 1,
                    height: 400,
                  },
                }}
              >
                <Paper variant="outlined">
                  <div className="custom-file-upload post-upload fas">
                    {!uploadFile.post.length > 0 ? (
                      <>
                        <div onClick={() => setIsSearchEnabled(false)}>
                          <UploadFile
                            required
                            id="post"
                            error={!!errors.postVideo}
                            setFile={(value) => handleUploadFile("post", value)}
                            inputName="post"
                            register={register}
                          >
                            <PlusCircleIcon className="add-icon" />
                            <h5>Add Post</h5>
                            <p className="post-upload-text">
                              Drag post file here or click browse your machine
                            </p>
                          </UploadFile>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          onClick={handleOpenVideoModal}
                          className="post-video video"
                        >
                          {postVideo}
                          {uploadProgress > 0 ? (
                            <div className="play-icon">
                              <ProgressBar value={uploadProgress} />
                            </div>
                          ) : (
                            <PlayCircleIcon
                              sx={{ fontSize: 80 }}
                              className="play-icon"
                            />
                          )}
                        </div>
                        <div className="image-cross">
                          {uploadFile.post.length > 0 && (
                            <CloseIcon
                              size="16px"
                              onClick={() =>
                                setUploadFile({ ...uploadFile, post: [] })
                              }
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <Typography variant="inherit" style={{ color: "red" }}>
                    {postError}
                  </Typography>
                </Paper>
              </Box>

              <Box
                mr={2}
                sx={{
                  display: "grid",
                  gap: 1.5,
                  "& > :not(style)": {
                    width: 1,
                    height: 70,
                  },
                }}
              >
                <Paper variant="outlined">
                  {!isProduct1SearchEnabled ? (
                    <div onDrop={handleProductDrop} onDragOver={handleDragOver}>
                    <UploadFile
                      required
                      id="product1"
                      name="product1"
                      {...register("product1")}
                      error={!!errors.product1}
                      setFile={(value) => handleUploadFile("product1", value)}
                      inputName="product"
                      register={register}
                      noBrowseOpen={true}
                      inputRootclassName="custom-file-upload product-selection"
                    >
                      {!uploadFile.product1.length > 0 ? (
                        <div className="add-icon-main">
                          <PlusCircleIcon className="add-icon" />
                        </div>
                      ) : (
                        <>{product1Images}</>
                      )}
                      <div style={{ width: "80%" }}>
                        {uploadFile.product1.length > 0 ? (
                          <div>{uploadFile.product1[0].name}</div>
                        ) : (
                          <div
                            onClick={() => {
                              setIsProduct1SearchEnabled(true);
                              setTabValue(0);
                            }}
                          >
                            <div>Product 1</div>
                            <div className="product-sub-desc">
                              Drag selection here or Search
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="image-cross">
                        {uploadFile.product1.length > 0 &&
                          !uploadFile.product2.length > 0 && (
                            <CloseIcon
                              size="16px"
                              onClick={() =>
                                setUploadFile({ ...uploadFile, product1: [] })
                              }
                            />
                          )}
                      </div>
                    </UploadFile>
                    </div>
                  ) : (
                    <TextField
                      id="outlined-search"
                      name="searchProduct1"
                      placeholder="Search products"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <CloseIcon
                                onClick={() =>
                                  setIsProduct1SearchEnabled(false)
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: { height: 70 },
                      }}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                        setTabValue(0);
                      }}
                    />
                  )}
                </Paper>
                <Paper variant="outlined">
                  {!isProduct2SearchEnabled ? (
                    <div onDrop={handleProductDrop} onDragOver={handleDragOver}>
                    <UploadFile
                      setFile={(value) => handleUploadFile("product2", value)}
                      inputName="product"
                      register={register}
                      noBrowseOpen={true}
                      inputRootclassName="custom-file-upload product-selection fas"
                    >
                      {!uploadFile.product2.length > 0 ? (
                        <div className="add-icon-main">
                          <PlusCircleIcon className="add-icon" />
                        </div>
                      ) : (
                        <>{product2Images}</>
                      )}
                      <div style={{ width: "80%" }}>
                        {uploadFile.product2.length > 0 ? (
                          <div>{uploadFile.product2[0].name}</div>
                        ) : (
                          <div
                            onClick={() => {
                              uploadFile.product1.length > 0 &&
                                setIsProduct2SearchEnabled(true);
                            }}
                          >
                            <div>Product 2</div>
                            <div className="product-sub-desc">
                              Drag selection here or Search
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="image-cross">
                        {uploadFile.product2.length > 0 &&
                          !uploadFile.product3.length > 0 && (
                            <CloseIcon
                              onClick={() =>
                                setUploadFile({ ...uploadFile, product2: [] })
                              }
                            />
                          )}
                      </div>
                    </UploadFile>
                    </div>
                  ) : (
                    <TextField
                      id="outlined-search"
                      name="searchProduct2"
                      placeholder="Search products"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <CloseIcon
                                onClick={() =>
                                  setIsProduct2SearchEnabled(false)
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: { height: 70 },
                      }}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                        setTabValue(0);
                      }}
                    />
                  )}
                </Paper>
                <Paper variant="outlined">
                  {!isProduct3SearchEnabled ? (
                    <div onDrop={handleProductDrop} onDragOver={handleDragOver}>
                    <UploadFile
                      setFile={(value) => handleUploadFile("product3", value)}
                      inputName="product"
                      register={register}
                      noBrowseOpen={true}
                      inputRootclassName="custom-file-upload product-selection fas"
                    >
                      {!uploadFile.product3.length > 0 ? (
                        <div className="add-icon-main">
                          <PlusCircleIcon className="add-icon" />
                        </div>
                      ) : (
                        <>{product3Images}</>
                      )}
                      <div style={{ width: "80%" }}>
                        {uploadFile.product3.length > 0 ? (
                          <div>{uploadFile.product3[0].name}</div>
                        ) : (
                          <div
                            onClick={() => {
                              uploadFile.product2.length > 0 &&
                                setIsProduct3SearchEnabled(true);
                            }}
                          >
                            <div>Product 3</div>
                            <div className="product-sub-desc">
                              Drag selection here or Search
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="image-cross">
                        {uploadFile.product3.length > 0 &&
                          !uploadFile.product4.length > 0 && (
                            <CloseIcon
                              onClick={() =>
                                setUploadFile({ ...uploadFile, product3: [] })
                              }
                            />
                          )}
                      </div>
                    </UploadFile>
                    </div>
                  ) : (
                    <TextField
                      id="outlined-search"
                      name="searchProduct3"
                      placeholder="Search products"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <CloseIcon
                                onClick={() =>
                                  setIsProduct3SearchEnabled(false)
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: { height: 70 },
                      }}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                        setTabValue(0);
                      }}
                    />
                  )}
                </Paper>
                <Paper variant="outlined">
                  {!isProduct4SearchEnabled ? (
                    <div onDrop={handleProductDrop} onDragOver={handleDragOver}>
                    <UploadFile
                      setFile={(value) => handleUploadFile("product4", value)}
                      inputName="product"
                      register={register}
                      noBrowseOpen={true}
                      inputRootclassName="custom-file-upload product-selection fas"
                    >
                      {!uploadFile.product4.length > 0 ? (
                        <div className="add-icon-main">
                          <PlusCircleIcon className="add-icon" />
                        </div>
                      ) : (
                        <>{product4Images}</>
                      )}
                      <div style={{ width: "80%" }}>
                        {uploadFile.product4.length > 0 ? (
                          <div>{uploadFile.product4[0].name}</div>
                        ) : (
                          <div
                            onClick={() => {
                              uploadFile.product3.length > 0 &&
                                setIsProduct4SearchEnabled(true);
                            }}
                          >
                            <div>Product 4</div>
                            <div className="product-sub-desc">
                              Drag selection here or Search
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="image-cross">
                        {uploadFile.product4.length > 0 &&
                          !uploadFile.product5.length > 0 && (
                            <CloseIcon
                              onClick={() =>
                                setUploadFile({ ...uploadFile, product4: [] })
                              }
                            />
                          )}
                      </div>
                    </UploadFile>
                    </div>
                  ) : (
                    <TextField
                      id="outlined-search"
                      name="searchProduct4"
                      placeholder="Search products"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <CloseIcon
                                onClick={() =>
                                  setIsProduct4SearchEnabled(false)
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: { height: 70 },
                      }}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                        setValue(1);
                      }}
                    />
                  )}
                </Paper>
                <Paper variant="outlined">
                  {!isProduct5SearchEnabled ? (
                    <div onDrop={handleProductDrop} onDragOver={handleDragOver}>
                    <UploadFile
                      setFile={(value) => handleUploadFile("product5", value)}
                      inputName="product"
                      register={register}
                      noBrowseOpen={true}
                      inputRootclassName="custom-file-upload product-selection fas"
                    >
                      {!uploadFile.product5.length > 0 ? (
                        <div className="add-icon-main">
                          <PlusCircleIcon className="add-icon" />
                        </div>
                      ) : (
                        <>{product5Images}</>
                      )}
                      <div style={{ width: "80%" }}>
                        {uploadFile.product5.length > 0 ? (
                          <div>{uploadFile.product5[0].name}</div>
                        ) : (
                          <div
                            onClick={() => {
                              uploadFile.product4.length > 0 &&
                                setIsProduct5SearchEnabled(true);
                            }}
                          >
                            <div>Product 5</div>
                            <div className="product-sub-desc">
                              Drag selection here or Search
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="image-cross">
                        {uploadFile.product5.length > 0 && (
                          <CloseIcon
                            onClick={() =>
                              setUploadFile({ ...uploadFile, product5: [] })
                            }
                          />
                        )}
                      </div>
                    </UploadFile>
                    </div>
                  ) : (
                    <TextField
                      id="outlined-search"
                      name="searchProduct5"
                      placeholder="Search products"
                      InputLabelProps={{
                        shrink: false,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <CloseIcon
                                onClick={() =>
                                  setIsProduct5SearchEnabled(false)
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: { height: 70 },
                      }}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                        setTabValue(0);
                      }}
                    />
                  )}
                  <Typography variant="inherit" style={{ color: "red" }}>
                    {errors.product?.message}
                  </Typography>
                </Paper>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "50%",
                flexFlow: "column",
                "& > :not(style)": {
                  width: 1,
                },
              }}
            >
              <Box>
                <TextField
                  fullWidth
                  multiline
                  rows={6}
                  label="Description"
                  margin="normal"
                  name="description"
                  variant="outlined"
                  {...register("description")}
                  style={{ marginTop: 0, marginBottom: 16 }}
                  value={description}
                  onChange={(e) => handleTextEditorChange(e)}
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gap: 2,
                }}
              >
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={postTagsOptions}
                  getOptionLabel={(option) => option.title || ""}
                  isOptionEqualToValue={(option, value)=>option?.id=== value?.id}
                  filterSelectedOptions
                  onInputChange={(event, newValue) => handlePostTags(newValue)}
                  onChange={(event, newValue) => {setSelectedPostTags(newValue)}}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                          key={option.id}
                          label={option.title}
                          size="small"
                          onChange={() => true}
                          {...getTagProps({index})}
                        />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Post Tags"
                      placeholder="Post Tags"
                    />
                  )}
                  value={selectedPostTags}
                />

                <AddressAutoComplete
                  id="location"
                  name="location"
                  label="Add Location"
                  InputLabelProps={{
                    shrink: getValues("location") && true,
                  }}
                  fieldNames={{
                    route: "location",
                    geometry: "locationGeometry",
                  }}
                  setValue={setValue}
                  register={register}
                  setAlert={setAlert}
                />
              </Box>
              <Typography variant="inherit" style={{ color: "red" }}>
                {errors.description?.message}
              </Typography>
            </Box>
          </Box>
        </>
        <Box px={3} py={2}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="PRODUCTS" />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <ProductTable
              searchValue={searchValue}
              handleSelectProduct={handleSelectProduct}
              profileId={routeData.state.profileId}
              shopId={routeData.state.shopId}
              handleResetAlert={handleResetAlert}
              handleProductDragStart={(event, productData) => handleProductDragStart(event, productData)}
            />
          </TabPanel>

          <Box px={3} py={2}>
            <Grid item xs={6} sm={7}></Grid>
            <Grid item xs={6} sm={4} className="add-user-button">
              <Button color="primary" variant="text" className="cancel-btn" onClick={handleDiscard}>
                Discard
              </Button>
              <Button
                color="primary"
                className="next-btn"
                onClick={()=>{handleSubmit(handleCreatePost("draft"))}}
              >
                Draft
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="next-btn"
                onClick={()=>{handleSubmit(handleCreatePost("published"))}}
              >
                Post
              </Button>
            </Grid>
            <Grid item xs={6} sm={1}></Grid>
          </Box>
        </Box>
      </Paper>
      {openPostVideo && (
        <PostVideoPreview
          open={openPostVideo}
          handleClose={handleCloseVideoModal}
          videoSrc={
            uploadFile.post.length > 0 ? uploadFile.post[0].preview : ""
          }
        />
      )}
      {alert?.error ? (
        <CustomizedSnackbars
          open={alert.error}
          vertical="top"
          horizontal="right"
          message={alert.message}
          color="error"
          setAlert={setAlert}
        />
      ) : (
        alert?.success && (
          <CustomizedSnackbars
            open={alert.success}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="success"
            setAlert={setAlert}
          />
        )
      )}
    </div>
  );
}

export default PostPage;
