import {accessKey, bucketName, bucketRegion, secretKey, signedUrlExpires} from "./awsConfig"

var AWS = require("aws-sdk");

export const configAWSBucket = async () => {
    AWS.config = new AWS.Config({
        region: bucketRegion,
        credentials: new AWS.Credentials(accessKey, secretKey),
    });
}


const uploadFileToS3 = async (file, folder, setUploadprogress,name) => {
  await configAWSBucket()
  const fileName = `${folder}/${name}`;
  var presignedUrl;
  var url;

  var upload = await new AWS.S3.ManagedUpload({
    partSize: 5000 * 1024 * 1024, queueSize: 1,
    params: {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
    },
  }).on("httpUploadProgress", function (progress) {
    var progresses = (progress.loaded * 100) / progress.total;
    setUploadprogress(parseInt(progresses))
  });

  var uploadPromise = upload.promise();

  await uploadPromise.then(
    async function (data) {
      url = data.Location;
      presignedUrl = await generatePreSignedURL(fileName);
    },
    function (err) {
      return alert("There was an error uploading your photo: ", err.message);
    }
  );
  return { presignedUrl, url };
}

const generatePreSignedURL = async (fileName) => {
    const s3 = new AWS.S3({
        region: bucketRegion,
        accessKeyId: accessKey,
        secretAccessKey: secretKey
    });
    const params = {
        Bucket: bucketName,
        Key: fileName,
        Expires: Number(signedUrlExpires)
    };
    return s3.getSignedUrl('getObject', params);
}

export {uploadFileToS3,generatePreSignedURL};
