function capitalizeFirstLetter(value, separator="") {
    let convertedValue = "";
    if(!!value){ 
        if(separator ===""){
            convertedValue = `${value?.charAt(0)?.toUpperCase() + value?.slice(1) }`
        }else{
            const strArray = value.split("_")
            strArray.forEach(str => {
                convertedValue = convertedValue + " " + str.charAt(0).toUpperCase() + str.slice(1);
            });
        }
    }
    return convertedValue;
};



async function checkDuplicateSpecialCharactrer(value){
    const strArray = value.split("");
    const length = strArray.length;
    const isNotSpecialCharacter = await isAlphaNumeric(strArray[length-1]);
    return strArray[length-1] === strArray[length-2] && !isNotSpecialCharacter ? true : false;
}

async function isAlphaNumeric(character) {
    return !character.match(/^[0-9a-z]+$/)? false : true   
}

export {capitalizeFirstLetter, checkDuplicateSpecialCharactrer}
