import { Typography } from "@mui/material";
import React from "react";

const HeadingBorderB = ({heading, fontSize, borderColor }) => {
  return (
    <div style={{ alignContent: "left" }}>
      <Typography variant="blackText2" style={{fontWeight: 500, fontSize:`${!!fontSize ? fontSize : "16px"}`}}> {heading}</Typography>
      <div style={{height: "3px", width: "58px",background: `${ !!borderColor? borderColor : "#5850EC"}`, marginTop: '8px', marginBottom:"8px"}}>
      </div>
    </div>
  );
};

export default HeadingBorderB;
