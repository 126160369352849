import React from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  SvgIcon,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Download as DownloadIcon,
  PlusCircle as PlusCircleIcon,
} from "react-feather";
import UsersList from "./Users/UsersList";

const Users = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      DashBoard
    </Link>,
    <Typography key="2" color="text.primary">
      Manage Listings
    </Typography>,
    <Typography key="3" color="text.primary">
      Users
    </Typography>,
  ];

  return (
    <>
      <div style={{ height: "10vh", width: "100%" }}>
        <Grid container justify="space-between" spacing={3}>

          <Grid item xs={12} md={8}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
            <Typography variant="h1">Users</Typography>
          </Grid>

          <Grid
            item
            xs={12} md={4}
            sx={{
              paddingRight: "24px",
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
            >
              <Button
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "4px",
                  width: "52px",
                  height: "36px",
                }}
              >
                <SvgIcon fontSize="small">
                  <DownloadIcon />
                </SvgIcon>
              </Button>
              <Button
                sx={{
                  background: "#5850EC",
                  borderRadius: "4px",
                  color: "#FFFFFF",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "24px",
                  textTransform: "uppercase",
                }}
                startIcon={<PlusCircleIcon />}
              >
                New User
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Container maxWidth={false}>
        <Box mt={3}>
          <UsersList />
        </Box>
      </Container>
    </>
  );
};

export default Users;
