import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import "./UploadFile.css"

function ProgressBar(props) {
    return (
        <Box className="progress-bar">
            <CircularProgress variant="determinate" size={60} {...props} />
            <Box className="progress-bar-value">
                <div className="progress-label">
                    {`${Math.round(props.value)}%`}
                </div>
            </Box>
        </Box>
    );
}

export default ProgressBar;
