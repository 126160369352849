import React, { useRef, useState } from "react";
import {
  Tabs,
  Tab,
  Button,
  Box,
  SvgIcon,
  Typography,
  InputBase,
  Card,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { DataGrid } from "@mui/x-data-grid";
import Label from "../../../components/Label";
import { Search as SearchIcon } from "react-feather";

import CommmentActions from "./CommentActions";
import SortIcon from "../../../components/UI/SortIcon";
import { useCallback } from "react";
import { useEffect } from "react";
import { formateDate } from "../../../util/dateformat";
import { getAllComments, updateReport } from "../../../services/comments/reportedComments";
import LoadingAnimation from "../../../components/LoadingAnimation/LoadingAnimation";
import CustomizedSnackbars from "../../../components/Alert/CustomizedSnackbars";
import { getErrorMessageFromErrorResponse } from "../../../services/helper";

const labelColors = {
  approved: "success",
  open: "warning",
  flagged: "error",
};

const compareString = (v1,v2) => v1?.name.localeCompare(v2?.name); 


export default function CommentList() {
  const searchForm = useRef("");
  const [reports, setReports] = useState([]);
  const [currentTab, setCurrentTab] = useState("all");
  const [clearButtonVisible, setClearButtonVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [rowCountState, setRowCountState] = useState(0);
  const [alert, setAlert] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleSearch = () => {
    const form = searchForm.current;
    if (form.value) {
      setSearchValue(form.value);
      setClearButtonVisible(true);
    }
  };

  const handleSearchClear = () => {
    setSearchValue("");
    searchForm.current.value = "";
    setClearButtonVisible(false);
  };

  const fetchListData = useCallback(async () => {
    setAlert({});
    let param = {
      limit: pageSize,
      offset: page * pageSize,
      search: searchValue,
      content_type: "comment",
    };

    if(currentTab.toLocaleLowerCase()!=="all"){
      param["review_status"] = currentTab.toLocaleLowerCase();
    }

    let searchParams = new URLSearchParams(param).toString();
    let reportlist = [];
    try {
      const data = await getAllComments(searchParams);
      if (data) {
        setRowCountState(data?.count);
        reportlist = data?.reports?.map((report) => {
          return {
            id: report?.id,
            reporter: {
              name: report?.Profile?.display_name,
              photo: report?.Profile?.profile_photo_s3_object_id
            },
            post: report?.Comment?.Post?.description,
            comment: report?.Comment?.comment_text,
            commnetAuthor: {
              name: report?.Comment?.Profile?.display_name,
              photo: report?.Comment?.Profile?.profile_photo_s3_object_id,
            },
            postDate: report?.Comment?.Post?.date_created,
            reportedDate: report?.date_created,
            status: report?.review_status,
            isSuspended: report?.is_suspended,
            action: "Action",
          }
        });
        setReports(reportlist);
      }
    } catch (error) {
      setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
      console.log(error);
    }
    setLoading(false);
  }, [page, pageSize, searchValue, currentTab]);

  const handleActions = (action,id) =>{
    setAlert({});
    let param = {
      id: id,
    };
    let searchParams = new URLSearchParams(param).toString();
    let data = {
      action: action
    };
    updateReport (searchParams,data)
      .then(function (response) {
        fetchListData();
      })
      .catch(function (error) {
        setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
        console.error("delete post error:", error)
        return true;
      });
  }

  const columns = [
    { field: "id", headerName: "Id", width: 89, hide: true },
    { field: "isSuspended", headerName: "Suspended", width: 89, hide: true },

    {
      field: "reporter",
      headerName: "Reporter",
      valueGetter: (params) => params?.row?.reporter,
      sortComparator: compareString,
      headerClassName: "",
      width: 300,
      renderCell: (params) => {
        return (
          <Box
            sx={{ paddingY: 2, display: "flex", alignItems: "center" }}
            component="div"
          >
            <Avatar src={params?.value?.photo ? params.value.photo : null} />
            <Typography variant="purpleText" sx={{ marginLeft: 1 }}>
              {params?.value?.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "post",
      headerName: "Post",
      width: 300,
      renderCell: (params) => {
        return (
          <>
            <Typography variant="purpleText">{params.value}</Typography>
          </>
        );
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      renderCell: (params) => {
        return <Typography variant="blackText">{params.value}</Typography>;
      },
      width: 400,
    },
    {
      field: "commnetAuthor",
      headerName: "Comment Author",
      width: 300,
      valueGetter: (params) => params?.row?.commnetAuthor,
      sortComparator: compareString,
      renderCell: (params) => {
        return (
          <>
            <Avatar src={params?.value?.photo ? params?.value?.photo : null} />
            <Typography variant="purpleText" sx={{ marginLeft: 1 }}>
              {params?.value?.name}
            </Typography>
          </>
        );
      },
    },
  
    {
      field: "postDate",
      headerName: "Post Date",
      width: 156,
      renderCell: (params) => {
        const { newForamteDate } = formateDate(params.value);
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="blackText" component="span">
              {newForamteDate}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "reportedDate",
      headerName: "Reported Date",
      width: 156,
      renderCell: (params) => {
        const { newForamteDate } = formateDate(params.value);
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="blackText" component="span">
              {newForamteDate}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 105,
      renderCell: (params) => {
        return (
          <Label
            color={params.value === "open" ? labelColors.open : params.value === "approved" ? labelColors.approved : params.value ==="flagged" ? labelColors.flagged : labelColors.open }
          >
            {params.value}
          </Label>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      type: "actions",
      renderCell: (params) => {
        return (
          <>
            <CommmentActions
              id={params?.id}
              row={params.row}
              handleActions={handleActions}
              selectedItems={selectedItems}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchListData();
  }, [page, pageSize, fetchListData]);

  return (
    <Card
      sx={{
        background: "#FFFFFF",
        boxShadow:
          "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
        borderRadius: "4px",
      }}
    >
      <div style={{ height: "5vh", width: "100%" }}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="all" label="All" />
          <Tab value="open" label="Open" />
          <Tab value="flagged" label="Flagged" />
          <Tab value="approved" label="Approved" />
        </Tabs>
      </div>
      <div style={{ height: "10vh", width: "100%" }}>
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid container spacing={2}>
            <Grid item>
              <InputBase
                inputRef={searchForm}
                sx={{
                  width: "420px",
                  height: "36px",
                  background: "#FFFFFF",
                  border: "1px solid #E0E0E0",
                  borderRadius: "4px",
                  textAlign: "right",
                  display: "flex",
                  padding: 2,
                }}
                startAdornment={
                  <SvgIcon fontSize="small" color="action" sx={{}}>
                    <SearchIcon />
                  </SvgIcon>
                }
                placeholder="Search customers"
                variant="outlined"
                disabled={clearButtonVisible}
              />
            </Grid>
            <Grid item>
              {clearButtonVisible === true ? (
                <Button
                  sx={{
                    height: "36px",
                    background: "#F5F5F5",
                    borderRadius: "4px",
                    color: "#263238",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "24px",
                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                    textTransform: "uppercase",
                  }}
                  onClick={handleSearchClear}
                >
                  Clear Filter
                </Button>
              ) : (
                <Button
                  sx={{
                    background: "#F5F5F5",
                    borderRadius: "4px",
                    color: "#263238",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "24px",
                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                    textTransform: "uppercase",
                    padding: "6px 16px",
                  }}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              )}
            </Grid>
          </Grid>
          <Box flexGrow={1} />
        </Box>
      </div>
      {loading ?
        <Box sx={{ width: "100%", height: "50vh" }}><LoadingAnimation /></Box> :
        <Box sx={{ width: "100%" }}>
          <DataGrid
            autoHeight
            rows={reports}
            columns={columns}
            checkboxSelection
            disableColumnMenu={true}
            sx={{
              border: 0,
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                display: "flex",
                justifyContent: "space-between",
              },
              "& .MuiDataGrid-iconButtonContainer": {
                display: "flex",
                width: "auto",
                visibility: "visible",
              },
            }}
            components={{
              ColumnSortedAscendingIcon: SortIcon,
              ColumnSortedDescendingIcon: SortIcon,
              ColumnUnsortedIcon: SortIcon,
            }}
            rowCount={rowCountState}
            loading={loading}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSelectionModelChange={itm => setSelectedItems(itm)}
          />
        </Box>
      }
      {
        alert.error ?
          <CustomizedSnackbars
            open={alert.error}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="error"
          /> :
          alert.success && <CustomizedSnackbars
            open={alert.success}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="success"
          />
      }
    </Card>
  );
}
