import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";

import {apiKey, appId, authDomain, measurementId, messagingSenderId, projectId, storageBucket,} from "./firebaseConfig";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey,
    authDomain,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
