import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import {
  Button,
  Box,
  SvgIcon,
  Typography,
  InputBase,
  Card,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import Label from "../../../../components/Label";
import {
  Edit as EditIcon,
  Search as SearchIcon,
  Image,
} from "react-feather";
import { getAllCategories } from "../../../../services/postsService";

import SortIcon from "../../../../components/UI/SortIcon";
import { formateDate } from "../../../../util/dateformat";
import LoadingAnimation from "../../../../components/LoadingAnimation/LoadingAnimation";
import CustomizedSnackbars from "../../../../components/Alert/CustomizedSnackbars";
import { getErrorMessageFromErrorResponse } from "../../../../services/helper";


const labelColors = {
  active: "success",
  paused: "warning",
  inactive: "drafted",
};

const columns = [
  { field: "id", headerName: "Id", width: 90, hide: true },
  {
    field: "title",
    headerName: "Title",
    width: 430,
    renderCell: (params) => {
      return (
        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <Box sx={{ paddingY: 2, display: "flex", alignItems: 'center', height: "56px", width: '52px', backgroundColor: "#EEEEEE", justifyContent: 'center', borderRadius: '4px' }} component="div">
            <Image height="23px" width="23px" />
          </Box>
          <Box>
            <Typography variant="blackText" sx={{ padding: " 0px 8px" }}>{params.value}</Typography>
          </Box>
        </Box>
      );
    },
  },

  {
    field: "class",
    headerName: "Class",
    renderCell: (params) => {
      return <Typography variant="blackText">{params.value}</Typography>;
    },
    width: 155,
  },
  {
    field: "dateAdded",
    headerName: "Date Added",
    width: 156,
    renderCell: (params) => {
      const { newForamteDate } = formateDate(params.value);
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="blackText" component="span">
            {newForamteDate}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 105,
    renderCell: (params) => {
      return (
        <Label
          color={
            params.value === "Active"
              ? labelColors["active"]
              : params.value === "Inactive" ? labelColors["inactive"] : labelColors["paused"]
          }
        >
          {params.value}
        </Label>
      );
    },
  },
  {
    field: "edit",
    headerName: "Edit",
    type: "actions",
    width: 120,
    renderCell: (params) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "space-around",
            alignItems: "center",
            paddingLeft: "20px",
          }}
        >
          <Link to={`/Manage/CategoryDetail/${params.id}`}>
            <IconButton
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <EditIcon color="#263238" height="18px" width="18px" />
            </IconButton>
          </Link>
        </Box>
      );
    },
  },
];

const CategoriesList = () => {
  const categoryRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [clearButtonVisible, setClearButtonVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [rowCountState, setRowCountState] = useState(0);
  const [alert, setAlert] = useState({});

  const handleSearch = () => {
    const form = categoryRef.current;
    if (form.value) {
      setSearchValue(form.value);
      setClearButtonVisible(true);
    }
  };

  const handleSearchClear = () => {
    setSearchValue("");
    setClearButtonVisible(false);
  };

  const fetchListData = useCallback(async () => {
    let param = {
      limit: pageSize,
      offset: page * pageSize,
    };
    let searchParams = new URLSearchParams(param).toString();
    let categorylist = [];
    try {
      const data = await getAllCategories(searchParams);
      if (data) {
        setRowCountState(data?.count);
        categorylist = data?.post_categories?.map((category) => ({
          id: category?.id,
          title: category?.name,
          class: category?.class ? "" : "",
          dateAdded: category?.date_created,
          status: category?.is_deleted ? "Inactive" : "Active",
          action: "Action",
        }));
        setCategories(categorylist);
      }
    } catch (error) {
      console.error(error);
      setAlert({ ...alert, error: true, message: getErrorMessageFromErrorResponse(error) })
    }
    setLoading(false);
  }, [page, pageSize]);

  useEffect(() => {
    setLoading(true);
    fetchListData();
  }, [page, pageSize, fetchListData]);



  return (
    <>
      <Card
        sx={{
          background: "#FFFFFF",
          boxShadow:
            "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
          borderRadius: "4px",
        }}
      >

        <div style={{ height: "10vh", width: "100%" }}>
          <Box p={2} minHeight={56} display="flex" alignItems="center">
            <Grid container spacing={2}>
              <Grid item>
                <InputBase
                  inputRef={categoryRef}
                  sx={{
                    width: "420px",
                    height: "36px",
                    background: "#FFFFFF",
                    border: "1px solid #E0E0E0",
                    borderRadius: "4px",
                    textAlign: "right",
                    display: "flex",
                    padding: 2,
                  }}
                  startAdornment={
                    <SvgIcon fontSize="small" color="action" sx={{}}>
                      <SearchIcon />
                    </SvgIcon>
                  }
                  placeholder="Search Categories"
                  variant="outlined"
                  disabled={clearButtonVisible}
                />
              </Grid>
              <Grid item>
                {clearButtonVisible === true ? (
                  <Button
                    sx={{
                      height: "36px",
                      background: "#F5F5F5",
                      borderRadius: "4px",
                      color: "#263238",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                      textTransform: "uppercase",
                    }}
                    onClick={handleSearchClear}
                  >
                    Clear Filter
                  </Button>
                ) : (
                  <Button
                    sx={{
                      background: "#F5F5F5",
                      borderRadius: "4px",
                      color: "#263238",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.14)",
                      textTransform: "uppercase",
                      padding: "6px 16px",
                    }}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </div>
        {loading ?
          <Box sx={{ width: "100%", height: "50vh" }}><LoadingAnimation /></Box> :
          <Box sx={{ width: "100%" }}>

            <DataGrid
              autoHeight
              rows={categories}
              columns={columns}
              checkboxSelection
              disableColumnMenu={true}
              sx={{
                border: 0,
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  display: "flex",
                  justifyContent: "space-between",
                },
                "& .MuiDataGrid-iconButtonContainer": {
                  display: "flex",
                  width: "auto",
                  visibility: "visible",
                },
              }}
              components={{
                ColumnSortedAscendingIcon: SortIcon,
                ColumnSortedDescendingIcon: SortIcon,
                ColumnUnsortedIcon: SortIcon,
              }}
              rowCount={rowCountState}
              loading={loading}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </Box>
        }
      </Card>
      {
        alert.error ?
          <CustomizedSnackbars
            open={alert.error}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="error"
          /> :
          alert.success && <CustomizedSnackbars
            open={alert.success}
            vertical="top"
            horizontal="right"
            message={alert.message}
            color="success"
          />
      }</>

  );
};

export default CategoriesList;
