import { createTheme } from "@mui/material";
import { softShadows } from "./shadows";
import { blueGrey } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#ffffff",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#959595",
            minHeight: 24,
            border: "1px solid #aaa",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#aaa",
          },
        },
      },
    },
  },

  overrides: {
    MuiInputBase: {
      input: {
        "&::placeholder": {
          opacity: 1,
          color: blueGrey[600],
        },
      },
    },
    "& .MuiPaper-root.MuiAccordion-root.Mui-expanded": {
      boxShadow: "none",
    },
  },
  palette: {
    type: "light",
    action: {
      active: blueGrey[600],
    },
    background: {
      default: "#f4f6f8",
      dark: "#f4f6f8",
      paper: "#FFFFFF",
    },
    primary: {
      main: "#5850EC",
    },
    secondary: {
      main: "#7986CB",
    },
    text: {
      primary: blueGrey[900],
      secondary: blueGrey[600],
    },
  },
  shadows: softShadows,
  typography: {
    fontFamily: ["Roboto"],
    h1: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontSize: "24px",
      fontWeight: "500",
      lineHeight: "28px",
      color: "#263238",
    },
    subtitleLogin: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      color: "#546E7A",
      letterSpacing: "0.1px",
    },
    navBarFont: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "24px",
      color: "#FFFFFF",
    },
    purpleText: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#5850EC",
    },
    purpleText2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#5850EC",
    },
    blackText: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#263238",
    },
    blackText2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#263238",
    },
  },
  "@global": {
    "*": {
      boxSizing: "border-box",
      margin: 0,
      padding: 0,
    },
    html: {
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
      height: "100%",
      width: "100%",
    },
    body: {
      height: "100%",
      width: "100%",
    },
    "#root": {
      height: "100%",
      width: "100%",
    },
  },
});

export default theme;
